import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { TranslationService } from './config/services/translation.service';
import { DialogData } from './shared/components/dialog-builder/dialog-single-input-model';
import { DialogLoading } from './shared/components/dialog-builder/loading/dialog-loading.component';
export class Helpers {
    static loadStyles(tag, src) {
        if (Array.isArray(src)) {
            $.each(src, function(k, s) {
                $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
            });
        } else {
            $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
        }
    }
    static unwrapTag(element) {
        $(element).removeAttr('appunwraptag').unwrap();
    }
    /**
     * Set title markup
     * @param title
     */
    static setTitle(title) {
        $('.m-subheader__title').text(title);
    }
    /**
     * Breadcrumbs markup
     * @param breadcrumbs
     */
    static setBreadcrumbs(breadcrumbs) {
        if (breadcrumbs) $('.m-subheader__title').addClass('m-subheader__title--separator');
        let ul = $('.m-subheader__breadcrumbs');
        if ($(ul).length === 0) {
            ul = $('<ul/>')
                .addClass('m-subheader__breadcrumbs m-nav m-nav--inline')
                .append(
                    $('<li/>')
                        .addClass('m-nav__item')
                        .append($('<a/>').addClass('m-nav__link m-nav__link--icon').append($('<i/>').addClass('m-nav__link-icon la la-home')))
                );
        }
        $(ul).find('li:not(:first-child)').remove();
        $.each(breadcrumbs, function(k, v) {
            let li = $('<li/>')
                .addClass('m-nav__item')
                .append($('<a/>').addClass('m-nav__link m-nav__link--icon').attr('routerLink', v.href).attr('title', v.title).append($('<span/>').addClass('m-nav__link-text').text(v.text)));
            $(ul).append($('<li/>').addClass('m-nav__separator').text('-')).append(li);
        });
        $('.m-subheader .m-stack__item:first-child').append(ul);
    }
    static setLoading(enable) {
        let body = $('body');
        if (enable) {
            $(body).addClass('m-page--loading-non-block');
            $('.m-page-loader').addClass('m-page--loading-non-block');
        } else {
            $(body).removeClass('m-page--loading-non-block');
        }
    }

    static showLoadingDialog(dialog: MatDialog, translationService: TranslationService) {
        var data = new DialogData();

        data.title = translationService.GetTranslatedValue('GENERAL.LOADING');
        data.description = '';
        data.negativeButtonText = translationService.GetTranslatedValue('GENERAL.CANCEL');
        var dialogResult = dialog.open(DialogLoading, {
            data,
        });
        return dialogResult;
    }
    //   static setLangLoading(enable) {
    //     // let body = $('body');
    //     // if (enable) {
    //     //   $('.page-white-loader').css('display', 'block');
    //     //   $(body).addClass('m-page--loading-non-block');
    //     // } else {
    //     //   setTimeout(function() {
    //     //     $('.page-white-loader').css('display', 'none');
    //     //     $(body).removeClass('m-page--loading-non-block');
    //     //   }, 1000);
    //     // }
    //   }

    //   static enableLoading(enable: boolean) {
    //     let body = $('body');
    //     if (enable) {
    //       console.log('alert created');
    //       $(body).addClass('m-page--loading-non-block');
    //     } else {
    //       $(body).removeClass('m-page--loading-non-block');
    //       console.log('alert stopped');
    //     }
    //   }

    static bodyClass(strClass) {
        $('body').attr('class', strClass);
    }
    static SwitchToRTL() {
        $('html').attr('dir', 'rtl');
        $('link[href="./assets/vendors/custom/fullcalendar/fullcalendar.bundle.css"]').attr('href', './assets/vendors/custom/fullcalendar/fullcalendar.bundle.rtl.css');
        $('link[href="./assets/demo/demo6/base/style.bundle.css"]').attr('href', './assets/demo/demo6/base/style.bundle.rtl.css');
        $('link[href="./assets/vendors/base/vendors.bundle.css"]').attr('href', './assets/vendors/base/vendors.bundle.rtl.css');
    }
    static SwitchToLTR() {
        $('html').attr('dir', 'ltr');
        $('link[href="./assets/vendors/custom/fullcalendar/fullcalendar.bundle.rtl.css"]').attr('href', './assets/vendors/custom/fullcalendar/fullcalendar.bundle.css');
        $('link[href="./assets/demo/demo6/base/style.bundle.rtl.css"]').attr('href', './assets/demo/demo6/base/style.bundle.css');
        $('link[href="./assets/vendors/base/vendors.bundle.rtl.css"]').attr('href', './assets/vendors/base/vendors.bundle.css');
    }

    static bodyClassForNav() {
        $('body').removeClass('m-aside-left--minimize');
        $('body').addClass('m-aside-left--minimize-left');
    }

    static bodyClassForSideBar() {
        $('body').addClass('m-aside-left--minimize');
        $('body').removeClass('m-aside-left--minimize-left');
    }

    /**
     * @name addBodyClass
     * @param className
     * Append a class to the body
     */
    static addBodyClass(className: string) {
        const bodyTag = document.body;
        bodyTag.classList.add(className);
    }

    /**
     * @name removeBodyClass
     * @param className
     * Removes a class from the body
     */
    static removeBodyClass(className: string) {
        const bodyTag = document.body;
        bodyTag.classList.remove(className);
    }
}
