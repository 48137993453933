import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoggerHelper } from '../../shared/helper/logging/logger';
import { NavigationKeys } from '../../shared/helper/navigation-keys';
import { SubscriptionViewModel } from '../../shared/viewmodel/shared/subscription/subscription.viewmodel';

@Injectable({ providedIn: 'root' })
export class SubscriptionGuard implements CanActivate {
    // Reference:
    // https://medium.com/angular-in-depth/new-in-angular-v7-1-updates-to-the-router-fd67d526ad05
    // https://stackoverflow.com/a/63955377

    constructor(public subscriptionViewModel: SubscriptionViewModel) { }

    /**
     * @name canActivate
     * @param route
     * @param state
     * @returns
     * Validates if the user has an active subscription or not
     */
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (await this.subscriptionViewModel.validateIfUserHasActiveSubscription()) {
            LoggerHelper.log('SubscriptionGuard: Subscription is valid');
            return true;
        } else {
            LoggerHelper.log('SubscriptionGuard: Subscription is not valid');
            if (state.url == '/' + NavigationKeys.TeacherModule + '/' + NavigationKeys.ManageSubscriptionPage) {
                LoggerHelper.log('SubscriptionGuard: Accessing subscription module');
                return true;
            } else {
                // Subscription is not valid, redirect the user with the return url
                LoggerHelper.log('SubscriptionGuard: Redirecting');
                return this.subscriptionViewModel.forceNavigateUserToSubscriptionPage();
            }
        }
    }
}
