import { CachingHelper } from '../../../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../../../helper/serialization-helper';

export class SubscriptionResponseModel {
    id: number;
    planId: number;
    planName: string;
    message: string;
    startDateTime?: Date;
    expiryDateTime?: Date;
    numberOfDays: number;
    isFreeTrial: boolean;
    hasExpired: boolean;
    hasActiveSubscription: boolean;
    subscriptionLink: string;
    stripeCustomerId: string;
    isSubscriptionThroughThirdParty: boolean; //Stripe or other providers
    isCustomDomainEnabled: boolean;

    // AppSumo specific
    isAppSumoSubscription: boolean;
    subscriptionCode: string;

    // For frontend detection
    lastTimeChecked: Date;

    private static _instance: SubscriptionResponseModel;

    public constructor() { }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new SubscriptionResponseModel(), CachingHelper.getItemFromCache(CachingKeys.ActiveSubscription));

            //if instance does not exist
            if (!this._instance) {
                this.updateInstance(new SubscriptionResponseModel());
                this._instance = SerializationHelper.toInstance(new SubscriptionResponseModel(), CachingHelper.getItemFromCache(CachingKeys.ActiveSubscription));
            }
            return this._instance;
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.ActiveSubscription);
    }

    /**
     * @name updateInstance
     * @param data
     * Update the new instance in cache
     */
    public static updateInstance(data: SubscriptionResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.ActiveSubscription, JSON.stringify(data));
    }
}
