import { Injectable } from '@angular/core';
import { OnboardingApplicationService } from '../../../../../../src/app/core/services/onboarding/onboarding-application-service.service';

@Injectable({
    providedIn: 'root',
})
export class OnboardingViewModel {
    constructor(private service: OnboardingApplicationService) { }

    /**
     * @name GetOnboardingByUserId
     * @param userId
     * Get the onboarding preference for certain user
     */
    public GetOnboardingByUserId(userId: number) {
        var data = this.service.GetOnboardingByUserId(userId);
        return data;
    }

    /**
     * @name UpdateOnboarding
     * @param userId
     * update onboarding
     */
    public UpdateOnboarding(userId: number, body: any) {
        var data = this.service.updateOnboarding(userId, body);
        return data;
    }

    /**
     * @name UpdateOnboarding
     * @param userId
     * @param onboarding
     * Update onboarding
     */
    public async updateOnboarding(userId: number, body: any) {
        return await this.service
            .updateOnboarding(userId, body)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }
}
