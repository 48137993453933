import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { GlobalVaribales } from '../../../../../../../../../../../../src/app/shared/helper/global-variable';
import { UtilityHelper } from '../../../../../../../../../../../../src/app/shared/helper/utility';
import { DialogOnBoardingData } from '../../../on-boarding-builder-dialog-model';

@Component({
    selector: 'on-boarding-popup-title',
    templateUrl: './on-boarding-popup-title.component.html',
    styleUrls: [`on-boarding-popup-title-style.scss`],
    encapsulation: ViewEncapsulation.None
})
export class OnBoardingPopupTitleComponent {
    @Input() dialogOnBoardingData: DialogOnBoardingData;
    @Input() isQuestionData: boolean;
    isQuestion: boolean = false;
    icon: string;
    @ViewChild('itemContainers') dataContainer: ElementRef;

    constructor() {
        this.LoadData();
    }

    async LoadData() {
        await UtilityHelper.delay(
            GlobalVaribales.DelayToLoadLazyDataBetweenComponents
        ); //delay the data reading, until the data has been set from the previous page using lazy loading

        if (this.dialogOnBoardingData) {
            if (this.isQuestionData == true) {
                this.isQuestion = this.isQuestionData;
                this.dataContainer.nativeElement.innerHTML = this.dialogOnBoardingData.icon;
            } else {
                this.dataContainer.nativeElement.innerHTML = '';
                this.icon = this.dialogOnBoardingData.icon;
            }
        }
    }
}
