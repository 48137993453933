import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule, MatListModule } from "@angular/material";
import { BottomSheetComponent } from "./bottom-sheet.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatListModule,
    ],
    entryComponents: [
        BottomSheetComponent,
    ],
    exports: [BottomSheetComponent],
    declarations: [
        BottomSheetComponent,
    ],
})
export class BottomSheetSharedModule { }