import { CachingHelper } from '../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../helper/serialization-helper';
import { SessionModel } from './session.model';
import { UserModel } from './user.model';

export class UserSessionModel {
    public session: SessionModel;
    public user: UserModel;

    private static _instance: UserSessionModel;

    private constructor() { }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new UserSessionModel(), CachingHelper.getItemFromCache(CachingKeys.Session));
            return this._instance;
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.Session);
    }

    public static updateInstance(data: UserSessionModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.Session, JSON.stringify(data));
    }
}
