export class AnalyticsAccountModel {
    avatar: string;
    name: string;
    id: string;
    industry: string;
    employee_count: number;
    plan: string;
    domain: string;

    createdAt: Date;
    description: string;
    email: string;
    employees: string;
    phone: string;
    website: string;

    //Extras
    hierarchyLevel: string;
    parentId: string;
    parentCode: string;
}
