export class KnowledgeBaseHelper {
    public static TrainnVideo = 'video';
    public static TrainnGuide = 'guide';
    public static TrainnArticle = 'article';
    public static TrainnCollection = 'collection';

    /**
     * @name launchKnowledgeBaseInNewTab
     * Launches the knowledge base in new tab
     */
    public static launchKnowledgeBaseInNewTab() {
        window.Trainn('configure', {
            domainName: 'augmental-learning',
        });
        window.Trainn('open');
    }

    /**
     * @name launchKnowledgeBaseInPopup
     * Launches the knowledge base in popup
     */
    public static launchKnowledgeBaseInPopup(type: string, itemId: string) {
        window.Trainn('configure', {
            domainName: 'augmental-learning',
        });
        window.Trainn('openContent', type, itemId);
    }
}
