export class DialogDataDynamicActionsModel {
    title?: string;
    description?: string;
    negativeButtonText?: string;
    actions?: ActionItem[] = [];
    disableClickOutsideDialog?: boolean = true;
}

export class ActionItem {
    key: number;
    action: string;
}
