import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
//This class is used only to logout the user. Otherwise it will cause circular dependency with the http service.
export class UserSessionApplicationService {
    constructor(private globalApiVariables: GlobalApiVariables, private http: HttpCall) { }

    /**
     * @name updateUserProfile
     * @param body
     * @param userId
     * @returns
     * Update the user profile information
     */
    updateUserProfile(body: string, userId: number) {
        var data = this.http.PutAsync(true, body, this.globalApiVariables.ApiUpdateUserProfile, userId);
        return data;
    }

    /**
     * @name uploadUserProfileImage
     * @param formData
     * @param userId
     * @returns
     * Upload the user profile image
     */
    uploadUserProfileImage(formData: FormData, userId: number) {
        var data = this.http.PostFilesAsync(true, formData, this.globalApiVariables.ApiUpdateUserProfileImage, userId);
        return data;
    }
}
