import { Injectable } from '@angular/core';
import { IntegrationsApiVariables } from '../../../../../../src/app/core/http/http-call/apis/shared/integrations/integrations.api';
import { AccountApiVariables } from '../../../http/http-call/apis/shared/account/account.api';
import { HttpCall } from '../../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class AccountApplicationService {
    constructor(private integrationsApiVariables: IntegrationsApiVariables, private accountApiVariables: AccountApiVariables, private http: HttpCall) { }

    /**
     * @name checkInstitutionStripeStatus
     * @param institutionId
     * Get institution stripe status
     */
    checkInstitutionStripeStatus(institutionId: number) {
        var data = this.http.GetAsync(true, this.integrationsApiVariables.ApiGetInstitutionStripeStatus, institutionId);
        return data;
    }

    /**
     * @name getAccountTypes
     * @returns the account types list
     */
    getAccountTypes() {
        var data = this.http.GetAsync(false, this.accountApiVariables.ApiGetAccountTypes);
        return data;
    }

    /**
     * @name validateDomainNameAvailability
     * @param body
     * @returns
     * Validate the domain name availability
     */
    public validateDomainNameAvailability(body: string) {
        var data = this.http.PostAsync(false, body, this.accountApiVariables.ApiValidateCustomDomainAvailability);
        return data;
    }

    /**
     * @name getAccountSettings
     * @returns
     * Get account settings
     */
    public getAccountSettings() {
        var data = this.http.GetAsync(false, this.accountApiVariables.ApiGetAccountSettings);
        return data;
    }

    /**
     * @name getAccountSettingsBasedOnAccountId
     * @param institutionId
     * @returns data
     */
    public getAccountSettingsBasedOnAccountId(institutionId: number) {
        var data = this.http.GetAsync(false, this.accountApiVariables.ApiGetAccountSettingsById, institutionId);
        return data;
    }

    /**
     * @name registerMainAccount
     * @param usercode
     * @param body
     * @returns
     * Register the main account
     */
    public registerMainAccount(usercode: string, body: string) {
        var data = this.http.PostAsync(false, body, this.accountApiVariables.ApiRegisterMainAccount, usercode);
        return data;
    }

    /**
     * @name checkIfAccountLimitReached
     * @param institutionId
     * @returns
     * Check if the account limit has been reached
     */
    public checkIfAccountLimitReached(institutionId: number) {
        var data = this.http.GetAsync(true, this.accountApiVariables.ApiCheckIfAccountLimitReached, institutionId);
        return data;
    }

    /**
     * @name checkIfAccountHasWarning
     * @param institutionId
     * @returns
     * Check if the account has warning
     */
    public checkIfAccountHasWarning(institutionId: number) {
        var data = this.http.GetAsync(true, this.accountApiVariables.ApiCheckIfAccountHasWarnings, institutionId);
        return data;
    }

    /**
     * @name updateAccountAccess
     * @param institutionId
     * @param isActivating
     * @param isDeactivating
     * @param body
     * @returns
     * Update account access
     */
    public updateAccountAccess(institutionId: number, isActivating: boolean, isDeactivating: boolean, body: string) {
        var data = this.http.PatchAsync(true, body, this.accountApiVariables.ApiToActivateDeactivateAccount, institutionId, isActivating, isDeactivating);
        return data;
    }

    /**
     * @name getAccountInformation
     * @param institutionId
     * @param subInstitutionId
     * Get account information
     */
    public getAccountInformation(institutionId: number, subInstitutionId: number) {
        var data = this.http.GetAsync(true, this.accountApiVariables.ApiGetAccountInformation, institutionId, subInstitutionId);
        return data;
    }

    /**
     * @name sendRequestForCustomDomain
     * @param institutionId
     * @returns
     * Send request for custom domain setup
     */
    public sendRequestForCustomDomain(institutionId: number) {
        return this.http.PostAsync(true, null, this.accountApiVariables.ApiRequestCustomDomain, institutionId);
    }

    /**
     * @name getAllAccounts
     * @param institutionId
     * Get all accounts in an account
     */
    public getAllAccounts(institutionId: number) {
        return this.http.GetAsync(true, this.accountApiVariables.ApiGetAllAccounts, institutionId);
    }

    /**
     * @name getAccountsInsights
     * @param accountId
     * @param body
     * Get account insights in an account
     */
    public getAccountsInsights(institutionId: number, body: string) {
        return this.http.PostAsync(true, body, this.accountApiVariables.ApiGetAccountInsights, institutionId);
    }

    /**
     * @name getAccountCap
     * @param accountId
     * Get account cap
     */
    public getAccountCap(institutionId: number) {
        return this.http.GetAsync(true, this.accountApiVariables.ApiGetAccountCap, institutionId);
    }

    /**
     * @name createAccount
     * @param institutionId
     * @param body
     * @returns
     * Create an account
     */
    public createAccount(institutionId: number, body: string) {
        var data = this.http.PostAsync(true, body, this.accountApiVariables.ApiCreateAccount, institutionId);
        return data;
    }

    /**
     * @name updateAccount
     * @param institutionId
     * @param subInstitutionId
     * @param body
     * @returns
     * Update an account
     */
    public updateAccount(institutionId: number, subInstitutionId: number, body: string) {
        var data = this.http.PutAsync(true, body, this.accountApiVariables.ApiUpdateAccount, institutionId, subInstitutionId);
        return data;
    }

    /**
     * @name validateAccountCap
     * @param accountId
     * @param body
     * @returns
     * Validate the account cap
     */
    public validateAccountCap(accountId: number, body: string) {
        return this.http.PostAsync(true, body, this.accountApiVariables.ApiValidateAccountCap, accountId);
    }

    /**
     * @name updateAccountCap
     * @param accountId
     * @param body
     * @returns
     * Update the account cap
     */
    public updateAccountCap(accountId: number, body: string) {
        return this.http.PutAsync(true, body, this.accountApiVariables.ApiUpdateAccountCap, accountId);
    }

    /**
     * @name getEmailConfiguration
     * @param accountId
     * Get email configuration
     */
    public getEmailConfiguration(accountId: number) {
        return this.http.GetAsync(true, this.accountApiVariables.ApiGetWhiteLabelEmailConfiguration, accountId);
    }

    /**
     * @name testEmailConfigurationBeforeSavingData
     * @param accountId
     * @param body
     * Test the email configuration before saving the data
     */
    public testEmailConfigurationBeforeSavingData(accountId: number, body: string) {
        return this.http.PostAsync(true, body, this.accountApiVariables.ApiSetupWhiteLabelEmailConfiguration, accountId);
    }

    /**
     * @name updateEmailConfiguration
     * @param accountId
     * @param body
     * Update email configuration
     */
    public updateEmailConfiguration(accountId: number, body: string) {
        return this.http.PutAsync(true, body, this.accountApiVariables.ApiUpdateWhiteLabelEmailConfiguration, accountId);
    }

    /**
     * @name testEmailConfiguration
     * @param accountId
     * Test the email configuration
     */
    public testEmailConfiguration(accountId: number) {
        return this.http.GetAsync(true, this.accountApiVariables.ApiTestWhiteLabelEmailConfiguration, accountId);
    }
}
