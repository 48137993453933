import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../../../../../src/app/config/services/translation.service';

@Component({
    selector: 'warning-recurrent-session-popup',
    templateUrl: './warning-recurrent-session-popup.component.html',
    styleUrls: [`warning-recurrent-session-popup.component.scss`],
    encapsulation: ViewEncapsulation.None,
})
export class WarningRecurrentSessionPopupComponent {


    //------------- translation ------------------
    translationDeleteSession: string = '';
    translationDeleteRecurrentSessionWarning: string = '';
    translationEditRecurrentSessionWarning: string = '';
    translationCancel: string = '';
    translationAllSessions: string = '';
    translationOnlyThisSession: string = '';
    translationEditSession: string = '';
    //------------- translation ------------------




    constructor(
        public dialogRef: MatDialogRef<WarningRecurrentSessionPopupComponent>,
        public translate: TranslateService,
        public translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.LabelUpdate();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.LabelUpdate();
        });
    }



    /**
     * Update the UI labels based on the translations
     */
    LabelUpdate() {
        this.translationDeleteSession = this.translationService.GetTranslatedValue(
            'LiveSession.DeleteSession'
        );

        this.translationEditSession = this.translationService.GetTranslatedValue(
            'LiveSession.EditSession'
        );

        this.translationDeleteRecurrentSessionWarning = this.translationService.GetTranslatedValue(
            'LiveSession.DeleteRecurrentSessionWarning',
            { sessionName: this.data.name }
        );

        this.translationEditRecurrentSessionWarning = this.translationService.GetTranslatedValue(
            'LiveSession.EditRecurrentSessionWarning',
            { sessionName: this.data.name }
        );

        this.translationCancel = this.translationService.GetTranslatedValue(
            'GENERAL.CANCEL'
        );

        this.translationAllSessions = this.translationService.GetTranslatedValue(
            'LiveSession.AllSessions'
        );

        this.translationOnlyThisSession = this.translationService.GetTranslatedValue(
            'LiveSession.OnlyThisSession'
        );
    }



    /**
     * @name DeleteAllSessions
     * delete all child sessions 
     */
    SelectAllSessions() {
        this.dialogRef.close({
            isAllSession: true,
        });
    }


    /**
     * @name DeleteSingleSession
     * Delete single Session
     */
    SelectSingleSession() {
        this.dialogRef.close(
            {
                isSingleSession: true
            }
        )
    }
}