import { EncryptionDecryptionKeys } from '../encryption-decryption/encryption-decryption-keys';
import { EncryptionDecryptionHelper } from '../encryption-decryption/encryption-decryption.helper';

export class CachingHelper {
    /**
     * @name saveItemIntoCache
     * @param key
     * Saves single item to cache after encrypting it
     */
    static saveItemIntoCache(key: string, value: string) {
        var encryptedValue = EncryptionDecryptionHelper.EncryptStringAES(EncryptionDecryptionKeys.EncryptDecryptCommonKey, EncryptionDecryptionKeys.IVCommonKey, value);
        localStorage.setItem(key, encryptedValue);
    }

    /**
     * @name getItemFromCache
     * @param key
     * Retrieves single item from cache after decrypting it
     */
    static getItemFromCache(key: string): string | null {
        var decryptedValue = null;
        var encryptedItem = localStorage.getItem(key);
        if (encryptedItem != null) {
            decryptedValue = EncryptionDecryptionHelper.DecryptStringAES(EncryptionDecryptionKeys.EncryptDecryptCommonKey, EncryptionDecryptionKeys.IVCommonKey, encryptedItem);
        }

        return decryptedValue;
    }

    /**
     * @name removeItemFromCache
     * @param key
     * Removes single item from cache
     */
    static removeItemFromCache(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * @name clearData
     * Clears all data stored in cache
     */
    static clearData() {
        localStorage.clear();
    }
}
