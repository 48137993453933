import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class InstitutionApplicationService {
    constructor(private globalApiVariables: GlobalApiVariables, private http: HttpCall) { }

    /**
     * @name GetAllInstitutionByInstructorId
     * @param userId
     * Get all institutions by instructor id
     */
    public GetAllInstitutionByInstructorId(userId: number) {
        var data = this.http.GetAsync(true, this.globalApiVariables.ApiGetAllInstitutions, userId);
        return data;
    }

    /**
     * @name GetAllInstitution
     * @param userId
     * @param body
     * Get all available institution by user id
     */
    public GetAllInstitution(userId: number, body: string) {
        var data = this.http.PostAsync(true, body, this.globalApiVariables.ApiGetAllInstitutionPreferences, userId);
        return data;
    }

    /**
     * @name UpdateUserInstitutionPreferences
     * @param body
     * Update the selected institution preferences
     */
    public UpdateUserInstitutionPreferences(body: string) {
        var data = this.http.PutAsync(true, body, this.globalApiVariables.ApiPutInstitutionPreferences);
        return data;
    }

    /**
     * @name GetInstitutionProviders
     * @param institutionId
     * Get institution providers
     */
    public GetInstitutionProviders(institutionId: number) {
        var data = this.http.GetAsync(true, this.globalApiVariables.ApiGetInstitutionProviders, institutionId);

        return data;
    }

    /**
     * @name getSubInstitutionsBasedOnInstitutionId
     * @param institutionId
     * Get sub institutions for a certain institution
     */
    public getSubInstitutionsBasedOnInstitutionId(institutionId: number) {
        var data = this.http.GetAsync(true, this.globalApiVariables.ApiGetSubInstitutions, institutionId);
        return data;
    }

    /**
     * @name getInstitutionSettings
     * @param institutionId
     * get institution settings
     */
    public getInstitutionSettings(institutionId: number) {
        var data = this.http.GetAsync(true, this.globalApiVariables.ApiGetInstitutionSettings, institutionId);
        return data;
    }
}
