import { Injectable } from '@angular/core';
import { SharedService } from '../../../../src/app/_services/SharedService';
import { InstitutionPreferenceResponseModel } from '../../../../src/app/core/models/institution/response/institution-preference-response.model';
import { BrandingResponseModel } from '../../../../src/app/core/models/shared/branding/response/branding.response.model';
import { SubscriptionResponseModel } from '../../../../src/app/core/models/shared/subscription/response/subscription.response.model';
import { environment } from '../../../../src/environments/environment';
import { Helpers } from '../../helpers';
import { BrandingViewModel } from '../viewmodel/shared/branding/branding.viewmodel';
import { FaviconService } from './branding/favicon.service';
import { LoggerHelper } from './logging/logger';

export class DomainModel {
  url: string;
  institution: string;
}

@Injectable({
  providedIn: 'root',
})
export class BrandingHelper {
  constructor(public viewModel: BrandingViewModel, private sharedService: SharedService, private faviconService: FaviconService) {}

  public _whiteLabelDomain: DomainModel[] = [
    { url: 'http://localhost:4200', institution: 'augmental' },
    { url: 'https://dev.d1s81te7kkgpj9.amplifyapp.com', institution: 'augmental' },
    { url: 'https://wl-staging.augmental.education', institution: 'augmental' },
    { url: 'https://wl-dev.augmental.education', institution: 'augmental' },
    { url: 'https://wl.augmental.education', institution: 'augmental' },
    { url: 'https://k12.augmental.education', institution: 'augmental' },
    { url: 'https://highered.augmental.education', institution: 'augmental' },
    { url: 'https://training.augmental.education', institution: 'augmental' },
    { url: 'https://trainings.augmental.education', institution: 'augmental' },
    { url: 'https://trainings.augmentalapp.com', institution: 'augmental' },
    { url: 'https://augmentalapp.com', institution: 'augmental' },
    { url: 'https://app.augmentalapp.com', institution: 'augmental' },
    { url: 'https://signup.augmentalapp.com', institution: 'augmental' },
    { url: 'https://signup.testingmeet.augmental.education', institution: 'augmental' },
  ];

  /**
   * getDomainNameFromUrl
   * @returns
   * Get the domain name from the url
   */
  getDomainNameFromUrl(): string {
    return location.hostname.replace('www.', '');
  }

  /**
   * @name getWhiteLabelInstitutionIfAvailable
   * @returns DomainModel
   * Return the white label institution object in case found
   */
  private getWhiteLabelInstitutionIfAvailable(): boolean {
    var currentUrl = this.getDomainNameFromUrl();
    for (var i = 0; i < this._whiteLabelDomain.length; i++) {
      if (this.getHostnameFromUrl(this._whiteLabelDomain[i].url).replace('www.', '').includes(currentUrl)) {
        return false;
      }
    }
    return true;
  }

  /**
   * @name getHostnameFromUrl
   * @param url
   * @returns
   * Extract the hostname from url
   */
  private getHostnameFromUrl(url: string): string {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
  }

  /**
   * @name isWhiteLabellingInstitution
   * @returns
   * Checks if it is a white labelling institution
   */
  isWhiteLabellingInstitution(): boolean {
    return this.getWhiteLabelInstitutionIfAvailable();
  }

  /**
   * @name getWhiteLabellingInstitution
   * @returns
   * Returns the name of the white labelling institution
   */
  // static getWhiteLabellingInstitution(): string {
  //   var item = this.getWhiteLabelInstitutionIfAvailable();
  //   if (item) {
  //     return item.institution;
  //   }
  //   return this._institutionAugmental;
  // }

  /**
   * @name changeBrandingBasedOnUrl
   * Check the url if belongs to a white label institution
   */
  async changeBrandingBasedOnUrl() {
    this.sharedService.ChangeInstitutionLogo.emit(true);
    this.faviconService.setFavicon(this.loadBrandingFavicon());

    // var brandingModel: BrandingResponseModel = await this.loadBranding();
    document.documentElement.style.setProperty('--color-primary-normal', BrandingResponseModel.Instance.primaryColor, 'important');
    document.documentElement.style.setProperty('--color-primary-very-light', BrandingResponseModel.Instance.lightColor, 'important');
    document.documentElement.style.setProperty('--color-primary-dark', BrandingResponseModel.Instance.darkColor, 'important');
    document.documentElement.style.setProperty('--color-secondary-normal', BrandingResponseModel.Instance.secondaryColor, 'important');
    document.documentElement.style.setProperty('--color-content-normal', BrandingResponseModel.Instance.primaryColor, 'important');
    document.documentElement.style.setProperty('--color-content-light', BrandingResponseModel.Instance.lightColor, 'important');
    document.documentElement.style.setProperty('--color-content-dark', BrandingResponseModel.Instance.darkColor, 'important');
    document.documentElement.style.setProperty('--color-question-normal', BrandingResponseModel.Instance.primaryColor, 'important');
    document.documentElement.style.setProperty('--color-question-light', BrandingResponseModel.Instance.lightColor, 'important');
    document.documentElement.style.setProperty('--color-question-dark', BrandingResponseModel.Instance.darkColor, 'important');

    Helpers.addBodyClass('branding-theme');
    // var item = this.getWhiteLabelInstitutionIfAvailable();
    // if (item) {
    //   Helpers.removeBodyClass(this._institutionAugmental + '-theme');
    //   Helpers.addBodyClass(item.institution + '-theme');
    //   return;
    // }
    // Helpers.addBodyClass(this._institutionAugmental + '-theme');
    return;
  }

  /**
   * @name loadBrandingLogo
   * @returns
   * Returns the branding logo of the white labelled institution
   */
  loadBrandingLogoOnWhite(): string {
    return BrandingResponseModel.Instance.logoOnWhite;
  }

  /**
   * @name loadBrandingLogo
   * @returns
   * Returns the branding logo of the white labelled institution
   */
  loadBrandingLogoOnColor(): string {
    return BrandingResponseModel.Instance.logoOnColor;
  }

  /**
   * @name loadBrandingFavicon
   * @returns
   * Returns the branding favicon of the white labelled institution
   */
  loadBrandingFavicon(): string {
    return BrandingResponseModel.Instance.favIcon;
  }

  /**
   * @name loadBrandingTagline
   * @returns
   * Returns the branding slogan of the white labelled institution
   */
  loadBrandingTagline(): string {
    return BrandingResponseModel.Instance.tagline;
  }

  /**
   * @name loadBrandingPlatformTitle
   * @returns
   * Returns the branding platform title of the white labelled institution
   */
  loadBrandingPlatformTitle(): string {
    return BrandingResponseModel.Instance.learningSpaceName;
  }

  /**
   * @name loadBrandingWebsite
   * @returns
   * Returns the branding website of the white labelled institution
   */
  loadBrandingWebsite(): string {
    return BrandingResponseModel.Instance.website;
  }

  /**
   * @name loadBrandingPrivacyLink
   * @returns
   * Returns the branding privacy link of the white labelled institution
   */
  loadBrandingPrivacyLink(): string {
    return BrandingResponseModel.Instance.privacyLink;
  }

  /**
   * @name loadBrandingTermsLink
   * @returns
   * Returns the branding terms link of the white labelled institution
   */
  loadBrandingTermsLink(): string {
    return BrandingResponseModel.Instance.termsLink;
  }

  /**
   * @name loadBranding
   * @returns
   * Loads the white labelled institution branding
   */
  async loadBranding(): Promise<BrandingResponseModel> {
    var branding = await this.loadBrandingBasedOnAccount();
    this.changeBrandingBasedOnUrl();
    return branding;
  }

  /**
   * @name loadBrandingBasedOnAccount
   * @returns
   */
  private async loadBrandingBasedOnAccount(): Promise<BrandingResponseModel> {
    if (BrandingResponseModel.Instance && BrandingResponseModel.Instance.learningSpaceName && BrandingResponseModel.Instance.learningSpaceName.length > 0) {
      LoggerHelper.log(BrandingResponseModel.Instance);
      return BrandingResponseModel.Instance;
    } else {
      if (InstitutionPreferenceResponseModel.Instance && InstitutionPreferenceResponseModel.Instance.institutionId > 0) {
        var branding = await this.viewModel.getAccountBrandingByAccountId(InstitutionPreferenceResponseModel.Instance.institutionId);
        LoggerHelper.log(branding);
        BrandingResponseModel.clearFullInstance();
        BrandingResponseModel.updateInstance(branding);
        return branding;
      } else {
        var branding = await this.viewModel.getAccountBranding();
        LoggerHelper.log(branding);
        BrandingResponseModel.clearFullInstance();
        BrandingResponseModel.updateInstance(branding);
        return branding;
      }
    }
  }

  /**
   * @name checkIfAccountIsOnCustomDomainPlan
   * @returns
   * Checks if a the account has a custom domain
   */
  public checkIfAccountIsOnCustomDomainPlan(): boolean {
    if ((SubscriptionResponseModel.Instance && SubscriptionResponseModel.Instance.isCustomDomainEnabled) || !this.getDomainNameFromUrl().includes(environment.webAppDomain)) {
      return true;
    } else {
      return false;
    }
  }
}
