import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CachingHelper } from '../../shared/helper/caching/caching';
import { CachingKeys } from '../../shared/helper/caching/caching-keys';
import { LoggerHelper } from '../../shared/helper/logging/logger';
import { NavigationKeys } from '../../shared/helper/navigation-keys';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _router: Router) { }

    /**
     * @name canActivate
     * @param route
     * @param state
     * @returns
     * Validates if the user is authenticated or not
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
        if (JSON.parse(CachingHelper.getItemFromCache(CachingKeys.Session))) {
            LoggerHelper.log('User is authenticated');
            return true;
        } else {
            // error when verify so redirect to login page with the return url
            LoggerHelper.log('User is not authenticated');
            LoggerHelper.log('Redirecting user to login page');
            return this.forceNavigateUserToLoginPage(state);
        }
    }

    /**
     * @forceNavigateUserToLoginPage
     * @returns the URL of the navigation
     */
    private forceNavigateUserToLoginPage(state: RouterStateSnapshot): UrlTree {
        return this._router.createUrlTree([NavigationKeys.LoginPage], { queryParams: { returnUrl: state.url } });
    }
}
