export enum IconsList {
  AccountActivated = 'phonelink',
  AccountAdd = 'add_to_queue',
  AccountDeactivated = 'phonelink_off',
  Add = 'add',
  AddItems = 'playlist_add',
  AddLink = 'add_link',
  AddUser = 'person_add',
  AI = 'auto_awesome',
  Back = 'arrow_back',
  Birthdate = 'calendar_month',
  Business = 'store',
  Certificate = 'workspace_premium',
  ChartTrendingDecrease = 'trending_down',
  ChartTrendingIncrease = 'trending_up',
  ChartTrendingStable = 'trending_flat',
  Clear = 'delete_sweep',
  Close = 'close',
  Cancel = 'cancel',
  Code = 'pin',
  Copy = 'content_copy',
  Correct = 'check',
  Course = 'topic',
  CreditCard = 'credit_card',
  Curriculum = 'source',
  Delete = 'delete_sweep',
  DeleteCourse = 'folder_delete',  
  DeleteForever = 'delete_forever',
  Draft = 'cloud_off',
  DripScheduleDynamicDate = 'fact_check',
  DripScheduleEnrollmentDate = 'route',
  DripScheduleOff = 'unpublished',
  DripScheduleSpecificDate = 'calendar_today',
  Duplicate = 'content_copy',
  Duration = 'schedule',
  Edit = 'edit',
  Email = 'mail',
  ExpiryDate = 'event',
  Gender = 'group',
  Generate = 'auto_awesome',
  Go = 'east',
  Group = 'groups',
  GroupRemove = 'group_off',
  Hostname = 'dns',
  Individual = 'person',
  Info = 'info',
  Integration = 'widgets',
  Language = 'language',
  Launch = 'rocket_launch',
  Learning = 'menu_book',
  Lesson = 'book',
  Level = 'signal_cellular_alt',
  Link = 'link',
  Locked = 'lock',
  Login = 'how_to_reg',
  ManageSubscription = 'currency_exchange',
  Marketing = 'newspaper',
  Money = 'payments',
  More = 'more_vert',
  Nationality = 'public',
  NavigateNext = 'navigate_next',
  Next = 'east',
  Notify = 'notifications_active',
  Online = 'public',
  OpenNewTab = 'open_in_new',
  Owner = 'admin_panel_settings',
  Password = 'key',
  Pending = 'pending_actions',
  Preview = 'preview',
  Price = 'local_offer',
  Private = 'lock',
  Profile = 'badge',
  Public = 'public',
  Publish = 'cloud_done',
  Register = 'person_add_alt',
  Resource = 'library_books',
  Retry = 'restart_alt',
  Role = 'admin_panel_settings',
  Search = 'search',
  SearchList = 'manage_search',
  Settings = 'settings_suggest',
  Share = 'send',
  Signature = 'drive_file_rename_outline',
  SiteBuilder = 'web',
  Subject = 'source',
  Subscription = 'account_balance',
  SubscriptionSuccessful = 'bookmark_added',
  Text = 'text_fields',
  Tips = 'tips_and_updates',
  Unlink = 'link_off',
  Upgrade = 'storefront',
  UserActivated = 'account_circle',
  UserCapacity = 'reduce_capacity',
  UserDeactivated = 'no_accounts',
  Warning = 'warning',Upload = "upload_file",
  Hat = "school",
  AltRoute = "alt_route",
  AudioTrack = "audiotrack",
  Image = "image",
  Title = "title", 
  UnfoldLess = 'unfold_less',
  UnfoldMore = 'unfold_more',
  AddChart = 'addchart'
}
