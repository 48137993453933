export class CachingKeys {
    /**
     * The browser preferences
     */
    public static BrowserPreferences = 'BrowserPreferences';

    /**
     * The account branding
     */
    public static Branding = 'Branding';

    /**
     * The application list of languages supported
     */
    public static Languages = 'ApplicationLanguages';

    /**
     * The user institution selected
     */
    public static Institution = 'Institution';

    /**
     * The user institution settings selected
     */
    public static InstitutionSettings = 'InstitutionSettings';

    /**
     * The user session saved
     */
    public static Session = 'Session';

    /**
     * The user preferences saved
     */
    public static UserPreferences = 'UserPreferences';

    /**
     * The user roles
     */
    public static UserRolePermissions = 'UserRolePermissions';

    /**
     * The user chosen course session
     * if the session is empty then the user did not choose any course
     */
    public static CourseSession = 'CourseSession';

    /**
     * The user chosen subcourse session
     * if the session is empty then the user did not choose any subcourse
     */
    public static SubCourseSession = 'SubCourseSession';
    /**
     * On boarding handler
     * if the session is empty then the user did not take any onboarding popup
     */
    public static OnBoarding = 'OnBoarding';

    /**
     * The learning flow
     */
    public static LearningFlow = 'LearningFlow';

    /**
     * Assessment flow
     */
    public static AssessmentFlow = 'AssessmentFlow';

    /**
     * The active subscription saved
     */
    public static ActiveSubscription = 'ActiveSubscription';
}
