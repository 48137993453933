import { CustomParams } from 'firebase/analytics';
import { environment } from '../../../../../../environments/environment';
import { LoggerHelper } from '../../../logging/logger';
import { IAnalyticsService } from '../../analytics.interface.service';
import { AnalyticsExtraModel } from '../../models/other/analytics-extra.model';

// @Injectable({
//   providedIn: 'root',
// })
export class SegmentService implements IAnalyticsService {
    private isTrackingEnabled: boolean;

    constructor() {
        LoggerHelper.log('Inside SegmentService');
        this.isTrackingEnabled = environment.analytics.segment.enableTracking;
    }

    /**
     * Initialize service
     *
     * @name initAnalyticsService
     * @memberof SegmentService
     */
    initAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Initialize SegmentService');
            // analytics.init();
        }
    }

    /**
     * Destroy service
     *
     * @name destroyAnalyticsService
     * @memberof SegmentService
     */
    destroyAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Destroying SegmentService');
            analytics.reset();
        }
    }

    /**
     * Initialize userCode
     *
     * @name registerUser
     * @param {string} userCode
     * @memberof SegmentService
     */
    public registerUser(userCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService registerUser userCode= ' + userCode);
            analytics.identify(userCode);
        }
    }

    /**
     * Register user property
     *
     * @name registerUserProperty
     * @param property
     */
    public registerUserProperties(userCode: string, property: object) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService registerUserProperties= ' + JSON.stringify(property));
            analytics.identify(userCode, property);
        }
    }

    /**
     * Register user property
     *
     * @name registerUserProperty
     * @param property
     */
    public registerUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService registerUserProperty= ' + JSON.stringify(property));
            analytics.identify(property);
        }
    }

    /**
     * Set user property
     *
     * @name setUserProperty
     * @param property
     * mixpanel.people.set is used to set properties on a user's profile within Mixpanel's People Analytics feature.
     * This is used for storing properties about the user themselves, rather than about their actions.
     */
    public setUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService setUserProperty= ' + JSON.stringify(property));
            // analytics.people.set(property);
        }
    }

    /**
     * Register company once
     *
     * @name registerCompanyPropertyOnce
     * @param {string} companyCode
     * @memberof SegmentService
     */
    public registerCompanyPropertyOnce(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService registerCompanyPropertyOnce companyCode= ' + companyCode);
            // analytics.group(companyCode);
        }
    }

    /**
     * Register company
     *
     * @name registerCompanyProperty
     * @param {string} companyCode
     * @memberof SegmentService
     */
    public registerCompanyProperty(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService registerCompanyProperty companyCode= ' + companyCode);
            // this.analytics.set_group(AnalyticsProperties.EventAccount, companyCode);
        }
    }

    /**
     * Update company
     *
     * @name updateCompanyProperty
     * @param {string} companyCode
     * @param {any} property
     * @memberof SegmentService
     */
    public updateCompanyProperty(companyCode: string, property: any, extras?: AnalyticsExtraModel) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService updateCompanyProperty companyCode= ' + companyCode);
            LoggerHelper.log('SegmentService updateCompanyProperty property= ' + JSON.stringify(property));
            LoggerHelper.log('SegmentService updateCompanyProperty extras= ' + JSON.stringify(extras));
            analytics.group(companyCode, property, {
                context: extras,
            });
        }
    }

    /**
     * @name LogEvent
     * @param {string} eventName Name of the action to track.
     * @param {AnalyticsExtraModel} extras
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEvent(eventName: string, extras?: AnalyticsExtraModel) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService logEvent= ' + eventName);
            LoggerHelper.log('SegmentService logEvent extras= ' + JSON.stringify(extras));
            analytics.track(eventName, {
                context: extras,
            });
        }
    }

    /**
     * @name logEventWithValue
     * @param {string} eventName Name of the action to track.
     * @param {*} eventValue Actions object with custom properties.
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEventWithValue(eventName: string, eventValue: any, extras?: AnalyticsExtraModel) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('SegmentService logEventWithValue eventName= ' + eventName);
            LoggerHelper.log('SegmentService logEventWithValue eventValue= ' + JSON.stringify(eventValue));
            LoggerHelper.log('SegmentService logEventWithValue extras= ' + JSON.stringify(extras));
            analytics.track(eventName, eventValue, {
                context: extras,
            });
        }
    }
}
