// src/app/favicon.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  constructor() {}

  /**
   * Updates the favicon to a specified URL.
   * @param faviconUrl - The URL of the new favicon image.
   */
  setFavicon(faviconUrl: string) {
    // Check if there is an existing favicon link element
    let link: HTMLLinkElement | null = document.querySelector('#favIcon');

    // If not, create one
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    // Set the new href (URL) for the favicon
    link.href = faviconUrl;
  }
}
