export class HttpExceptionKeys {
    public static ServerError = 'Server error';
    public static UnAuthorized = 'Unauthorized';
    public static NoAccess = 'No access';
    public static BadRequest = 'Bad Request';
    public static NotFound = 'Not Found';
    public static Conflict = 'Conflict';
    public static AlreadyExists = 'Already Exists';
    public static ServiceUnavailable = 'Service Unavailable';
    public static NoInternetConnection = 'No Internet Connection';
}
