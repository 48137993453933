import { InstitutionPreferenceResponseModel } from '../../../../../src/app/core/models/institution/response/institution-preference-response.model';
import { CourseFullResponseModel } from '../../../../../src/app/core/models/my-courses/response/course-full-response.model';
import { InstructorInstitutionCourseResponseModel } from '../../../../../src/app/core/models/my-courses/response/instructor-institution-course-response.model';
import { UserRolePermissionResponseModel } from '../../../../../src/app/core/models/roles/user-role-permission-response.model';
import { UserPreferencesModel } from '../../../../../src/app/core/models/session/user-preferences.model';
import { UserSessionModel } from '../../../../../src/app/core/models/session/user-session.model';
import { BrandingResponseModel } from '../../../../../src/app/core/models/shared/branding/response/branding.response.model';
import { InstitutionSettingResponseModel } from '../../../core/models/institution/response/institution-settings.response.model';
import { CachingHelper } from '../caching/caching';

export class SessionHelper {
    /**
     * @name clearSessionData
     * Clear the session data across the platform
     */
    public static clearSessionData() {
        CachingHelper.clearData();

        BrandingResponseModel.clearFullInstance();
        UserSessionModel.clearInstance();
        UserPreferencesModel.ClearInstance();
        UserRolePermissionResponseModel.ClearInstance();
        InstructorInstitutionCourseResponseModel.clearFullInstance();
        InstitutionPreferenceResponseModel.ClearInstance();
        InstitutionSettingResponseModel.clearFullInstance();
        CourseFullResponseModel.ClearInstance();
    }
}
