export enum CategoryType {
    /*------------------------------------*\
      #GettingStarted
    \*------------------------------------*/
    GettingStarted = 'getting started',

    /*------------------------------------*\
      #DASHBOARD
    \*------------------------------------*/
    Dashboard = 'dashboard',

    /*------------------------------------*\
      #My Courses
    \*------------------------------------*/
    MyCourses = 'course',

    /*------------------------------------*\
      #Course summary
    \*------------------------------------*/
    CourseSummary = 'course summary',

    /*------------------------------------*\
      #LEARNER REPORT
    \*------------------------------------*/
    LearnerReport = 'learner-report',

    /*------------------------------------*\
      #CONTENT REPORT
    \*------------------------------------*/
    ContentReport = 'content-report',

    /*------------------------------------*\
      #REPORT
    \*------------------------------------*/
    Report = 'report',

    /*------------------------------------*\
      #Analytics
    \*------------------------------------*/
    Analytics = 'analytics',

    /*------------------------------------*\
      #LIVEBOARD
    \*------------------------------------*/
    Liveboard = 'liveboard',

    /*------------------------------------*\
      #Live Session
    \*------------------------------------*/
    LiveSession = 'live session',

    /*------------------------------------*\
      #Recorded Session
    \*------------------------------------*/
    RecordedSession = 'recorded session',

    /*------------------------------------*\
      #Content library
    \*------------------------------------*/
    ContentLibrary = 'content library',

    /*------------------------------------*\
      #Assessment
    \*------------------------------------*/
    Assessment = 'assessment',

    /*------------------------------------*\
      #Exercise Correction
    \*------------------------------------*/
    ExerciseCorrection = 'exercise correction',

    /*------------------------------------*\
      #Manage subscription
    \*------------------------------------*/
    ManageSubscription = 'manage subscription',

    /*------------------------------------*\
      #Manage account
    \*------------------------------------*/
    ManageAccount = 'manage accounts',

    /*------------------------------------*\
      #Manage user
    \*------------------------------------*/
    ManageUser = 'manage users',

    /*------------------------------------*\
      #Manage user group
    \*------------------------------------*/
    ManageUserGroup = 'manage user group',

    /*------------------------------------*\
      #Manage user enrollment
    \*------------------------------------*/
    ManageUserEnrollment = 'manage user enrollment',

    /*------------------------------------*\
      #Learning outcome
    \*------------------------------------*/
    LearningOutcomes = 'learning outcomes',

    /*------------------------------------*\
      #Curriculum management
    \*------------------------------------*/
    CurriculumManagement = 'curriculum management',

    /*------------------------------------*\
      #Site builder
    \*------------------------------------*/
    SiteBuilder = 'site builder',
}
