import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, UrlTree } from '@angular/router';
import { RoleHelper } from '../../../../../../src/app/shared/helper/role/role-helper';
import { RoleType } from '../../../../../../src/app/shared/helper/role/role.enum';
import { SubscriptionKey } from '../../../../../../src/app/shared/helper/subscription/subscription.enum';
import { TranslationService } from '../../../../config/services/translation.service';
import { InstitutionPreferenceResponseModel } from '../../../../core/models/institution/response/institution-preference-response.model';
import { UserSessionModel } from '../../../../core/models/session/user-session.model';
import { SubscriptionResponseModel } from '../../../../core/models/shared/subscription/response/subscription.response.model';
import { SubscriptionApplicationService } from '../../../../core/services/subscription/subscription-application.service';
import { SharedService } from '../../../../_services/SharedService';
import { DialogData } from '../../../components/dialog-builder/dialog-single-input-model';
import { DialogInformativeNewComponent } from '../../../components/dialog-builder/informative/new/dialog-informative.component';
import { CachingHelper } from '../../../helper/caching/caching';
import { CachingKeys } from '../../../helper/caching/caching-keys';
import { LoggerHelper } from '../../../helper/logging/logger';
import { NavigationKeys } from '../../../helper/navigation-keys';
import { IconsList } from '../../../helper/theme/icons';
import { ThemeType } from '../../../helper/theme/theme-type.enum';
import { SubscriptionPlanFeatureModel } from '../../../models/subscription/subsription-plan-features.model';
import { SubscriptionPlanModel } from '../../../models/subscription/subsription-plan.model';
import { UserRolesViewModel } from '../../roles/user-roles.viewmodel';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionViewModel {
    isRTL: boolean = false;

    //--------------- translation --------------------
    public translationUpgradePlanTitle: string = '';
    public translationUpgradePlanDescription: string = '';
    public translationUpgrade: string = '';
    public translationDismiss: string = '';
    //--------------- translation --------------------

    constructor(
        private _router: Router,
        public translationService: TranslationService,
        public service: SubscriptionApplicationService,
        private userRoleViewModel: UserRolesViewModel,
        private dialog: MatDialog,
        private sharedService: SharedService
    ) {
        this.labelUpdate();
    }

    /**
     * @name labelUpdate
     * Load translations
     */
    private labelUpdate() {
        this.isRTL = this.translationService.isPlatformDirectionRtl();

        this.translationUpgradePlanTitle = this.translationService.GetTranslatedValue('Subscription.UpgradeSubscriptionTitle');
        this.translationUpgradePlanDescription = this.translationService.GetTranslatedValue('Subscription.UpgradeSubscriptionDescription');
        this.translationUpgrade = this.translationService.GetTranslatedValue('Subscription.Upgrade');
        this.translationDismiss = this.translationService.GetTranslatedValue('GENERAL.Dismiss');
    }

    /**
     * @name validateIfUserHasActiveSubscription
     * @returns true if user has active subscription, false if not
     * Check in the cache the last time we checked for the validation.
     *    If today
     *        & valid then return true
     *        & not valid return false
     *    If not today
     *        Call API to fetch information and store them in the cache
     *            if valid then return true
     *            if not valid return false
     *        -> reload roles/permission API
     */
    async validateIfUserHasActiveSubscription(forceLoadDataFromServer: boolean = false): Promise<boolean> {
        if (forceLoadDataFromServer) {
            LoggerHelper.log('Subscription has been forced to update. Updating it now');
            return await this.forceLoadDataFromServer(false);
        } else {
            if (JSON.parse(CachingHelper.getItemFromCache(CachingKeys.ActiveSubscription))) {
                var datePipe = new DatePipe('en-US');
                if (datePipe.transform(SubscriptionResponseModel.Instance.lastTimeChecked, 'dd/MM/yyyy') === datePipe.transform(new Date(), 'dd/MM/yyyy')) {
                    LoggerHelper.log('Subscription has been already updated today');
                    return this.validateIfUserHasActiveSubscriptionAfterLoadingData();
                } else {
                    LoggerHelper.log('Subscription has not been updated today. Updating it now');
                    return await this.forceLoadDataFromServer();
                    // await this.loadSubscriptionInformation();
                    // await this.userRoleViewModel.getUserPermission(InstitutionPreferenceResponseModel.Instance.institutionId, UserSessionModel.Instance.user.id);
                    // return this.validateIfUserHasActiveSubscriptionAfterLoadingData();
                }
            } else {
                LoggerHelper.log('Subscription has no records. Updating it now');
                return await this.forceLoadDataFromServer();
                // await this.loadSubscriptionInformation();
                // await this.userRoleViewModel.getUserPermission(InstitutionPreferenceResponseModel.Instance.institutionId, UserSessionModel.Instance.user.id);
                // return this.validateIfUserHasActiveSubscriptionAfterLoadingData();
            }
        }
    }

    /**
     * @name validateSubscriptionAfterLoadingData
     * @returns true if subscription is valid, false if not
     */
    private validateIfUserHasActiveSubscriptionAfterLoadingData(): boolean {
        LoggerHelper.log('---------- Subscription ---------');
        LoggerHelper.log(SubscriptionResponseModel.Instance);
        if (!JSON.parse(CachingHelper.getItemFromCache(CachingKeys.ActiveSubscription)) || SubscriptionResponseModel.Instance.hasExpired) {
            return false;
        }
        return true;
    }

    /**
     * @name forceLoadDataFromServer
     * @param loadUserPermission
     * @returns true if user has active subscription, false if not
     */
    private async forceLoadDataFromServer(loadUserPermission: boolean = true): Promise<boolean> {
        await this.loadSubscriptionInformation();
        if (loadUserPermission) {
            await this.userRoleViewModel.getUserPermission(InstitutionPreferenceResponseModel.Instance.institutionId, UserSessionModel.Instance.user.id);
        }
        return this.validateIfUserHasActiveSubscriptionAfterLoadingData();
    }

    /**
     * @name loadSubscriptionInformation
     * Load subscription date from api then update the date of the last time checked
     */
    private async loadSubscriptionInformation() {
        var subscriptionResponseModel: SubscriptionResponseModel = await this.getActiveSubscriptionInformation(InstitutionPreferenceResponseModel.Instance.institutionId);
        if (subscriptionResponseModel != null) {
            subscriptionResponseModel.lastTimeChecked = new Date();
            SubscriptionResponseModel.clearInstance();
            SubscriptionResponseModel.updateInstance(subscriptionResponseModel);
            this.sharedService.onUserRoleChange.emit(true);
        }
    }

    /**
     * @name getActiveSubscriptionInformation
     * @param institutionId
     * @param courseId
     * @param userId
     * @returns
     */
    private async getActiveSubscriptionInformation(institutionId: number): Promise<SubscriptionResponseModel> {
        return await this.service
            .getActiveSubscriptionInformation(institutionId)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @forceNavigateUserToSubscriptionPage
     * @returns the URL of the navigation
     * Check if in free tial period
     *    if true then go to trial-ended
     *    else then go to error
     */
    public forceNavigateUserToSubscriptionPage(): UrlTree {
        if (SubscriptionResponseModel.Instance.isFreeTrial) {
            return this._router.parseUrl(NavigationKeys.SubscriptionPage + '/' + NavigationKeys.SubscriptionTrialEndedPage);
        } else {
            return this._router.parseUrl(NavigationKeys.SubscriptionPage + '/' + NavigationKeys.SubscriptionErrorPage);
        }
    }

    /**
     * @name redirectUserPopup
     * Redirect user popup
     */
    redirectUserPopup() {
        var dialogFeatureReleaseModel: DialogData = new DialogData();
        dialogFeatureReleaseModel.icon = IconsList.Subject;
        dialogFeatureReleaseModel.theme = ThemeType.Error;
        dialogFeatureReleaseModel.title = this.translationService.GetTranslatedValue('GENERAL.Warning');
        dialogFeatureReleaseModel.description = this.translationService.GetTranslatedValue('Subscription.Manage.');
        dialogFeatureReleaseModel.negativeButtonText = this.translationService.GetTranslatedValue('GENERAL.Dismiss');
        dialogFeatureReleaseModel.positiveButtonText = this.translationService.GetTranslatedValue('GENERAL.Continue');
        dialogFeatureReleaseModel.positiveButtonActionUrl = SubscriptionResponseModel.Instance.subscriptionLink;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = dialogFeatureReleaseModel;
        dialogConfig.panelClass = 'custom-dialog-container';
        if (this.isRTL) {
            dialogConfig.direction = 'rtl';
        } else {
            dialogConfig.direction = 'ltr';
        }

        const dialogRef = this.dialog.open(DialogInformativeNewComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                SubscriptionResponseModel.clearFullInstance();
            }
        });
    }

    /**
     * @name generateAppSumoPricingTable
     */
    generateAppSumoPricingTable(): SubscriptionPlanModel[] {
        var subscriptionPlanModel: SubscriptionPlanModel[] = [];
        subscriptionPlanModel.push(this.generateAppSumoPlan('AppSumo Tier 1', 69, '$', '100GB', 6, 1, 150, '0', false, true, false, false, false, false));
        subscriptionPlanModel.push(this.generateAppSumoPlan('AppSumo Tier 2', 159, '$', '200GB', 5, 5, 500, '5', false, true, false, false, false, false));
        subscriptionPlanModel.push(this.generateAppSumoPlan('AppSumo Tier 3', 299, '$', '400GB', 3, 15, 2000, 'Unlimited', true, true, true, true, true, true));
        subscriptionPlanModel.push(this.generateAppSumoPlan('AppSumo Tier 4', 499, '$', '1TB', 3, 30, 5000, 'Unlimited', true, true, true, true, true, true));
        return subscriptionPlanModel;
    }

    /**
     * @name generateAppSumoPlan
     * @returns
     */
    private generateAppSumoPlan(
        planName: string,
        planPrice: number,
        planCurrency: string,
        planAccessStorage: string,
        planRevenueShare: number,
        planAdminsLimit: number,
        planLearnersLimit: number,
        planSubAccountsLimit: string,
        planCustomDomainEnabled: boolean,
        planWhiteLabelEnabled: boolean,
        planFeatureAnalytics: boolean,
        planFeatureCloudRecordings: boolean,
        planFeatureContentAnalytics: boolean,
        planFeatureVideoConferencing: boolean
    ): SubscriptionPlanModel {
        var subscriptionPlanModel = new SubscriptionPlanModel();
        subscriptionPlanModel.planName = planName;
        subscriptionPlanModel.planPrice = planPrice;
        subscriptionPlanModel.planCurrency = planCurrency;
        // subscriptionPlanModel.planAccessStorage = planAccessStorage;
        // subscriptionPlanModel.planRevenueShare = planRevenueShare;

        // subscriptionPlanModel.planAdminsLimit = planAdminsLimit;
        // subscriptionPlanModel.planLearnersLimit = planLearnersLimit;
        // subscriptionPlanModel.planSubAccountsLimit = planSubAccountsLimit;

        subscriptionPlanModel.features = [];

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = planAccessStorage + ' Storage ';
        feature.isEnabled = null;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = planAdminsLimit + ' Admin users ';
        feature.isEnabled = null;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = planLearnersLimit + ' Active learners ';
        feature.isEnabled = null;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = planSubAccountsLimit + ' Sub-accounts ';
        feature.isEnabled = null;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = planRevenueShare + '%' + ' Transaction fee ';
        feature.isEnabled = null;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Custom branding';
        feature.isEnabled = planWhiteLabelEnabled;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Custom domain';
        feature.isEnabled = planCustomDomainEnabled;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Advanced analytics';
        feature.isEnabled = planFeatureAnalytics;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Content-based analytics';
        feature.isEnabled = planFeatureContentAnalytics;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Video conferencing';
        feature.isEnabled = planFeatureVideoConferencing;
        subscriptionPlanModel.features.push(feature);

        var feature = new SubscriptionPlanFeatureModel();
        feature.featureName = 'Cloud recordings';
        feature.isEnabled = planFeatureCloudRecordings;
        subscriptionPlanModel.features.push(feature);

        return subscriptionPlanModel;
    }

    /**
     * @name upgradePlanPopup
     */
    public upgradePlanPopup() {
        LoggerHelper.log('Upgrade popup');

        var dialogFeatureReleaseModel: DialogData = new DialogData();
        dialogFeatureReleaseModel.icon = IconsList.Upgrade;
        dialogFeatureReleaseModel.theme = ThemeType.Primary;
        dialogFeatureReleaseModel.title = this.translationUpgradePlanTitle;
        dialogFeatureReleaseModel.description = this.translationUpgradePlanDescription;
        dialogFeatureReleaseModel.negativeButtonText = this.translationDismiss;

        if (RoleHelper.getUserRole().find((element) => element == RoleType.Administrator)) {
            dialogFeatureReleaseModel.positiveButtonText = this.translationUpgrade;
        } else {
            dialogFeatureReleaseModel.positiveButtonText = '';
        }
        dialogFeatureReleaseModel.positiveButtonActionUrl = '';

        // if (SubscriptionResponseModel.Instance.subscriptionLink && SubscriptionResponseModel.Instance.subscriptionLink.length > 0) {
        //   dialogFeatureReleaseModel.positiveButtonText = this.translationUpgrade;
        //   dialogFeatureReleaseModel.positiveButtonActionUrl = SubscriptionResponseModel.Instance.subscriptionLink;
        // } else {
        //   dialogFeatureReleaseModel.positiveButtonText = '';
        // }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = dialogFeatureReleaseModel;
        dialogConfig.panelClass = 'custom-dialog-container';
        const dialogRef = this.dialog.open(DialogInformativeNewComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (RoleHelper.getUserRole().find((element) => element == RoleType.Administrator)) {
                    //redirect the admin to subscription
                    this._router.navigate([NavigationKeys.TeacherModule + '/' + NavigationKeys.ManageSubscriptionPage]);
                }
            }
        });
    }

    /**
     * @name featureLockedPopup
     */
    public featureLockedPopup() {
        LoggerHelper.log('Feature locked popup');

        var dialogFeatureReleaseModel: DialogData = new DialogData();
        dialogFeatureReleaseModel.icon = IconsList.Upgrade;
        dialogFeatureReleaseModel.theme = ThemeType.Primary;
        dialogFeatureReleaseModel.title = this.translationService.GetTranslatedValue('Subscription.FeatureLockedSubscriptionTitle');
        dialogFeatureReleaseModel.negativeButtonText = this.translationService.GetTranslatedValue('GENERAL.Dismiss');

        if (RoleHelper.getUserRole().find((element) => element == RoleType.Administrator)) {
            dialogFeatureReleaseModel.description = this.translationService.GetTranslatedValue('Subscription.FeatureLockedAdminSubscriptionDescription');
            dialogFeatureReleaseModel.positiveButtonText = this.translationService.GetTranslatedValue('GENERAL.Manage');
        } else {
            dialogFeatureReleaseModel.description = this.translationService.GetTranslatedValue('Subscription.FeatureLockedInstructorSubscriptionDescription');
            dialogFeatureReleaseModel.positiveButtonText = '';
        }
        dialogFeatureReleaseModel.positiveButtonActionUrl = '';

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = dialogFeatureReleaseModel;
        dialogConfig.panelClass = 'custom-dialog-container';
        const dialogRef = this.dialog.open(DialogInformativeNewComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (RoleHelper.getUserRole().find((element) => element == RoleType.Administrator)) {
                    //redirect the admin to subscription
                    this._router.navigate([NavigationKeys.TeacherModule + '/' + NavigationKeys.ManageAccountsPage]);
                }
            }
        });
    }

    /**
     * @name loadCurrentSubscription
     * @returns
     * Loads the current subscription from cache
     */
    public loadCurrentSubscription(): string {
        if (SubscriptionResponseModel.Instance.isFreeTrial != null && SubscriptionResponseModel.Instance.isFreeTrial) {
            return SubscriptionKey.Free;
        } else {
            switch (SubscriptionResponseModel.Instance.planName.toLowerCase()) {
                case SubscriptionKey.Free:
                    return SubscriptionKey.Free;
                case SubscriptionKey.Starter:
                    return SubscriptionKey.Starter;
                case SubscriptionKey.Plus:
                    return SubscriptionKey.Plus;
                case SubscriptionKey.Pro:
                    return SubscriptionKey.Pro;
                default:
                    return null;
            }
        }
    }
}
