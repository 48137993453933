import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SubscriptionApiVariables {
    public readonly AugmentalBaseApiServer = environment.baseApiServer;
    public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

    public readonly ApiSubscriptionValidation = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Subscription';
}
