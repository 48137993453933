import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserApiVariables {
  public readonly AugmentalIdentityApiServer = environment.identityApiServer;
  public readonly AugmentalBaseApiServer = environment.baseApiServer;
  public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

  public readonly ApiRegisterAdminUser = this.AugmentalBaseApiServer + '/api/Institutions/AUG_ZS6JII/Administrators/SignUp()?isTemporaryUser=true';
  public readonly ApiCheckUserEmailAvailability = this.AugmentalIdentityApiServer + '/api/Email/{0}/CheckAvailability()';
  public readonly ApiGetUserStatus = this.AugmentalIdentityApiServer + '/api/Users/SignUp/Status';

  public readonly ApiCheckIfLearnersLimitReachedByInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Subscription/Learners/Limit/Reached';
  public readonly ApiCheckIfAdminsLimitReachedByInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Subscription/Staff/Limit/Reached';
}
