import { UrlFormatterHelper } from './../../../../core/helper/url-formatter-helper';
import { EncryptionDecryptionHelper } from './../../../helper/encryption-decryption/encryption-decryption.helper';
import { LiveSessionApplicationService } from './../../../../core/services/live-session/live-session-application.service';
import { Injectable } from "@angular/core";
import { EncryptionDecryptionKeys } from '../../../../../../src/app/shared/helper/encryption-decryption/encryption-decryption-keys';

@Injectable({
    providedIn: 'root'
})
export class LearnerLiveSessionViewModel {
    constructor(private service: LiveSessionApplicationService) { }




    /**
     * @name GetLearnerLiveSessions
     * @param institutionId
     * @param learnerId
     * @param minutesRange
     * Get live session related to a specific learner
     */
    public GetLearnerLiveSessions(
        institutionId: number,
        learnerId: number,
        minutesRange: number,
        todayOnly: boolean = false,
        upcomingOnly: boolean = false,
        rangeOfDays: number = 7
    ) {
        var data = this.service.GetLearnerLiveSessions(
            institutionId,
            learnerId,
            minutesRange,
            todayOnly,
            upcomingOnly,
            rangeOfDays
        );
        return data;
    }


    /**
     * @name JoinLearnerLiveSession
     * @param liveSessionId
     * Join a live session
     */
    public JoinLearnerLiveSession(liveSessionId: number) {
        var data = this.service.JoinLearnerLiveSession(liveSessionId);
        return data;
    }




    /**
     * @name GenerateLearnerLiveSessionShareLink
     * @param learnerId
     * @param liveSessionId
     * generate live session share link
     */
    public GenerateLearnerLiveSessionShareLink(
        learnerId: number,
        liveSessionId: number
    ) {
        var data = this.service.GenerateLearnerLiveSessionShareLink(
            learnerId,
            liveSessionId
        );
        return data;
    }




    /**
     * @name GetLearnerRecordedSession
     * @param institutionId
     * @param learnerId
     * Get recorded session
     */
    public GetLearnerRecordedSession(institutionId: number, learnerId: number) {
        var data = this.service.GetLearnerRecordedSession(institutionId, learnerId);
        return data;
    }



    /**
     * @name JoinLiveSession
     * @param meetingId
     * Join live session from authorized user link
     */
    public JoinLiveSessionAsAuthorizedUserLink(meetingId: string) {
        var data = this.service.JoinLiveSessionAsAuthorizedUserLink(meetingId);
        return data;
    }


    /**
     * @name CheckIfAllowingGuestsToJoin
     * @param meetingId
     * check if allowing guests to join in this meeting
     */
    public CheckIfAllowingGuestsToJoin(meetingId: string) {
        var data = this.service.CheckIfAllowingGuestsToJoin(meetingId);
        return data;
    }

    /**
     * @name JoinLiveSessionAsGuestLink
     * @param meetingId
     * @param guestName
     * Join live session as guest
     */
    public JoinLiveSessionAsGuestLink(meetingId: string, guestName: string, passcode: string) {
        var data = this.service.JoinLiveSessionAsGuestLink(meetingId, guestName, passcode);
        return data;
    }


    /**
     * Prepare share message link
     * @param message
     * @param encryptedPassword
     */
    PrepareShareInvitationMessage(
        message: string,
        encryptedPassword: string
    ): string {
        var decryptedPassword = EncryptionDecryptionHelper.DecryptStringAES(
            EncryptionDecryptionKeys.EncryptDecryptCommonKey,
            EncryptionDecryptionKeys.IVCommonKey,
            encryptedPassword
        );
        return UrlFormatterHelper.FormatStringWithMultipleValues(
            message,
            decryptedPassword
        );
    }

}