import { Injectable } from '@angular/core';
import { UserInstitutionPreferenceRequestModel } from '../../../../../src/app/core/models/institution/request/user-institution-preference-request.model';
import { InstitutionSettingResponseModel } from '../../../core/models/institution/response/institution-settings.response.model';
import { InstitutionApplicationService } from '../../../core/services/institutions/institution-application-service.service';

@Injectable({
    providedIn: 'root',
})
export class InstitutionViewModel {
    constructor(private service: InstitutionApplicationService) { }

    /**
     * @name GetAllInstitutionByInstructorId
     * @param userId
     * Get all institutions by instructor id
     */
    public GetAllInstitutionByInstructorId(userId: number) {
        var data = this.service.GetAllInstitutionByInstructorId(userId);
        return data;
    }

    /**
     * @name GetAllInstitution
     * @param userId
     * @param body
     * Get all available institution by user id
     */
    public GetAllInstitution(userId: number, body: string) {
        var data = this.service.GetAllInstitution(userId, body);
        return data;
    }

    /**
     * @name GetUserInstitutionPreferenceRequestModel
     * @param selectedInstitutionPreferenceId
     * Fills the request model to get institution preferences list
     */
    public GetUserInstitutionPreferenceRequestModel(selectedInstitutionPreferenceId: number): UserInstitutionPreferenceRequestModel {
        var userInstitutionPreferenceRequestModel = new UserInstitutionPreferenceRequestModel();
        userInstitutionPreferenceRequestModel.isBotApp = false;
        userInstitutionPreferenceRequestModel.isLearnerApp = false;
        userInstitutionPreferenceRequestModel.isInstructorApp = false;
        userInstitutionPreferenceRequestModel.isCmsApp = true;
        userInstitutionPreferenceRequestModel.selectedInstitutionPreferenceId = selectedInstitutionPreferenceId;
        return userInstitutionPreferenceRequestModel;
    }
    /**
     * @name UpdateUserInstitutionPreferences
     * @param body
     * Update the selected institution preferences
     */
    public UpdateUserInstitutionPreferences(body: string) {
        var data = this.service.UpdateUserInstitutionPreferences(body);
        return data;
    }

    /**
     * @name getInstitutionSettings
     * @param institutionId
     * get institution settings
     */
    async getInstitutionSettings(institutionId: number): Promise<InstitutionSettingResponseModel> {
        return await this.service
            .getInstitutionSettings(institutionId)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }
}
