import * as CryptoJS from 'crypto-js';

export class EncryptionHelper {
    /**
     * Encrypts the string to bytes aes.
     * @param plainText
     * @param key
     * @param iv
     * @param cipherMode
     * @param paddingMode
     * @param feedbackSize
     */
    public static EncryptStringAES_Advanced(plainText: string, key: string, iv: string, cipherMode: any, paddingMode: any, feedbackSize: number): string {
        try {
            var keyBytes = CryptoJS.enc.Utf8.parse(key);
            var ivBytes = CryptoJS.enc.Utf8.parse(iv);
            var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), keyBytes, {
                keySize: feedbackSize,
                iv: ivBytes,
                mode: cipherMode,
                padding: paddingMode,
            });
            return encryptedlogin.toString();
        } catch (e) {
            return '';
        }
    }

    /**
     * Encrypts the string to bytes aes.
     * @param plainText
     * @param key
     * @param iv
     */
    public static EncryptStringAES(plainText: string, key: string, iv: string): string {
        try {
            var keyBytes = CryptoJS.enc.Utf8.parse(key);
            var ivBytes = CryptoJS.enc.Utf8.parse(iv);

            var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), keyBytes, {
                iv: ivBytes,
            });
            return encryptedlogin.toString();
        } catch (e) {
            return '';
        }
    }

    /**
     * Encrypts the string
     * @param plainText
     * @param key
     * @param iv
     */
    public static EncryptStringHmacSHA256(plainText: string, key: string): string {
        try {
            var signedString = CryptoJS.HmacSHA256(plainText, key);
            var resultSign = CryptoJS.enc.Hex.stringify(signedString);
            return resultSign;
        } catch (e) {
            return '';
        }
    }
}
