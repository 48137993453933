import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { TranslationService } from '../config/services/translation.service';
import { HttpExceptionKeys } from '../core/http/http-call/http-exception-keys';
import { InstitutionSettingResponseModel } from '../core/models/institution/response/institution-settings.response.model';
import { SaltRequestModel } from '../core/models/session/request/salt.request.model';
import { SessionRequestModel } from '../core/models/session/request/user-session.request.model';
import { UserPreferencesModel } from '../core/models/session/user-preferences.model';
import { UserSessionModel } from '../core/models/session/user-session.model';
import { BrandingResponseModel } from '../core/models/shared/branding/response/branding.response.model';
import { UserSignUpStatusResponseModel } from '../core/models/shared/user/response/user-sign-up-status.response.model';
import { Helpers } from '../helpers';
import { DialogInformative } from '../shared/components/dialog-builder/informative/dialog-informative.component';
import { AnalyticsService } from '../shared/helper/analytics/analytics.service';
import { AnalyticsViewModel } from '../shared/helper/analytics/analytics.viewmodel';
import { BrandingHelper } from '../shared/helper/branding-helper';
import { EncryptionDecryptionKeys } from '../shared/helper/encryption-decryption/encryption-decryption-keys';
import { EncryptionDecryptionHelper } from '../shared/helper/encryption-decryption/encryption-decryption.helper';
import { LoggerHelper } from '../shared/helper/logging/logger';
import { NavigationKeys } from '../shared/helper/navigation-keys';
import { TitleService } from '../shared/helper/platform/title.service';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { DialogData } from './../shared/components/dialog-builder/dialog-single-input-model';
import { SnackBar } from './../shared/components/snackbar/snackbar';
import { LearnerLiveSessionViewModel } from './../shared/viewmodel/learner/live-session/learner-live-session.viewmodel';
import { UserSessionViewModel } from './../shared/viewmodel/session/user-session.viewmodel';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/auth.component.html',
    styleUrls: ['./templates/auth.style.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    isLockedAccess: boolean = false;
    institutionSettings: InstitutionSettingResponseModel;

    augmentalWebsite: string = '';
    isAccountOnCustomDomainPlan: boolean;
    brandingIsWhiteLabelling: boolean;
    brandingLogo: string;
    brandingSlogan: string;
    brandingTermsLink: string;
    brandingPrivacyLink: string;

    model: any = {};
    loading = false;
    showErrorMessage = false;
    returnUrl: string = '';
    meetingId: string;
    authenticationCode: string;

    errorMessage: string = '';
    private errorMessageCredentialsError: string = "Oops, we couldn't sign you in. Please check your credentials and try again.";
    private errorMessageUserDeactivated: string = 'Your account has been deactivated. Contact your administrator to know more.';

    allowGuests: boolean = false;

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    constructor(
        private titleService: TitleService,
        private _router: Router,
        private _script: ScriptLoaderService,
        private activatedRoute: ActivatedRoute,
        private translationService: TranslationService,
        private viewModel: UserSessionViewModel,
        private brandingHelper: BrandingHelper,
        private liveSessionViewModel: LearnerLiveSessionViewModel,
        private analyticsService: AnalyticsService,
        private analyticsViewModel: AnalyticsViewModel,
        private _snackBar: SnackBar,
        public dialog: MatDialog
    ) {
        this.titleService.setPageTitle(NavigationKeys.LoginPage);

        LoggerHelper.log('Inside login page');
        this.validateAccessToSignUpModule();
    }

    async ngOnInit() {
        await this.validateBranding();
        this.getIdFromUrl();
        this.initializeDataBasedOnInstitution();

        //switch between the forms in the login page
        this.model.remember = true;

        this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo6/base/scripts.bundle.js'], true).then(() => {
            Helpers.setLoading(false);
        });

        this.translationService.getSelectedLanguage().subscribe((lang) => {
            if (lang) {
                setTimeout(() => {
                    if (UserPreferencesModel.Instance && UserPreferencesModel.Instance.languageIsoCode) {
                        this.translationService.setLanguageBasedOnAccountType(UserPreferencesModel.Instance);
                    } else {
                        this.translationService.setLanguage(lang);
                    }
                });
            }
        });
    }

    /**
     * @name validateAccessToSignUpModule
     */
    private async validateAccessToSignUpModule() {
        await this.getInstitutionSettings();
    }

    private async validateBranding() {
        if (!BrandingResponseModel.Instance || !BrandingResponseModel.Instance.learningSpaceName || BrandingResponseModel.Instance.learningSpaceName.length == 0) {
            await this.brandingHelper.loadBranding();
        }
    }

    /**
     * @name GetIdFromUrl
     * Get id from url
     */
    private async getIdFromUrl() {
        this.activatedRoute.queryParams.takeUntil(this.destroyed$).subscribe((params) => {
            this.returnUrl = params['returnUrl'];
            this.meetingId = params['meetingId'];
            this.authenticationCode = params['authentication'];
            LoggerHelper.log('returnUrl = ' + this.returnUrl);
            LoggerHelper.log('meetingId = ' + this.meetingId);
        });

        if (this.meetingId) {
            this.checkIfAllowingGuestsToJoin(this.meetingId);
        }
        if (this.authenticationCode) {
            this.handleUserRedirection(await this.authenticateUserSilentlyUsingCode(this.authenticationCode));
        }

        // if (this.brandingHelper.getDomainNameFromUrl() == 'localhost' || this.brandingHelper.getDomainNameFromUrl() == 'signup' + environment.webAppDomain) {
        //   this.isLockedAccess = true;
        // }
        if (this.brandingHelper.getDomainNameFromUrl() == 'signup' + environment.webAppDomain) {
            this.isLockedAccess = true;
        }
    }

    /**
     * @name onLoginButtonClick
     * @returns
     * call the login function and store the user and session in the local storage
     */
    onLoginButtonClick() {
        //display error if mail or password are not filled
        if (!this.model.email || !this.model.password) {
            this.errorMessage = this.errorMessageCredentialsError;
            this.showErrorMessage = true;
            return;
        }

        this.validateModuleAccess();
    }

    /**
     * @name validateModuleAccess
     * Validate module access
     */
    private async validateModuleAccess() {
        //check if the current app is signup.augmentalapp.com
        if (this.isLockedAccess) {
            var userStatus = await this.getUserStatus("'" + this.model.email + "'");
            if (userStatus.isInstitutionSetupCompleted) {
                //redirect the user to the domain
                window.location.href = userStatus.redirectUrl;
            } else {
                if (userStatus.isUserSetupCompleted) {
                    //redirect the user to the account setup
                    await this.authenticateUser(this.model.email);
                } else {
                    //redirect the user to the account welcome
                    this._router.navigate([NavigationKeys.AccountRegistrationModule + '/' + NavigationKeys.AccountWelcomePage]);
                }
            }
        } else {
            LoggerHelper.log('Authenticating');
            this.authenticateUser(this.model.email);
        }
    }

    /**
     * @name redirectUserAfterSuccessfulAuthentication
     * Redirect the user after a successful authentication
     */
    private redirectUserAfterSuccessfulAuthentication() {
        this.setupAnalytics();

        this._router.navigate([NavigationKeys.RoutingPage], {
            queryParams: {
                returnUrl: this.returnUrl,
            },
        });
    }

    /**
     * @name setupAnalytics
     * Setup the analytics
     */
    private setupAnalytics() {
        //Init service
        this.analyticsService.initAnalyticsService();

        //Register user
        this.analyticsService.registerUser(UserSessionModel.Instance.user.code);

        //Register custom user property for Mixpanel
        // var analyticsUserModel: AnalyticsUserModel = this.analyticsViewModel.getSegmentUserProfileUpdateTracking(UserSessionModel.Instance);
        // this.analyticsService.setSegmentUserProperty(UserSessionModel.Instance.user.code, analyticsUserModel);

        // var analyticsUserSessionModel: AnalyticsUserSessionModel = new AnalyticsUserSessionModel();
        // analyticsUserSessionModel.username = UserSessionModel.Instance.user.username;
        // analyticsUserSessionModel.id = UserSessionModel.Instance.user.code;
        // analyticsUserSessionModel.date = DateTimeHelper.FormatDateTime(new Date(), 'HH:mm dd-MM-yyyy', 'en-US');
        // this.analyticsService.logEventWithValue(AnalyticsProperties.EventLogin, analyticsUserSessionModel, this.analyticsViewModel.getTrackEventExtras());
    }

    /**
     * @name onJoinAsGuestClick
     * join as guest
     */
    onJoinAsGuestClick() {
        this._router.navigate([NavigationKeys.JoinGuest], {
            queryParams: {
                meetingId: this.meetingId,
            },
        });
    }

    /**
     * @name showWarningDialog
     * @param liveSessionId
     * @param isDelete
     * Show warning dialog if the live session Id is not correct
     */
    private showWarningDialog(error: string) {
        var data = new DialogData();
        data.title = this.translationService.GetTranslatedValue('GENERAL.Warning');
        data.description = error;
        data.positiveButtonText = this.translationService.GetTranslatedValue('GENERAL.CLOSE');

        const dialogRef = this.dialog.open(DialogInformative, {
            data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * @name NavigateToForgotPassword
     * Navigate to forgot password
     */
    NavigateToForgotPassword() {
        this._router.navigate([NavigationKeys.ResetPasswordPage]);
    }

    /**
     * @name CheckIfAllowingGuestsToJoin
     * @param meetingId
     * check if allowing guests to join in this meeting
     */
    private async checkIfAllowingGuestsToJoin(meetingId: string) {
        await this.liveSessionViewModel
            .CheckIfAllowingGuestsToJoin(meetingId)
            .toPromise()
            .then((result) => {
                LoggerHelper.log('New Data inside CheckIfAllowingGuestsToJoin : ' + JSON.stringify(result));
                if (result == true) {
                    this.allowGuests = true;
                } else {
                    this.allowGuests == false;
                }
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
                this._snackBar.ShowWarning('An error occurred while connecting to the server. Please try again.');
            })
            .finally(() => { });
    }

    /**
     * @name joinLiveSessionAsAuthorizedUserLink
     * @param meetingId
     * Join a live session from authorized user link
     */
    private async joinLiveSessionAsAuthorizedUserLink(meetingId: string) {
        await this.liveSessionViewModel
            .JoinLiveSessionAsAuthorizedUserLink(meetingId)
            .toPromise()
            .then((result) => {
                LoggerHelper.log('New Data inside JoinLiveSessionAsAuthorizedUserLink : ' + JSON.stringify(result));
                if (result.isSuccessful) {
                    window.location.href = result.description;
                } else {
                    this.redirectUserAfterSuccessfulAuthentication();
                    this.showWarningDialog(result.description);
                }
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
                this._snackBar.ShowWarning('An error occurred while connecting to the server. Please try again.');
            })
            .finally(() => { });
    }

    /**
     * @name onGetStartedClick
     * Detect when get started button has been clicked
     */
    onGetStartedClick() {
        this._router.navigate([NavigationKeys.SignUpPage], {
            queryParams: {
                returnUrl: this.returnUrl,
            },
        });
    }

    /**
     * @name initializeDataBasedOnInstitution
     * Initialize data based on institution
     */
    private async initializeDataBasedOnInstitution() {
        this.brandingIsWhiteLabelling = this.brandingHelper.isWhiteLabellingInstitution();
        this.brandingLogo = this.brandingHelper.loadBrandingLogoOnColor();
        this.brandingPrivacyLink = this.brandingHelper.loadBrandingPrivacyLink();
        this.brandingTermsLink = this.brandingHelper.loadBrandingTermsLink();
        this.brandingSlogan = this.brandingHelper.loadBrandingTagline();

        this.augmentalWebsite = environment.augmentalWebsite;
        this.isAccountOnCustomDomainPlan = this.brandingHelper.checkIfAccountIsOnCustomDomainPlan();
    }

    /**
     * @name authenticateUser
     * @param username
     * Authenticate the user
     */
    private async authenticateUser(username: string) {
        this.loading = true;
        this.showErrorMessage = false;
        this.errorMessage = '';
        var encryptedServerSalt = await this.validateServerSalt(username);
        if (encryptedServerSalt) {
            var decryptedServerSalt = EncryptionDecryptionHelper.DecryptStringAES(EncryptionDecryptionKeys.EncryptDecryptCommonKey, EncryptionDecryptionKeys.IVCommonKey, encryptedServerSalt);
            LoggerHelper.log('Decrypted salt: ' + decryptedServerSalt);
            await this.valdiateUserAuthentication(decryptedServerSalt, this.model.password);
        } else {
            this.loading = false;
            this.errorMessage = this.errorMessageCredentialsError;
            this.showErrorMessage = true;
        }
    }

    /**
     * @name validateServerSalt
     * @param username
     * Validate the server salt
     */
    private async validateServerSalt(username: string): Promise<string> {
        var encryptedUsername = EncryptionDecryptionHelper.EncryptStringAES(EncryptionDecryptionKeys.EncryptDecryptCommonKey, EncryptionDecryptionKeys.IVCommonKey, username, 16);

        var saltRequestModel: SaltRequestModel = new SaltRequestModel();
        saltRequestModel.encryptedUsername = encryptedUsername;

        return await this.getSaltFromServer(JSON.stringify(saltRequestModel));
    }

    /**
     * @name valdiateUserAuthentication
     * @param serverSalt
     * @param password
     */
    private async valdiateUserAuthentication(serverSalt: string, password: string) {
        LoggerHelper.log('Main key: ' + EncryptionDecryptionKeys.EncryptDecryptCommonKey);
        LoggerHelper.log('Main key sliced: ' + EncryptionDecryptionKeys.EncryptDecryptCommonKey.slice(0, 8));
        LoggerHelper.log('Main IV: ' + EncryptionDecryptionKeys.IVCommonKey);
        LoggerHelper.log('Main IV sliced: ' + EncryptionDecryptionKeys.IVCommonKey.slice(0, 8));
        LoggerHelper.log('Main server salt: ' + serverSalt);
        LoggerHelper.log('Main server salt sliced: ' + serverSalt.slice(0, 8));
        LoggerHelper.log('Main key sliced + server salt sliced -> bytes: ' + CryptoJS.enc.Utf8.parse(EncryptionDecryptionKeys.EncryptDecryptCommonKey.slice(0, 8) + serverSalt.slice(0, 8)));
        LoggerHelper.log('Main IV sliced + server salt sliced -> bytes: ' + CryptoJS.enc.Utf8.parse(EncryptionDecryptionKeys.IVCommonKey.slice(0, 8) + serverSalt.slice(0, 8)));

        var encryptedPassword = EncryptionDecryptionHelper.EncryptStringAES(
            EncryptionDecryptionKeys.EncryptDecryptCommonKey.slice(0, 8) + serverSalt.slice(0, 8),
            EncryptionDecryptionKeys.IVCommonKey.slice(0, 8) + serverSalt.slice(0, 8),
            password,
            16
        );

        var userSessionRequestModel: SessionRequestModel = new SessionRequestModel();
        userSessionRequestModel.hashedPassword = encryptedPassword;
        userSessionRequestModel.application = 'CmsApp';

        var userResponseModel = await this.authenticateUserRequest(JSON.stringify(userSessionRequestModel));
        if (userResponseModel) {
            if (this.isLockedAccess) {
                this._router.navigate([NavigationKeys.AccountRegistrationModule + '/' + NavigationKeys.AccountUserSetupPage + '/' + userResponseModel.user.code + '/' + NavigationKeys.AccountSetupPage]);
            } else {
                UserSessionModel.clearInstance();
                UserSessionModel.updateInstance(userResponseModel);
                UserSessionModel.clearInstance();

                this.handleUserRedirection(userResponseModel);
            }
        }
    }

    /**
     * @name handleUserRedirection
     * @param user
     * Handle the user redirection
     */
    private handleUserRedirection(user: UserSessionModel) {
        if (user == null) {
            this.showErrorMessage = true;
            this.viewModel.clearSessionData();
        } else {
            //success
            if (this.meetingId && this.meetingId.length > 0) {
                this.joinLiveSessionAsAuthorizedUserLink(this.meetingId);
            } else {
                this.redirectUserAfterSuccessfulAuthentication();
            }
        }
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  START API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------

    /**
     * @name getUserStatus
     * @param body
     * @returns
     * Get user status
     */
    private async getUserStatus(body: string): Promise<UserSignUpStatusResponseModel> {
        this.loading = true;
        var response: UserSignUpStatusResponseModel = await this.viewModel.getUserStatus(body);
        LoggerHelper.log('New Data inside getUserStatus: ' + JSON.stringify(response));
        this.loading = false;
        return response;
    }

    /**
     * @name getSaltFromServer
     * @param body
     * @returns
     * Get salt from server based on username
     */
    private async getSaltFromServer(body: string): Promise<string> {
        var response = await this.viewModel.getSaltFromServer(body);
        LoggerHelper.log('New Data inside getSaltFromServer: ' + response);
        return response;
    }

    /**
     * @name authenticateUserRequest
     * @param body
     * @returns
     * Authenticate the user
     */
    private async authenticateUserRequest(body: string): Promise<UserSessionModel> {
        try {
            var response: UserSessionModel = await this.viewModel.authenticateUser(body);
            LoggerHelper.log('New Data inside authenticateUser: ' + JSON.stringify(response));
            this.loading = false;
            return response;
        } catch (error) {
            this.loading = false;
            if (error == HttpExceptionKeys.NoAccess) {
                this.errorMessage = this.errorMessageUserDeactivated;
            } else {
                this.errorMessage = this.errorMessageCredentialsError;
            }
            this.showErrorMessage = true;
            return null;
        }
    }

    /**
     * @name authenticateUserSilentlyUsingCode
     * @param body
     * @returns
     * Authenticate the user silently using the code
     */
    private async authenticateUserSilentlyUsingCode(authenticationCode: string): Promise<UserSessionModel> {
        this.loading = true;
        var response: UserSessionModel = await this.viewModel.authenticateUserSilentlyUsingCode(authenticationCode);
        LoggerHelper.log('New Data inside authenticateUserSilentlyUsingCode: ' + JSON.stringify(response));
        UserSessionModel.clearInstance();
        UserSessionModel.updateInstance(response);
        UserSessionModel.clearInstance();
        this.loading = false;
        return response;
    }

    /**
     * @name getInstitutionSettings
     * @param institutionId
     * @returns data
     */
    async getInstitutionSettings() {
        this.institutionSettings = await this.viewModel.getAccountSettings();
        LoggerHelper.log('New Data inside getInstitutionSettings: ' + JSON.stringify(this.institutionSettings));
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  END API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
}
