import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GlobalApiLearnersVariables {
  public readonly AugmentalIdentityApiServer = environment.identityApiServer;
  public readonly AugmentalBaseApiServer = environment.baseApiServer;
  public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

  /**
   * @name ApiGetTableOfContentsResources
   * Api to get the resources of the table of content
   */
  public readonly ApiGetTableOfContentsResources = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/CourseSessions/{1}/LinearLearning/TableOfContent';

  /**
   * @name ApiCreateUserSession
   * Api create user session
   */
  public readonly ApiCreateUserSession = this.AugmentalBaseApiServer + '/api/UserSessions';

  /**
   * @name ApiGetNextContent
   * Api get next content
   */
  public readonly ApiGetNextContent = this.AugmentalBaseApiServer + '/api/LinearLearning/NextContent';

  /**
   * @name ApiGetAdaptiveNextContent
   * Api get adaptive next content
   */
  public readonly ApiGetAdaptiveNextContent = this.AugmentalBaseApiServer + '/api/AdaptiveLearning/NextContent';

  /**
   * @name ApiGetContentPreview
   * Api to get content preview
   */
  public readonly ApiGetContentPreview = this.AugmentalBaseApiServerBff + '/api/CourseTrackings/{0}/Content/{1}/Preview';

  /**
   * @name ApiUpdateUserTracking
   * Api update user tracking
   */
  public readonly ApiUpdateUserTracking = this.AugmentalBaseApiServer + '/api/UserTrackings/{0}';

  /**
   * @name ApiSubmitAnswer
   * Api submit answer
   */
  public readonly ApiSubmitAnswer = this.AugmentalBaseApiServer + '/api/LinearLearning/Courses/{0}/Learners/{1}/UserAnswers';

  /**
   * @name ApiSubmitAdaptiveAnswer
   * Api submit adaptive answer
   */
  public readonly ApiSubmitAdaptiveAnswer = this.AugmentalBaseApiServer + '/api/AdaptiveLearning/Courses/{0}/Learners/{1}/UserAnswers';

  /**
   * @name ApiGetFeedbackAfterSubmittingAnswer
   * Api get feedback after submitting answer
   */
  public readonly ApiGetFeedbackAfterSubmittingAnswer = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/SubCourses/{1}/Learners/{2}/Questions/Feedback/Automated';

  /**
   * @name ApiGetQuestionExplanation
   * Api get question explanation
   */
  public readonly ApiGetQuestionExplanation = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/SubCourses/{1}/Learners/{2}/Questions/Clarification/Automated';

  /**
   * @name ApiCompletionProgress
   * Api completion progress
   */
  public readonly ApiCompletionProgress = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/CourseSessions/{1}/LinearLearning/CompletionProgress';

  /**
   * @name ApiUpdateSession
   * Api update session
   */
  public readonly ApiUpdateSession = this.AugmentalBaseApiServer + '/api/UserSessions/{0}';

  /**
   * @name ApiSignUpValidateAccountShortCode
   * Validate account short code
   */
  public readonly ApiSignUpValidateAccountShortCode = this.AugmentalBaseApiServer + '/api/Institution/ShortCode/IsRequired';

  /**
   * @name ApiSignUpValidationEmail
   * Validate email when sign up
   */
  public readonly ApiSignUpValidationEmail = this.AugmentalBaseApiServer + '/api/Emails/Institution/Id';

  /**
   * @name ApiVerifyEmailCode
   * Verify email code
   */
  public readonly ApiVerifyEmailCode = this.AugmentalIdentityApiServer + '/api/Users/Email/VerificationCode/IsValid';

  /**
   * @name ApiResendEmailVerificationCode
   * Resend verification code
   */
  public readonly ApiResendEmailVerificationCode = this.AugmentalIdentityApiServer + '/api/Users/Email/VerificationCode/Resend()';

  /**
   * @name ApiSignUpLearner
   * Sign up learner
   */
  public readonly ApiSignUpLearner = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Learners/SignUp()';

  /**
   * @name ApiGetCourseInfoByGeneratedId
   * Api to get course info by generated id
   */
  public readonly ApiGetCourseInfoByGeneratedId = this.AugmentalBaseApiServerBff + '/api/Classes/GeneratedId/{0}/Course/Info';

  /**
   * @name ApiGetCourseAvailableSeatsByClassId
   * Api to get course available seats by class id
   */
  public readonly ApiGetCourseAvailableSeatsByClassId = this.AugmentalBaseApiServer + '/api/Classes/{0}/AvailableSeats';

  /**
   * @name ApiGetListOfPublisherCourses
   * Api to get publisher course list
   */
  public readonly ApiGetListOfProviderCourses = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/ProviderInstitutionCourses?keyword={2}&isArchived={3}';

  /**
   * @name ApiGetLearnerCourses
   * Api to get courses enrolled in
   */
  public readonly ApiGetLearnerCourses = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses?keyword={2}&isArchived={3}';

  /**
   * @name ApiGetCourseByStripeSessionId
   * Api to get courses by stripe id
   */
  public readonly ApiGetCourseByStripeSessionId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses/Subscription/Success?session_id={2}';

  /**
   * @name ApiGetLessonSummaryGenerated
   * Api to get lesson summary generated
   */
  public readonly ApiGetLessonSummaryGenerated = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/SubCourses/{1}/Learners/{2}/Lesson/Summary/Automated';

  /**
   * @name ApiGetCourseCertificateInfo
   * Api to get course certificate info
   */
  public readonly ApiGetCourseCertificateInfo = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/Learners/{2}/Certificates';

  public readonly ApiGetCourseSummaryInfo = this.AugmentalBaseApiServerBff + '/api/Learners/{0}/Classes/{1}/Courses/{2}/Progress';
  public readonly ApiGetSubCoursesByCourse = this.AugmentalBaseApiServerBff + '/api/Learners/{0}/Courses/{1}/SubCourses/Progress?classId={2}';
  public readonly ApiGetSubCoursesBySubCourse = this.AugmentalBaseApiServerBff + '/api/Learners/{0}/SubCourses/{1}/SubCourses/Progress?classId={2}';
  public readonly ApiGetCourseSummaryTopicBySubCourseId = this.AugmentalBaseApiServerBff + '/api/CourseSummaries/Institutions/{0}/Courses/{1}/Learners/{2}/CourseSummary';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-----------------------------Report---------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * Get learner report course
   */
  public readonly ApiGetLearnerReportCourseDetailWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Learners/{2}/RootCourses/{3}/GenerateReport()?numberOfHighLowToReturn={4}&percentageThreshold={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get learner report course
   */
  public readonly ApiGetLearnerReportCourseDetailWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Learners/{2}/RootCourses/{3}/GenerateReport()?groupId={4}&numberOfHighLowToReturn={5}&percentageThreshold={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * Get learner report subcourse
   */
  public readonly GetReportSubcourseWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Learners/{2}/SubCourses/{3}/GenerateReport()?numberOfHighLowToReturn={4}&percentageThreshold={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get report last subcourse
   */
  public readonly GetReportSubcourseWithGroupId =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Learners/{2}/SubCourses/{3}/GenerateReport()?groupId={4}&numberOfHighLowToReturn={5}&percentageThreshold={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name ApiGetLearnerContentHistory
   * Api to get learner content history
   */
  public readonly ApiGetLearnerContentHistory = this.AugmentalBaseApiServerBff + '/api/Learners/{0}/UserSessions/{1}/ContentHistory';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-----------------------------Report---------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Assessment--------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetLearnerAssessment
   * Api to get learner assessment
   */
  public readonly ApiGetLearnerAssessment = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses/{2}/Assessments?isQuiz=true';

  /**
   * @name ApiGetLearnerAssessmentOverview
   * Api to get learner assessment overview
   */
  public readonly ApiGetLearnerAssessmentOverview = this.AugmentalBaseApiServer + '/api/Learners/{0}/Assessments/{1}/Overview';

  /**
   * @name ApiGetLearnerAssessmentOverview
   * Api to get learner assessment attempts
   */
  public readonly ApiGetLearnerAssessmentAttempt = this.AugmentalBaseApiServerBff + '/api/Assessments/{0}/Learners/{1}/Attempts';

  /**
   * @name ApiGetAssessmentExercises
   * Api to get exercises assessment
   */
  public readonly ApiGetAssessmentExercises = this.AugmentalBaseApiServerBff + '/api/Assessments/Sessions/{0}/Exercises/History';

  /**
   * @name ApiGetAssessmentExerciseQuestions
   * Api to get assessment exercise questions
   */
  public readonly ApiGetAssessmentExerciseQuestions = this.AugmentalBaseApiServerBff + '/api/Assessments/Sessions/{0}/Exercises/{1}/Questions/History';

  /**
   * @name ApiGetAssessmentExerciseStatistics
   * Api to get assessment exercise statistics
   */
  public readonly ApiGetAssessmentExerciseStatistics = this.AugmentalBaseApiServer + '/api/Learners/{0}/Assessments/{1}/Sessions/{2}/Exercises/{3}/History';

  /**
   * @name ApiGetAssessmentTableOfContents
   * Api to get assessment table of contents
   */
  public readonly ApiGetAssessmentTableOfContents = this.AugmentalBaseApiServer + '/api/Assessments/{0}/AssessmentSessions/{1}/TableOfContent';

  /**
   * @name ApiGetAssessmentTableOfContentsWithSelectedQuestion
   * Api to get assessment table of contents with selected question
   */
  public readonly ApiGetAssessmentTableOfContentsWithSelectedQuestion = this.AugmentalBaseApiServer + '/api/Assessments/{0}/AssessmentSessions/{1}/TableOfContent?selectedExerciseQuestionId={2}';

  /**
   * @name ApiCreateAssessmentSessionWithoutGroup
   * Api create assessment session without group
   */
  public readonly ApiCreateAssessmentSessionWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Exams/{2}/StartSession()?learnerId={3}';

  /**
   * @name ApiCreateAssessmentSessionWithGroup
   * Api create assessment session with group
   */
  public readonly ApiCreateAssessmentSessionWithGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Exams/{2}/StartSession()?learnerId={3}&groupId={4}';

  /**
   * @name ApiGetAssessmentNextContent
   * Api get assessment next content
   */
  public readonly ApiAssessmentNextContent = this.AugmentalBaseApiServer + '/api/Assessment/NextContent';

  /**
   * @name ApiSubmitAssessmentUserAnswer
   * Api submit assessment user answer
   */
  public readonly ApiSubmitAssessmentUserAnswer = this.AugmentalBaseApiServer + '/api/Assessment/Assessments/{0}/Learners/{1}/UserAnswers';

  /**
   * @name ApiEndAssessment
   * Api end assessment
   */
  public readonly ApiEndAssessmentSession = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Learners/{1}/Assessments/Sessions/{2}/EndSession()';

  /**
   * @name ApiGetAssessmentCompletionProgress
   * Api get assessment completion progress
   */
  public readonly ApiGetAssessmentCompletionProgress = this.AugmentalBaseApiServer + '/api/Assessments/{0}/AssessmentSessions/{1}/Assessment/CompletionProgress';

  /**
   * @name ApiGenerateCourse
   * Api to generate course
   */
  public readonly ApiGenerateCourse = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses/Generate()';

  /**
   * @name ApiGetGeneratedCourseStatusCode
   * Api to get generated course status
   */
  public readonly ApiGetGeneratedCourseStatusCode = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses/Generation/{2}/Status';

  /**
   * @name ApiGetGeneratedTopicSuggestions
   * Api to get generated topic suggestions
   */
  public readonly ApiGetGeneratedTopicSuggestions = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/Courses/Topics/Generate()';

  /**
   * @name ApiGetGeneratedTopicSuggestionsStatusCode
   * Api to get generated topic suggestions status
   */
  public readonly ApiGetGeneratedTopicSuggestionsStatusCode = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/Topics/Generation/{1}/Status';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Assessment--------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------------live session-------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetLiveSessions
   * api to get live session for certain learner
   */
  public readonly ApiGetLiveSessions = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/LiveSessions?inProgressRangeMinutes={2}&todayOnly={3}&upcomingOnly={4}&rangeOfDays={5}';

  /**
   * @name ApiJoinLiveSession
   * Api to join live session
   */
  public readonly ApiJoinLiveSession = this.AugmentalBaseApiServer + '/api/MeetingSessions/{0}/JoinMeetingLink?isModerator=false&isAttendee=true';

  /**
   * @name ApiGenerateLiveSessionShareLink
   * Api to generate live session share link
   */
  public readonly ApiGenerateLiveSessionShareLink = this.AugmentalBaseApiServer + '/api/Learners/{0}/MeetingSessions/{1}/SharedLink';

  /**
   * @name ApiGetRecordedSession
   * Api to get recorded session
   */
  public readonly ApiGetRecordedSession = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/LiveSessionRecordings';

  /**
   * @name ApiJoinLiveSessionAsAuthorizedUserLink
   * Api to join live session as authorized user link
   */
  public readonly ApiJoinLiveSessionAsAuthorizedUserLink = this.AugmentalBaseApiServer + '/api/MeetingSessions/MappedId/{0}/JoinAsAuthorizedUserLink';

  /**
   * @name ApiCheckIfAllowingGuestsToJoin
   * check if allow guest to join
   */
  public readonly ApiCheckIfAllowingGuestsToJoin = this.AugmentalBaseApiServer + '/api/MeetingSessions/{0}/IsAllowingGuestsToJoin';

  /**
   * @name ApiJoinLiveSessionAsGuestLink
   * Api join live session as guest
   */
  public readonly ApiJoinLiveSessionAsGuestLink = this.AugmentalBaseApiServer + '/api/MeetingSessions/MappedId/{0}/JoinAsGuestLink?guestName={1}';
  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------------live session-------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------Course enrollment-------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  public readonly ApiDeleteLearnerEnrollment = this.AugmentalBaseApiServer + '/api/Learners/{0}/Classes/{1}/Enrollments';
  public readonly ApiEnrollLearnerInClass = this.AugmentalBaseApiServer + '/api/Learners/{0}/Classes/{1}/Enrollments';
  public readonly ApiEnrollLearnerInCourse = this.AugmentalBaseApiServer + '/api/Learners/{0}/Courses/{1}/Enrollments';
  public readonly ApiSubscribeLearnerInCourse = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Classes/{1}/Courses/{2}/Users/{3}/Stripe/Checkout';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------Course enrollment-------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
}
