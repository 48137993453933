import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';
import { UserSessionModel } from '../../models/session/user-session.model';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesApplicationService {
    private langIds: any = [];

    constructor(
        private globalApiVariables: GlobalApiVariables,
        private http: HttpCall
    ) { }

    /* Get user preferences data*/
    public GetUserPreferences(institutionId: number, userId: number) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGetUserPreferences,
            institutionId,
            UserSessionModel.Instance.session.applicationId,
            userId
        );
        return data;
    }

    /* Create user preferences data*/
    public SaveUserPreferences(body: any, institutionId: number, userId: number) {
        var data = this.http.PostAsync(
            true,
            body,
            this.globalApiVariables.ApiPostUserPreferences,
            institutionId,
            UserSessionModel.Instance.session.applicationId,
            userId
        );
        return data;
    }

    /* Create user preferences data*/
    public UpdateUserPreferences(
        body: any,
        institutionId: number,
        userId: number
    ) {
        var data = this.http.PutAsync(
            true,
            body,
            this.globalApiVariables.ApiUpdateUserPreferences,
            institutionId,
            UserSessionModel.Instance.session.applicationId,
            userId
        );
        return data;
    }
}
