import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountApiVariables {
    public readonly AugmentalBaseApiServer = environment.baseApiServer;
    public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

    public readonly ApiGetAccountTypes = this.AugmentalBaseApiServer + '/api/EducationTypes';
    public readonly ApiValidateCustomDomainAvailability = this.AugmentalBaseApiServer + '/api/Institutions/Domain/IsAvailable()';
    public readonly ApiGetAccountBranding = this.AugmentalBaseApiServer + '/api/Institutions/Domain/Branding';
    public readonly ApiGetAccountBrandingByAccount = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Branding';
    public readonly ApiGetAccountSettings = this.AugmentalBaseApiServer + '/api/Institutions/Domain/Settings';
    public readonly ApiGetAccountSettingsById = this.AugmentalBaseApiServer + '/api/Institutions/{0}/InstitutionSettings';
    public readonly ApiRegisterMainAccount = this.AugmentalBaseApiServer + '/api/Users/{0}/Institutions/Setup()';
    public readonly ApiCheckIfAccountLimitReached = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Subscription/SubAccounts/Limit/Reached';
    public readonly ApiToActivateDeactivateAccount = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Activation?isActivating={1}&isDeactivating={2}';
    public readonly ApiGetAllAccounts = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Accounts';
    public readonly ApiGetAccountInsights = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Cap/Details';
    public readonly ApiGetAccountCap = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Cap';
    public readonly ApiUpdateAccountCap = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Cap';
    public readonly ApiValidateAccountCap = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Cap/Warnings';
    public readonly ApiGetAccountInformation = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Account?subInstitutionId={1}';
    public readonly ApiRequestCustomDomain = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Domain/Request()';
    public readonly ApiCreateAccount = this.AugmentalBaseApiServer + '/api/Institutions/{0}/SubInstitutions/Account';
    public readonly ApiUpdateAccount = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Account?subInstitutionId={1}';
    public readonly ApiCheckIfAccountHasWarnings = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Account/Warnings';
    public readonly ApiTestWhiteLabelEmailConfiguration = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Smtp/Configuration/Connection/Test';
    public readonly ApiGetWhiteLabelEmailConfiguration = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Smtp/Configuration';
    public readonly ApiSetupWhiteLabelEmailConfiguration = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Smtp/Configuration/Connection/Test';
    public readonly ApiUpdateWhiteLabelEmailConfiguration = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Smtp/Configuration';
}
