import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AccountType } from '../../../../../src/app/shared/helper/account/account.enum';
import { LoggerHelper } from '../../../../../src/app/shared/helper/logging/logger';
import { NavigationKeys } from '../../../../../src/app/shared/helper/navigation-keys';
import { SessionHelper } from '../../../../../src/app/shared/helper/session/session.helper';
import { UrlFormatterHelper } from '../../helper/url-formatter-helper';
import { InstitutionPreferenceResponseModel } from '../../models/institution/response/institution-preference-response.model';
import { UserPreferencesModel } from '../../models/session/user-preferences.model';
import { UserSessionModel } from '../../models/session/user-session.model';
import { HttpExceptionKeys } from './http-exception-keys';
@Injectable({
  providedIn: 'root',
})
export class HttpCall {
  constructor(private http: HttpClient, private _router: Router) {}

  TIME_OUT_VALUE = 240000; //240s

  /**
   * @name formatUrl
   * @param url
   * @param args
   * Format url function
   */
  formatUrl(url: string, args: any[]): string {
    if (args) {
      if (args.length > 0) {
        return UrlFormatterHelper.FormatString(url, args);
      } else {
        return url;
      }
    } else {
      return url;
    }
  }

  /**
   * getDomainNameFromUrl
   * @returns
   * Get the domain name from the url
   */
  getDomainNameFromUrl(): string {
    return location.hostname.replace('www.', '');
  }

  /**
   * @name getTimeZoneDifference
   * Get the time difference between UTC and local time function
   */
  private getTimeZoneDifference(): string {
    var d = new Date();
    var n = d.getTimezoneOffset(); //get the timezone diff in minutes
    var hours = Math.floor(n / 60); //convert minutes into hour
    var finalHour = -1 * hours; //inverse the sign of the resulted number

    //LoggerHelper.log('current date = ' + d);
    LoggerHelper.log('time diff = ' + finalHour.toString());
    return finalHour.toString();
  }

  /**
   * @name getLanguage
   * Get user language
   */
  private getLanguage(): string {
    if (UserPreferencesModel) {
      if (UserPreferencesModel.Instance.languageIsoCode) {
        return UserPreferencesModel.Instance.languageIsoCode;
      } else {
        return 'en';
      }
    }
  }

  /**
   * @name getAccountType
   * Get account type
   */
  private getAccountType(): string {
    if (InstitutionPreferenceResponseModel.Instance) {
      if (InstitutionPreferenceResponseModel.Instance.isK12Education) {
        return AccountType.School;
      } else {
        if (InstitutionPreferenceResponseModel.Instance.isHigherEducation) {
          return AccountType.HigherEducation;
        } else {
          if (InstitutionPreferenceResponseModel.Instance.isTrainingEducation) {
            return AccountType.Training;
          }
        }
      }
    }
    return AccountType.Training;
  }

  /**
   * @name getUserToken
   * Get user token
   */
  private getUserToken(): string {
    if (UserSessionModel) {
      if (UserSessionModel.Instance.session) {
        return UserSessionModel.Instance.session.token;
      } else {
        return '';
      }
    }
  }

  /**
   * @name AddHeaderToHttp
   * @param addAuthorization
   * @param contentTypeManuallySet - If true, set Content-Type header to application/json manually. If false, let the browser handle it (useful for multipart/form-data requests).
   * Add http header to the API calls function
   */
  AddHeaderToHttp(addAuthorization: boolean, contentTypeManuallySet: boolean = true): any {
    let headersConfig: any = {};

    headersConfig.Accept = 'application/json';
    headersConfig['Accept-Language'] = this.getLanguage();
    headersConfig.TimeZone = this.getTimeZoneDifference();
    headersConfig.DomainName = this.getDomainNameFromUrl();
    headersConfig.Account = this.getAccountType();
    headersConfig.IsWebApp = 'true';

    if (addAuthorization) {
      headersConfig.Authorization = 'Bearer ' + this.getUserToken();
    }

    // If contentTypeManuallySet is true, set the Content-Type to application/json
    if (contentTypeManuallySet) {
      headersConfig['Content-Type'] = 'application/json';
    }

    const options = {
      headers: new HttpHeaders(headersConfig),
      reportProgress: true,
    };

    return options;
  }

  /* GET async function */
  /**
   * @name GetAsync
   * @param addAuthentication
   * @param url
   * @param args
   * Get request
   */
  GetAsync(addAuthentication: boolean, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http get url to send ' + fullUrl);
    try {
      return this.http
        .get(fullUrl, this.AddHeaderToHttp(addAuthentication))
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {
      LoggerHelper.logError('http error ');
      LoggerHelper.logError(Exception);
    }
  }

  /**
   * @name PostAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Post request
   */
  PostAsync(addAuthentication: boolean, body: any, url: string, ...args: any[]): Observable<any> {
    // Note: This is a current fix since the function is used more than 100 time, and we need to add the contentType to the function signature
    // TODO: Refactor to use an object to pass parameters as it would a cleaner and more scalable approach
    // Like this: const { addAuthentication, body, url, contentType = 'application/json', args = [] } = params;

    // Default contentType to 'application/json' if not provided
    let contentType = 'application/json';
    let contentTypeManuallySet = true;

    // Check for contentType in args, which is expected to be a string
    const contentTypeIndex = args.findIndex((arg) => arg === 'application/json' || arg === 'multipart/form-data' || arg === 'application/x-www-form-urlencoded');
    if (contentTypeIndex !== -1) {
      contentType = args[contentTypeIndex]; // Extract the contentType, if we want to use it in the future
      args.splice(contentTypeIndex, 1); // Remove contentType from args
      contentTypeManuallySet = false;
    }

    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http post url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .post(fullUrl, body, this.AddHeaderToHttp(addAuthentication, contentTypeManuallySet))
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name PostFilesAsync
   * @param addAuthentication
   * @param formData
   * @param url
   * @param args
   * Post request
   */
  PostFilesAsync(addAuthentication: boolean, formData: FormData, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http post file url to send ' + fullUrl);

    if (addAuthentication) {
      var options = this.AddHeaderToHttp(addAuthentication, false);
      options.observe = 'events';
    } else {
      var options: any = {
        reportProgress: true,
        observe: 'events',
      };
    }

    try {
      return this.http
        .post(fullUrl, formData, options)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name PutAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Put request
   */
  PutAsync(addAuthentication: boolean, body: any, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http put url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .put(fullUrl, body, this.AddHeaderToHttp(addAuthentication))
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name PatchAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Patch request
   */
  PatchAsync(addAuthentication: boolean, body: any, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http patch url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .patch(fullUrl, body, this.AddHeaderToHttp(addAuthentication))
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name DeleteAsync
   * @param addAuthentication
   * @param url
   * @param args
   * Patch request
   */
  DeleteAsync(addAuthentication: boolean, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http delete url to send ' + fullUrl);

    try {
      return this.http
        .delete(fullUrl, this.AddHeaderToHttp(addAuthentication))
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name Request
   * @param method "POST" | "PUT" | "PATCH" | "DELETE" | "GET"
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Request function could send get,put,post,delete http request by choosing the methods.
   * The methods are :  "POST" | "PUT" | "PATCH" | "DELETE" | "GET"
   */
  Request(method: string, addAuthentication: boolean, body: any, url: string, ...args: any[]): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http request url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      var option = this.AddHeaderToHttp(addAuthentication);
      option['body'] = body;
      return this.http
        .request(method, fullUrl, option)
        .timeout(this.TIME_OUT_VALUE)
        .catch((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
        .map((response: any) => {
          LoggerHelper.log('http response ' + JSON.stringify(response));
          return response;
        });
    } catch (Exception) {}
  }

  /**
   * @name handleError
   * @param error
   * Handle the http errors
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      LoggerHelper.logError('An error occurred:' + JSON.stringify(error.error.message));
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      switch (error.status) {
        case 400:
          //throw new InvalidCastException();
          LoggerHelper.logError(HttpExceptionKeys.AlreadyExists);
          return throwError(HttpExceptionKeys.AlreadyExists);
        case 401:
          //UnAuthorized
          LoggerHelper.logError(HttpExceptionKeys.UnAuthorized);
          SessionHelper.clearSessionData();
          this._router.navigate(['/' + NavigationKeys.LoginPage]);
          return throwError(HttpExceptionKeys.UnAuthorized);
        case 403:
          //UnAuthorized
          LoggerHelper.logError(HttpExceptionKeys.NoAccess);
          SessionHelper.clearSessionData();
          this._router.navigate(['/' + NavigationKeys.LoginPage]);
          return throwError(HttpExceptionKeys.NoAccess);
        case 404:
          //throw new KeyNotFoundException();
          LoggerHelper.logError(HttpExceptionKeys.NotFound);
          return throwError(HttpExceptionKeys.NotFound);
        case 409:
          //Conflict
          LoggerHelper.logError(HttpExceptionKeys.Conflict);
          return throwError(HttpExceptionKeys.Conflict);
        case 412:
          //throw new InvalidOperationException();
          LoggerHelper.logError(HttpExceptionKeys.Conflict);
          return throwError(HttpExceptionKeys.Conflict);
        case 500:
          LoggerHelper.logError(HttpExceptionKeys.ServerError);
          return throwError(HttpExceptionKeys.ServerError);
        case 503:
          //ServiceUnavailable
          LoggerHelper.logError(HttpExceptionKeys.ServiceUnavailable);
          return throwError(HttpExceptionKeys.ServiceUnavailable);
        default:
          LoggerHelper.logError(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
          return throwError(error.error.message);
      }
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
