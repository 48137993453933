import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class OnboardingApplicationService {
    constructor(private globalApiVariables: GlobalApiVariables, private http: HttpCall) { }

    /**
     * @name GetOnboardingByUserId
     * @param userId
     * Get the onboarding preference for certain user
     */
    public GetOnboardingByUserId(userId: number) {
        var data = this.http.GetAsync(true, this.globalApiVariables.ApiGetOnBoardingByUserId, userId);
        return data;
    }

    /**
     * @name updateOnboarding
     * @param userId
     * update onboarding
     */
    public updateOnboarding(userId: number, body: any) {
        var data = this.http.PostAsync(true, body, this.globalApiVariables.ApiPostOnBoarding, userId);
        return data;
    }
}
