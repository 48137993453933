import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ThemeType } from '../../../../../../../src/app/shared/helper/theme/theme-type.enum';

@Component({
    selector: 'popup-header',
    templateUrl: './popup-header.component.html',
    styleUrls: ['popup-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupHeaderComponent {
    public predefinedTheme = ThemeType;

    @Input() theme: string;
    @Input() icon: string;
    @Input() title: string;
    @Input() description?: string;

    constructor(public dialogRef: MatDialogRef<PopupHeaderComponent>) { }
}
