import { GlobalApiLearnersVariables } from './../../http/http-call/global-variables-learner';
import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class LiveSessionApplicationService {
    constructor(
        private globalApiVariables: GlobalApiVariables,
        private learnerGlobalApiVariables: GlobalApiLearnersVariables,
        private http: HttpCall
    ) { }

    /**
     * @name GetLiveSessionsForCertainInstructor
     * @param institutionId
     * @param instructorId
     * @param minutesRange
     * Get live session related to a specific instructor
     */
    public GetLiveSessionsForCertainInstructor(
        institutionId: number,
        instructorId: number,
        minutesRange: number,
        todayOnly: boolean = false,
        upcomingOnly: boolean = false,
        rangeOfDays: number = 7
    ) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGetLiveSessions,
            institutionId,
            instructorId,
            minutesRange,
            todayOnly,
            upcomingOnly,
            rangeOfDays
        );
        return data;
    }

    /**
     * @name DeleteLiveSession
     * @param liveSessionId
     * @param deleteSelectedSessionOnlyWithoutChild
     * Delete live session
     */
    public DeleteLiveSession(liveSessionId: number, deleteSelectedSessionOnlyWithoutChild: boolean) {
        var data = this.http.DeleteAsync(
            true,
            this.globalApiVariables.ApiDeleteLiveSession,
            liveSessionId,
            deleteSelectedSessionOnlyWithoutChild
        );
        return data;
    }


    /**
     * @name EndLiveSession
     * @param liveSessionId
     * End live session
     */
    public EndLiveSession(liveSessionId: number) {
        var data = this.http.DeleteAsync(
            true,
            this.globalApiVariables.ApiEndLiveSession,
            liveSessionId,
        );
        return data;
    }

    /**
     * @name JoinLiveSession
     * @param liveSessionId
     * Join a live session
     */
    public JoinLiveSession(liveSessionId: number) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiJoinLiveSession,
            liveSessionId
        );
        return data;
    }

    /**
     * @name CreateLiveSession
     * @param institutionId
     * @param instructorId
     * @param body
     * Create a live session
     */
    public CreateLiveSession(
        institutionId: number,
        instructorId: number,
        body: any
    ) {
        var data = this.http.PostAsync(
            true,
            body,
            this.globalApiVariables.ApiCreateLiveSession,
            institutionId,
            instructorId
        );
        return data;
    }

    /**
     * @name GenerateLiveSessionShareLink
     * @param instructorId
     * @param liveSessionId
     * generate live session share link
     */
    public GenerateLiveSessionShareLink(
        instructorId: number,
        liveSessionId: number
    ) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGenerateLiveSessionShareLink,
            instructorId,
            liveSessionId
        );
        return data;
    }

    /**
     * @name UpdateLiveSession
     * @param institutionId
     * @param instructorId
     * @param liveSessionId
     * Update live session
     */
    public UpdateLiveSession(
        institutionId: number,
        instructorId: number,
        liveSessionId: number,
        updateCurrentAndUpcomingSchedulesToo: boolean,
        updateSingleScheduleOnly: boolean,
        body: any,
        rootSessionId?: number
    ) {
        var data = null;
        if (!rootSessionId || rootSessionId == null || rootSessionId == 0) {
            data = this.http.PutAsync(
                true,
                body,
                this.globalApiVariables.ApiUpdateLiveSession,
                institutionId,
                instructorId,
                liveSessionId,
                updateCurrentAndUpcomingSchedulesToo,
                updateSingleScheduleOnly
            );
        }
        else {
            data = this.http.PutAsync(
                true,
                body,
                this.globalApiVariables.ApiUpdateLiveSessionWithRootSessionId,
                institutionId,
                instructorId,
                liveSessionId,
                updateCurrentAndUpcomingSchedulesToo,
                updateSingleScheduleOnly,
                rootSessionId
            );
        }


        return data;
    }

    /**
     * @name GetLiveSessionById
     * @param liveSessionId
     * Get live session by id
     */
    public GetLiveSessionById(liveSessionId: number, updateSingleSession: boolean, rootSessionId?: number) {
        var data = null;
        if (!rootSessionId || rootSessionId == null || rootSessionId == 0) {
            data = this.http.GetAsync(
                true,
                this.globalApiVariables.ApiGetLiveSessionById,
                liveSessionId,
                updateSingleSession
            );
        }
        else if (rootSessionId > 0) {
            data = this.http.GetAsync(
                true,
                this.globalApiVariables.ApiGetLiveSessionByIdWithRootSession,
                liveSessionId,
                updateSingleSession,
                rootSessionId
            );
        }
        return data;
    }

    /**
     * @name CheckIfMeetingSessionEnrollmentIsAvailable
     * @param moderatorId
     * @param scheduleDateTime
     * @param body
     * @param meetingSessionId
     * @param duration
     * Check if meeting session enrollment is available
     */
    public CheckIfMeetingSessionEnrollmentIsAvailable(
        institutionId: number,
        moderatorId: number,
        scheduleDateTime: string,
        body: any,
        meetingSessionId?: number,
        duration?: number
    ) {
        var data = null;
        if (
            (meetingSessionId == null || meetingSessionId == 0) &&
            (duration == null || duration == 0)
        ) {
            data = this.http.PostAsync(
                true,
                body,
                this.globalApiVariables
                    .ApiCheckIfMeetingSessionEnrollmentIsAvailableWithoutSessionIdAndDuration,
                institutionId,
                moderatorId,
                scheduleDateTime
            );
        } else if (
            meetingSessionId != null &&
            meetingSessionId > 0 &&
            (duration == null || duration == 0)
        ) {
            data = this.http.PostAsync(
                true,
                body,
                this.globalApiVariables
                    .ApiCheckIfMeetingSessionEnrollmentIsAvailableWithSessionIdAndWithoutDuration,
                institutionId,
                moderatorId,
                scheduleDateTime,
                meetingSessionId
            );
        } else if (
            (meetingSessionId == null || meetingSessionId == 0) &&
            duration != null &&
            duration > 0
        ) {
            data = this.http.PostAsync(
                true,
                body,
                this.globalApiVariables
                    .ApiCheckIfMeetingSessionEnrollmentIsAvailableWithoutSessionIdAndWithDuration,
                institutionId,
                moderatorId,
                scheduleDateTime,
                duration
            );
        } else if (
            meetingSessionId != null &&
            meetingSessionId > 0 &&
            duration != null &&
            duration > 0
        ) {
            data = this.http.PostAsync(
                true,
                body,
                this.globalApiVariables
                    .ApiCheckIfMeetingSessionEnrollmentIsAvailableWithSessionIdAndWithDuration,
                institutionId,
                moderatorId,
                scheduleDateTime,
                meetingSessionId,
                duration
            );
        }
        return data;
    }

    /**
     * @name GetRecordedSession
     * @param institutionId
     * @param instructorId
     * Get recorded session for a specific instructor
     */
    public GetRecordedSession(institutionId: number, instructorId: number) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGetRecordedSession,
            institutionId,
            instructorId
        );
        return data;
    }

    /**
     * @name ManageRecordedSessionStatus
     * @param instructorId
     * @param meetingSessionRecordingId
     * @param isPublishingRecording
     * Publish/Unpublish recorded session
     */
    public ManageRecordedSessionStatus(
        instructorId: number,
        meetingSessionRecordingId: number,
        isPublishingRecording: boolean
    ) {
        var data = this.http.PutAsync(
            true,
            null,
            this.globalApiVariables.ApiPublishRecordedSession,
            instructorId,
            meetingSessionRecordingId,
            isPublishingRecording
        );
        return data;
    }

    /**
     * @name DeleteRecordedSession
     * @param meetingSessionRecordingId
     * Delete recorded session
     */
    public DeleteRecordedSession(meetingSessionRecordingId: number) {
        var data = this.http.DeleteAsync(
            true,
            this.globalApiVariables.ApiDeleteRecordedSession,
            meetingSessionRecordingId
        );
        return data;
    }


    /**
     * @name GetRecurrentSessions
     * @param liveSessionId
     * @param inProgressRangeMinutes
     * Get recurrent sessions by root session 
     */
    public GetRecurrentSessions(liveSessionId: number, inProgressRangeMinutes: number) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGetRecurrentSession,
            liveSessionId,
            inProgressRangeMinutes
        );
        return data;
    }

    /**
     * @name CheckRecurrentLiveSessionAvailability
     * @param institutionId
     * @param instructorId
     * @param body
     * @param liveSessionId
     * Check recurrent session availability
     */
    public CheckRecurrentLiveSessionAvailability(institutionId: number, instructorId: number, body: any, liveSessionId?: number, rootSessionId?: number) {
        var data = null;
        if (liveSessionId == undefined || liveSessionId == null || liveSessionId == 0) {
            if (rootSessionId == undefined || rootSessionId == null || rootSessionId == 0) {
                data = this.http.PostAsync(
                    true,
                    body,
                    this.globalApiVariables.ApiCheckRecurrentLiveSessionAvailabilityOnCreate,
                    institutionId,
                    instructorId
                );
            }
            else {
                data = this.http.PostAsync(
                    true,
                    body,
                    this.globalApiVariables.ApiCheckRecurrentLiveSessionAvailabilityOnCreateWithRootSessionId,
                    institutionId,
                    instructorId,
                    rootSessionId
                );
            }

        }
        else {

            if (rootSessionId == undefined || rootSessionId == null || rootSessionId == 0) {
                data = this.http.PostAsync(
                    true,
                    body,
                    this.globalApiVariables.ApiCheckRecurrentLiveSessionAvailabilityOnUpdate,
                    institutionId,
                    instructorId,
                    liveSessionId
                );
            }
            else {
                data = this.http.PostAsync(
                    true,
                    body,
                    this.globalApiVariables.ApiCheckRecurrentLiveSessionAvailabilityOnUpdateWithRootSessionId,
                    institutionId,
                    instructorId,
                    liveSessionId,
                    rootSessionId
                );
            }

        }

        return data;
    }





    /**
     * @name GetLearnerLiveSessions
     * @param institutionId
     * @param learnerId
     * @param minutesRange
     * Get live session related to a specific learner
     */
    public GetLearnerLiveSessions(
        institutionId: number,
        learnerId: number,
        minutesRange: number,
        todayOnly: boolean = false,
        upcomingOnly: boolean = false,
        rangeOfDays: number = 7
    ) {
        var data = this.http.GetAsync(
            true,
            this.learnerGlobalApiVariables.ApiGetLiveSessions,
            institutionId,
            learnerId,
            minutesRange,
            todayOnly,
            upcomingOnly,
            rangeOfDays
        );
        return data;
    }




    /**
     * @name JoinLiveSession
     * @param liveSessionId
     * Join a live session
     */
    public JoinLearnerLiveSession(liveSessionId: number) {
        var data = this.http.GetAsync(
            true,
            this.learnerGlobalApiVariables.ApiJoinLiveSession,
            liveSessionId
        );
        return data;
    }



    /**
     * @name GenerateLiveSessionShareLink
     * @param learnerId
     * @param liveSessionId
     * generate live session share link
     */
    public GenerateLearnerLiveSessionShareLink(
        learnerId: number,
        liveSessionId: number
    ) {
        var data = this.http.GetAsync(
            true,
            this.learnerGlobalApiVariables.ApiGenerateLiveSessionShareLink,
            learnerId,
            liveSessionId
        );
        return data;
    }


    /**
     * @name GetRecordedSession
     * @param institutionId 
     * @param learnerId 
     * Get recorded session 
     */
    public GetLearnerRecordedSession(institutionId: number, learnerId: number) {
        var data = this.http.GetAsync(
            true,
            this.learnerGlobalApiVariables.ApiGetRecordedSession,
            institutionId,
            learnerId
        );
        return data;
    }



    /**
     * @name JoinLiveSession
     * @param meetingId
     * Join live session from authorized user link
     */
    public JoinLiveSessionAsAuthorizedUserLink(meetingId: string) {
        var data = this.http.GetAsync(
            true,
            this.learnerGlobalApiVariables.ApiJoinLiveSessionAsAuthorizedUserLink,
            meetingId
        );
        return data;
    }

    /**
     * @name CheckIfAllowingGuestsToJoin
     * @param meetingId
     * check if allowing guests to join in this meeting
     */
    public CheckIfAllowingGuestsToJoin(meetingId: string) {
        var data = this.http.GetAsync(
            false,
            this.learnerGlobalApiVariables.ApiCheckIfAllowingGuestsToJoin,
            meetingId
        );
        return data;
    }

    /**
     * @name JoinLiveSessionAsGuestLink
     * @param meetingId
     * @param guestName
     * Join live session as guest
     */
    public JoinLiveSessionAsGuestLink(meetingId: string, guestName: string, passcode: string) {
        var body: any = {
            passcode: passcode
        }
        var data = this.http.PostAsync(
            false,
            JSON.stringify(body),
            this.learnerGlobalApiVariables.ApiJoinLiveSessionAsGuestLink,
            meetingId,
            guestName
        );
        return data;
    }



}
