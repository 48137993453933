import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpCall } from '../../../../src/app/core/http/http-call/http-call';
import { UserSessionModel } from '../../../../src/app/core/models/session/user-session.model';
import { UserApiVariables } from '../../core/http/http-call/apis/shared/user/user.api';
import { GlobalApiVariables } from '../../core/http/http-call/global-variables';
import { GlobalApiLearnersVariables } from './../../core/http/http-call/global-variables-learner';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private learnerGlobalApiVariables: GlobalApiLearnersVariables, private globalApiVariables: GlobalApiVariables, private userApiVariables: UserApiVariables, private http: HttpCall) {}

  /**
   * @name LogoutSession
   * Delete user session and log out
   */
  public LogoutSession() {
    var data = this.http.DeleteAsync(true, this.globalApiVariables.ApiLogout, UserSessionModel.Instance.session.id);
    return data;
  }

  /**
   * @name signUpValidateEmail
   * @param body
   * @returns data
   * sign up validation email
   */
  public signUpValidateEmail(body: any) {
    var data = this.http.PostAsync(false, body, this.learnerGlobalApiVariables.ApiSignUpValidationEmail);

    return data;
  }

  /**
   * @name verifyEmailCode
   * @param body
   * @returns data
   * verify email code
   */
  public verifyEmailCode(body: any) {
    var data = this.http.PostAsync(false, body, this.learnerGlobalApiVariables.ApiVerifyEmailCode);

    return data;
  }

  /**
   * @name resendEmailVerificationCode
   * @param body
   * @returns data
   * resend email verification code
   */
  public resendEmailVerificationCode(body: any) {
    var data = this.http.PostAsync(false, body, this.learnerGlobalApiVariables.ApiResendEmailVerificationCode);

    return data;
  }

  /**
   * @name signUpLearner
   * @param institutionId
   * @param body
   * @returns data
   * sign up learner
   */
  public signUpLearner(institutionId: number, body: any) {
    var data = this.http.PostAsync(false, body, this.learnerGlobalApiVariables.ApiSignUpLearner, institutionId);

    return data;
  }

  /**
   * @name registerAdminUser
   * @param body
   * @returns data
   * Register admin user
   */
  public registerAdminUser(body: any) {
    var data = this.http.PostAsync(false, body, this.userApiVariables.ApiRegisterAdminUser);
    return data;
  }

  /**
   * @name getInstitutionValidationShortCode
   * Checks whether we need to allow the user to input account shortcode or not on sign up
   */
  public getInstitutionValidationShortCode() {
    var data = this.http.GetAsync(false, this.learnerGlobalApiVariables.ApiSignUpValidateAccountShortCode);

    return data;
  }

  /**
   * @name getSaltFromServer
   * @param body
   * @returns
   * Get salt from server based on username
   */
  public getSaltFromServer(body: string) {
    var data = this.http.PostAsync(false, body, this.globalApiVariables.GetSaltFromServer);
    return data;
  }

  /**
   * @name getUserStatus
   * @param body
   * @returns
   * Get user status
   */
  public getUserStatus(body: string) {
    var data = this.http.PostAsync(false, body, this.userApiVariables.ApiGetUserStatus);
    return data;
  }

  /**
   * @name authenticateUser
   * @param body
   * @returns
   * Authenticate the user
   */
  public authenticateUser(body: string) {
    var data = this.http.PostAsync(false, body, this.globalApiVariables.AuthenticateUser);
    return data;
  }

  /**
   * @name authenticateUserSilentlyUsingCode
   * @param body
   * @returns
   * Authenticate the user silently using the code
   */
  public authenticateUserSilentlyUsingCode(authenticationCode: string) {
    var data = this.http.GetAsync(false, this.globalApiVariables.AuthenticateUserSilently, authenticationCode);
    return data;
  }
}
