import { SerializationHelper } from '../../../../../../../src/app/core/helper/serialization-helper';
import { CachingHelper } from '../../../../../../../src/app/shared/helper/caching/caching';
import { CachingKeys } from '../../../../../../../src/app/shared/helper/caching/caching-keys';

export class BrandingResponseModel {
    learningSpaceName: string;
    tagline: string;
    primaryColor: string;
    lightColor: string;
    darkColor: string;
    secondaryColor: string;
    hasSecondaryLogo: boolean;
    favIcon: string;

    logoOnColor: string;
    logoOnWhite: string;
    logoSecondaryOnWhite: string;
    logoSecondaryOnColor: string;

    website: string;
    termsLink: string;
    privacyLink: string;

    //for local usage
    isBrandingHasBeenLoadedBasedOnAccountId: boolean;

    private static _instance: BrandingResponseModel;

    public constructor() { }

    /**
     * @name Instance
     */
    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new BrandingResponseModel(), CachingHelper.getItemFromCache(CachingKeys.Branding));
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.Branding);
    }

    /**
     * @name updateInstance
     * @param data
     * Update the instance
     */
    public static updateInstance(data: BrandingResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.Branding, JSON.stringify(data));
    }
}
