import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../../src/app/config/services/translation.service';
import { SharedService } from '../../../_services/SharedService';

@Component({
    selector: 'bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet-style.scss'],
})
export class BottomSheetComponent {
    //---------- translation -----------------
    translationCancel: string = '';
    //---------- translation -----------------

    constructor(
        public translate: TranslateService,
        public translationService: TranslationService,
        private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private sharedService: SharedService
    ) {
        this.LabelUpdate();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.LabelUpdate();
        });
        _bottomSheetRef.disableClose = false;
    }

    /**
     * Update the UI labels based on the translations
     */
    LabelUpdate() {
        this.translationCancel = this.translationService.GetTranslatedValue('GENERAL.CANCEL');
    }

    /**
     * @name OnItemTap
     * @param event
     * @param contentType
     * Detect when an item was tapped
     */
    OnItemTap(event: MouseEvent, contentType: string) {
        this._bottomSheetRef.dismiss(contentType);
        event.preventDefault();
    }

    /**
     * @name CloseBottomSheet
     * Close the bottom sheet on close button click event
     */
    CloseBottomSheet() {
        this._bottomSheetRef.dismiss();
    }
}
