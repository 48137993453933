import { UserRolePermissionResponseModel } from '../../../../../src/app/core/models/roles/user-role-permission-response.model';
import { RoleType } from './role.enum';

export class RoleHelper {
    /**
     * @name GetRole
     * @returns role of the user
     */
    public static getUserRole(): string[] {
        var role: string[] = [];
        var userPermission = UserRolePermissionResponseModel.Instance;
        if (userPermission && userPermission.roles) {
            userPermission.roles.forEach((element) => {
                if (element.role.toLowerCase() == RoleType.Instructor.toLowerCase()) {
                    role.push(RoleType.Instructor);
                } else {
                    if (element.role.toLowerCase() == RoleType.Learner.toLowerCase()) {
                        role.push(RoleType.Learner);
                    } else {
                        if (element.role.toLowerCase() == RoleType.Administrator.toLowerCase()) {
                            role.push(RoleType.Administrator);
                        }
                    }
                }
            });
        }
        return role;
    }
}
