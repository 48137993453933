import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import { NavigationKeys } from './shared/helper/navigation-keys';

const routes: Routes = [
    {
        path: '',
        loadChildren: './theme/theme.module#ThemeModule',
    },
    { path: NavigationKeys.LogoutPage, component: LogoutComponent },
    // { path: '', redirectTo: 'index', pathMatch: 'full' },
    // { path: 'builder', component: BuilderComponent },
    {
        path: NavigationKeys.LiveBoardSamplePage,
        loadChildren: './theme/pages/samples/live-board/live-board-sample.module#LiveBoardSampleModule',
    },
    {
        path: NavigationKeys.ReportSamplePage,
        loadChildren: './theme/pages/samples/report/report-sample.module#ReportSampleModule',
    },
    {
        path: NavigationKeys.RoutingPage,
        loadChildren: './theme/pages/route/setup-workflow-routing.module#RoutingWorkflowSetupModule',
    },
    {
        path: 'page-not-found',
        loadChildren: './theme/pages/not-found/not-found.module#NotFoundModule',
    },
    {
        path: '**',
        redirectTo: 'page-not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
