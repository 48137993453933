import { GenericSelectionCardModel } from "../../models/generic-selection-card-model";

export class DialogSelectionData {
    theme?: string;
    icon?: string;
    title?: string;
    description?: string;
    negativeButtonText?: string;
    positiveButtonText?: string;
    lookupArray: GenericSelectionCardModel[];
    isMultipleSelection: boolean;
  }