import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { TranslationService } from '../../../../../src/app/config/services/translation.service';
import { UserInstitutionPreferenceResponseModel } from '../../../../../src/app/core/models/institution/response/user-institution-preference-response.model';
import { UserRolePermissionResponseModel } from '../../../../../src/app/core/models/roles/user-role-permission-response.model';
import { UserRoleModel } from '../../../../../src/app/core/models/roles/user-role.model';
import { BrandingResponseModel } from '../../../../../src/app/core/models/shared/branding/response/branding.response.model';
import { AnalyticsViewModel } from '../../../../../src/app/shared/helper/analytics/analytics.viewmodel';
import { BrandingHelper } from '../../../../../src/app/shared/helper/branding-helper';
import { IntercomService } from '../../../../../src/app/shared/helper/intercom/intercom-service';
import { NavigationKeys } from '../../../../../src/app/shared/helper/navigation-keys';
import { CategoryType } from '../../../../../src/app/shared/helper/role/category.enum';
import { RoleHelper } from '../../../../../src/app/shared/helper/role/role-helper';
import { InstitutionViewModel } from '../../../../../src/app/shared/viewmodel/institution/institutionpicker.viewmodel';
import { UserRolesViewModel } from '../../../../../src/app/shared/viewmodel/roles/user-roles.viewmodel';
import { UserPreferencesViewModel } from '../../../../../src/app/shared/viewmodel/session/user-preferences.viewmodel';
import { SharedService } from '../../../../../src/app/_services/SharedService';
import { InstitutionPreferenceResponseModel } from '../../../core/models/institution/response/institution-preference-response.model';
import { InstitutionSettingResponseModel } from '../../../core/models/institution/response/institution-settings.response.model';
import { UserPreferencesModel } from '../../../core/models/session/user-preferences.model';
import { UserSessionModel } from '../../../core/models/session/user-session.model';
import { Helpers } from '../../../helpers';
import { AnalyticsService } from '../../../shared/helper/analytics/analytics.service';
import { LoggerHelper } from '../../../shared/helper/logging/logger';
import { SubscriptionViewModel } from '../../../shared/viewmodel/shared/subscription/subscription.viewmodel';
import { OnBoardingPreferences } from './../../../core/models/onboarding/onboarding-preferences.model';
import { NavigationType } from './../../../shared/helper/navigation/navigation.enum';
import { RoleType } from './../../../shared/helper/role/role.enum';
import { OnboardingViewModel } from './../../../shared/viewmodel/teacher/onboarding/onboarding.viewmodel';

@Component({
    selector: 'rout-page',
    templateUrl: './setup-workflow-routing.component.html',
    styleUrls: [`setup-workflow-routing-style.scss`],
    encapsulation: ViewEncapsulation.None,
})
export class RoutingWorkflowSetupComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    private userInstitutionPreferenceResponseModel: UserInstitutionPreferenceResponseModel;

    ngOnDestroy() {
        LoggerHelper.log('RoutingWorkflowSetupComponent destroy');
        Helpers.setLoading(false);
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
    ngOnInit() {
        LoggerHelper.log('RoutingWorkflowSetupComponent init');
        this.GetIdFromUrl();
        this.NavigationNextAction();
        Helpers.setLoading(true);
    }

    returnUrl: string;

    constructor(
        private _router: Router,
        public translationService: TranslationService,
        private institutionViewModel: InstitutionViewModel,
        private userPreferencesViewModel: UserPreferencesViewModel,
        private userRoleViewModel: UserRolesViewModel,
        private onboardingViewModel: OnboardingViewModel,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService,
        private analyticsService: AnalyticsService,
        private analyticsViewModel: AnalyticsViewModel,
        private brandingHelper: BrandingHelper,
        public subscriptionViewModel: SubscriptionViewModel,
        public intercomService: IntercomService
    ) {
        LoggerHelper.log('Inside the RoutingWorkflowSetupComponent page');
    }

    /**
     * @name GetIdFromUrl
     * Get id from url
     */
    GetIdFromUrl() {
        this.activatedRoute.queryParams.takeUntil(this.destroyed$).subscribe((params) => {
            this.returnUrl = params['returnUrl'];
            LoggerHelper.log('returnUrl = ' + this.returnUrl);
        });
    }

    /**
     * @name NavigationNextAction
     * Navigate to the next pages
     */
    private async NavigationNextAction() {
        if (this.IsUserSessionAvailable() == true) {
            if ((await this.HasChosenInstitution()) == true) {
                //load the user permissions
                await this.userRoleViewModel.getUserPermission(InstitutionPreferenceResponseModel.Instance.institutionId, UserSessionModel.Instance.user.id);

                //load the subscription
                if (await this.subscriptionViewModel.validateIfUserHasActiveSubscription(true)) {
                    LoggerHelper.log('SetupWorkFlowRouting: Subscription is valid');

                    if (this.userRoleViewModel.isAccessToAllFeaturesDisabled()) {
                        LoggerHelper.log('SetupWorkFlowRouting: Access to all features disabled');
                        //Take the user to no access page
                        this._router.navigate([NavigationKeys.SubscriptionPage + '/' + NavigationKeys.SubscriptionInvalidAccessPage]);
                        return;
                    } else {
                        if (this.userRoleViewModel.isAccessToAllFeaturesRequireUpgrade()) {
                            LoggerHelper.log('SetupWorkFlowRouting: Access to all features require upgrade');
                            //Take the user to the subscription error page
                            this._router.navigate([NavigationKeys.SubscriptionPage + '/' + NavigationKeys.SubscriptionErrorPage]);
                            return;
                        }
                    }
                } else {
                    // Subscription is not valid, redirect the user with the return url
                    LoggerHelper.log('SetupWorkFlowRouting:Subscription is not valid');

                    //Take the user to the appropriate subscription page
                    this._router.navigateByUrl(this.subscriptionViewModel.forceNavigateUserToSubscriptionPage());
                    return;
                }

                await this.LoadUserPreferences(InstitutionPreferenceResponseModel.Instance.institutionId);
                if (this.HasChosenLanguage() == true) {
                    //Initialize the branding based on account id
                    if (!BrandingResponseModel.Instance || (BrandingResponseModel.Instance && !BrandingResponseModel.Instance.isBrandingHasBeenLoadedBasedOnAccountId)) {
                        BrandingResponseModel.clearFullInstance();
                        await this.brandingHelper.loadBranding();
                        BrandingResponseModel.Instance.isBrandingHasBeenLoadedBasedOnAccountId = true;
                    }

                    //Initialize the analytics service
                    this.setupAnalytics();
                    this.intercomService.initializeIntercom();

                    await this.GetOnboardingByUserId(UserSessionModel.Instance.user.id);
                    var role: string[] = RoleHelper.getUserRole();

                    if (this.returnUrl && this.returnUrl.length > 0) {
                        this._router.navigateByUrl(this.returnUrl);
                    } else {
                        if (role.find((element) => element == RoleType.Administrator)) {
                            this.navigateToFirstAvailableModuleAdministrator();
                        } else {
                            if (role.find((element) => element == RoleType.Instructor)) {
                                this.navigateToFirstAvailableModuleInstrcutor();
                            } else {
                                if (role.find((element) => element == RoleType.Learner)) {
                                    this.navigateToFirstAvailableModuleLearner();
                                }
                            }
                        }
                    }
                } else {
                    //go to PickLanguage
                    //TODO Change url
                    LoggerHelper.log('SetupWorkFlowRouting:PickLanguage');
                    this._router.navigate(['/' + NavigationKeys.LanguageSetupPage]);
                }
            } else {
                //go to PickInstitution + fetch UserPreferences inside the class
                LoggerHelper.log('SetupWorkFlowRouting:PickInstitution');
                //TODO change url
                this._router.navigate(['/' + NavigationKeys.InstitutionSetupPage]);
            }
        } else {
            //go to Login
            LoggerHelper.log('SetupWorkFlowRouting:Login');
            this._router.navigate([NavigationKeys.LoginPage]);
        }
    }

    /**
     * @name setupAnalytics
     * Setup the analytics
     */
    private setupAnalytics() {
        //Register user
        this.analyticsService.registerUser(UserSessionModel.Instance.user.code);

        //Register custom user property for Segment
        // var analyticsUserModel: AnalyticsUserModel = this.analyticsViewModel.getSegmentUserProfileUpdateTracking(UserSessionModel.Instance);
        // this.analyticsService.setSegmentUserProperty(UserSessionModel.Instance.user.code, analyticsUserModel);

        //Register custom user property
        // var analyticsAccountModel: AnalyticsAccountModel = this.analyticsViewModel.getAccountProfile(InstitutionPreferenceResponseModel.Instance);
        // this.analyticsService.updateCompanyProperty(InstitutionPreferenceResponseModel.Instance.institutionCode, analyticsAccountModel, this.analyticsViewModel.getTrackEventExtras());

        // if (SubscriptionResponseModel.Instance.hasExpired) {
        //   // On subscription or trial ended
        //   var analyticsAccountSubscriptionModel: AnalyticsAccountTrialModel = this.analyticsViewModel.getActiveSubscription(SubscriptionResponseModel.Instance);
        //   this.analyticsService.logEventWithValue(AnalyticsProperties.EventSubscriptionEnded, analyticsAccountSubscriptionModel, this.analyticsViewModel.getTrackEventExtras());
        // } else {
        //   if (SubscriptionResponseModel.Instance.isFreeTrial) {
        //     // During free trial
        //     var analyticsAccountSubscriptionModel: AnalyticsAccountTrialModel = this.analyticsViewModel.getActiveSubscription(SubscriptionResponseModel.Instance);
        //     this.analyticsService.logEventWithValue(AnalyticsProperties.EventSubscriptionTrialStarted, analyticsAccountSubscriptionModel, this.analyticsViewModel.getTrackEventExtras());
        //   } else {
        //     if (SubscriptionResponseModel.Instance.hasActiveSubscription) {
        //       // On subscription active
        //       var analyticsAccountSubscriptionModel: AnalyticsAccountTrialModel = this.analyticsViewModel.getActiveSubscription(SubscriptionResponseModel.Instance);
        //       this.analyticsService.logEventWithValue(AnalyticsProperties.EventSubscriptionActive, analyticsAccountSubscriptionModel, this.analyticsViewModel.getTrackEventExtras());
        //     }
        //   }
        // }
    }

    /**
     * @name isBrandingExists
     * Check branding
     * @returns
     */
    // private async isBrandingExists(): Promise<boolean> {
    //   var branding = await this.brandingHelper.loadBranding();
    //   if (branding) {
    //     return true;
    //   }
    //   return false;
    // }

    /**
     * @name IsUserSessionAvailable
     * Checks if user session is available in cache
     */
    private IsUserSessionAvailable(): boolean {
        UserSessionModel.clearInstance();
        try {
            if (UserSessionModel.Instance) {
                LoggerHelper.logInfo(UserSessionModel.Instance);
                if (UserSessionModel.Instance.user.id) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        } catch (Exception) {
            return false;
        }
    }

    /**
     * @name HasTakenOnBoarding
     * Check if user has taken onboarding
     */
    private HasTakenOnBoarding(): boolean {
        try {
            if (UserPreferencesModel.Instance) {
                LoggerHelper.log('HasTakenOnBoarding : ' + UserPreferencesModel.Instance.hasTakenOnBoarding);
                return UserPreferencesModel.Instance.hasTakenOnBoarding;
            }
            return false;
        } catch (Exception) {
            return false;
        }
    }

    /**
     * @name HasChosenInstitution
     * Check if user has chosen institution
     */
    private async HasChosenInstitution(): Promise<boolean> {
        try {
            await this.GetAllInstitution(UserSessionModel.Instance.user.id, this.institutionViewModel.GetUserInstitutionPreferenceRequestModel(0));
            if (this.userInstitutionPreferenceResponseModel.institutionPreferenceId > 0) {
                var tmpuserInstitutionPreferenceResponseModel = this.userInstitutionPreferenceResponseModel.availableInstitutionsPreferences.find(
                    (item) => item.id == this.userInstitutionPreferenceResponseModel.institutionPreferenceId
                );
                InstitutionPreferenceResponseModel.ClearInstance();
                InstitutionPreferenceResponseModel.UpdateInstance(tmpuserInstitutionPreferenceResponseModel);
                InstitutionPreferenceResponseModel.ClearInstance();

                //Load the settings
                await this.getInstitutionSettings(InstitutionPreferenceResponseModel.Instance.institutionId);

                return true;
            } else {
                if (this.userInstitutionPreferenceResponseModel.availableInstitutionsPreferences.length > 0) {
                    if (this.userInstitutionPreferenceResponseModel.availableInstitutionsPreferences.length == 1) {
                        await this.UpdateUserInstitutionPreferences(
                            this.institutionViewModel.GetUserInstitutionPreferenceRequestModel(this.userInstitutionPreferenceResponseModel.availableInstitutionsPreferences[0].id)
                        );
                        InstitutionPreferenceResponseModel.ClearInstance();
                        InstitutionPreferenceResponseModel.UpdateInstance(this.userInstitutionPreferenceResponseModel.availableInstitutionsPreferences[0]);
                        InstitutionPreferenceResponseModel.ClearInstance();

                        //Load the settings
                        await this.getInstitutionSettings(InstitutionPreferenceResponseModel.Instance.institutionId);

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    InstitutionPreferenceResponseModel.ClearInstance();
                    InstitutionPreferenceResponseModel.UpdateInstance(new InstitutionPreferenceResponseModel());
                    InstitutionPreferenceResponseModel.ClearInstance();
                    return true;
                }
            }
        } catch (Exception) {
            InstitutionPreferenceResponseModel.ClearInstance();
            InstitutionPreferenceResponseModel.UpdateInstance(new InstitutionPreferenceResponseModel());
            InstitutionPreferenceResponseModel.ClearInstance();
            return true;
        }
    }

    /**
     * @name HasChosenLanguage
     * Check if user has chosen language
     */
    private HasChosenLanguage(): boolean {
        try {
            if (UserPreferencesModel.Instance) {
                if (UserPreferencesModel.Instance.languageId) {
                    LoggerHelper.log('HasChosenLanguage : ' + UserPreferencesModel.Instance.languageId);
                    this.translationService.setLanguageBasedOnAccountType(UserPreferencesModel.Instance);
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        } catch (Exception) {
            return false;
        }
    }

    /**
     * @name navigateToFirstAvailableModuleAdministrator
     * Validates if a user has access to modules in order to navigate them
     */
    private navigateToFirstAvailableModuleAdministrator() {
        LoggerHelper.log('Inside navigateToFirstAvailableModuleAdministrator');
        var role: UserRoleModel = UserRolePermissionResponseModel.Instance.roles.find((role) => role.role.toLowerCase() == RoleType.Administrator.toLowerCase());

        var category = role.categories.find((category) => category.category.toLowerCase() == CategoryType.Dashboard);
        if (category && category.hasAccess && !category.requireUpgrade) {
            this._router.navigate([NavigationKeys.CreateUrl(NavigationType.Admin, NavigationKeys.DashboardPage)]);
            return;
        }

        var category = role.categories.find((category) => category.category.toLowerCase() == CategoryType.MyCourses);
        if (category && category.hasAccess && !category.requireUpgrade) {
            this._router.navigate([NavigationKeys.CreateUrl(NavigationType.Admin, NavigationKeys.MyCoursesPage)]);
            return;
        }
    }

    /**
     * @name navigateToFirstAvailableModuleInstrcutor
     * Validates if a user has access to modules in order to navigate them
     * If the my courses page is available then redirect them to the my courses page,
     * Otherwise redirect the user to the first accessible module
     */
    private navigateToFirstAvailableModuleInstrcutor() {
        LoggerHelper.log('Inside navigateToFirstAvailableModuleInstrcutor');
        var role: UserRoleModel = UserRolePermissionResponseModel.Instance.roles.find((role) => role.role.toLowerCase() == RoleType.Instructor.toLowerCase());

        var category = role.categories.find((category) => category.category.toLowerCase() == CategoryType.MyCourses);
        if (category && category.hasAccess && !category.requireUpgrade) {
            this._router.navigate([NavigationKeys.CreateUrl(NavigationType.Admin, NavigationKeys.MyCoursesPage)]);
            return;
        }
    }

    /**
     * @name navigateToFirstAvailableModuleLearner
     * Validates if a user has access to modules in order to navigate them
     * If the my courses page is available then redirect them to the my courses page,
     * Otherwise redirect the user to the first accessible module
     */
    private navigateToFirstAvailableModuleLearner() {
        LoggerHelper.log('Inside navigateToFirstAvailableModuleLearner');
        var role: UserRoleModel = UserRolePermissionResponseModel.Instance.roles.find((role) => role.role.toLowerCase() == RoleType.Learner.toLowerCase());

        var category = role.categories.find((category) => category.category.toLowerCase() == CategoryType.MyCourses);
        if (category && category.hasAccess && !category.requireUpgrade) {
            this._router.navigate([NavigationKeys.CreateUrl(NavigationType.Learner, NavigationKeys.MyCoursesPage)]);
            return;
        }
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  START API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------

    /**
     * @name GetAllInstitution
     * @param userId
     * Get all institutions by instructor id
     */
    async GetAllInstitution(userId: number, body: any) {
        await this.institutionViewModel
            .GetAllInstitution(userId, JSON.stringify(body))
            .toPromise()
            .then((result) => {
                LoggerHelper.log('New Data inside GetAllInstitution ' + JSON.stringify(result));

                this.userInstitutionPreferenceResponseModel = result;
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
                this.userInstitutionPreferenceResponseModel = new UserInstitutionPreferenceResponseModel();
            })
            .finally(() => { });
    }

    /**
     * @name UpdateUserInstitutionPreferences
     * @param body
     * Update the selected institution preferences
     */
    async UpdateUserInstitutionPreferences(body: any) {
        await this.institutionViewModel
            .UpdateUserInstitutionPreferences(JSON.stringify(body))
            .toPromise()
            .then((result) => {
                LoggerHelper.log('New Data inside UpdateUserInstitutionPreferences ' + JSON.stringify(result));
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
            })
            .finally(() => { });
    }

    /**
     * @name LoadUserPreferences
     * @param institutionId
     * Load user preferences
     */
    async LoadUserPreferences(institutionId: number) {
        await this.userPreferencesViewModel
            .GetUserPreferences(institutionId, UserSessionModel.Instance.user.id)
            .toPromise()
            .then((result) => {
                var data: UserPreferencesModel = result;
                LoggerHelper.log('New Data inside LoadUserPreferences ' + JSON.stringify(data));

                UserPreferencesModel.ClearInstance();
                UserPreferencesModel.UpdateInstance(data);
                UserPreferencesModel.ClearInstance();
                LoggerHelper.log(UserPreferencesModel.Instance);
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
            })
            .finally(() => { });
    }

    /**
     * @name GetOnboarding
     * @param GetOnboarding
     * get the onboarding preference for a specific user
     */
    async GetOnboardingByUserId(userId: number) {
        //fill the model to be sent
        await this.onboardingViewModel
            .GetOnboardingByUserId(userId)
            .toPromise()
            .then((result) => {
                if (result) {
                    OnBoardingPreferences.ClearInstance();
                    var onboardingHandler = new OnBoardingPreferences();
                    onboardingHandler = result;
                    OnBoardingPreferences.UpdateInstance(onboardingHandler);
                }
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
            })
            .finally(() => { });
    }

    /**
     * @name getInstitutionSettings
     * @param institutionId
     * get institution settings
     */
    async getInstitutionSettings(institutionId: number) {
        var institutionSettingResponseModel: InstitutionSettingResponseModel = await this.institutionViewModel.getInstitutionSettings(institutionId);
        if (institutionSettingResponseModel) {
            InstitutionSettingResponseModel.clearInstance();
            InstitutionSettingResponseModel.updateInstance(institutionSettingResponseModel);
            InstitutionSettingResponseModel.clearInstance();
        } else {
            InstitutionSettingResponseModel.clearInstance();
            InstitutionSettingResponseModel.updateInstance(new InstitutionSettingResponseModel());
            InstitutionSettingResponseModel.clearInstance();
        }
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  END API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
}
