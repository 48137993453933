import { CachingHelper } from '../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../helper/serialization-helper';

export class UserPreferencesModel {
    // userId: number = 0;
    canOverrideTheme: boolean;
    canOverrideLanguage: boolean;
    institutionId: number;
    institutionDescription: string;
    languageId?: number;
    languageDescription: string;
    languageIsoCode: string;
    fontSize: string;
    hasTakenOnBoarding: boolean;
    institutionLogo: string;
    themeId?: number;
    themeDescription: string;
    themePrimaryColor: string;
    themeDarkColor: string;
    themeLightColor: string;
    themeSecondaryColor: string;
    themeErrorColor: string;
    themeSuccessColor: string;
    active?: boolean;
    isK12Education: boolean;
    isHigherEducation: boolean;
    isTrainingEducation: boolean;
    isAdaptiveLearningEnabled: boolean;
    isLinearLearningEnabled: boolean;
    providerInstitutionPreference: UserPreferencesModel;

    private static _instance: UserPreferencesModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new UserPreferencesModel(), CachingHelper.getItemFromCache(CachingKeys.UserPreferences));

            //if instance does not exist
            if (!this._instance) {
                this.UpdateInstance(new UserPreferencesModel());
                this._instance = SerializationHelper.toInstance(new UserPreferencesModel(), CachingHelper.getItemFromCache(CachingKeys.UserPreferences));
            }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }
    public static UpdateInstance(data: UserPreferencesModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.UserPreferences, JSON.stringify(data));
    }
}
