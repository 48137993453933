import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../src/app/auth/_services/authentication.service';
import { FileResponseModel } from '../../../../../src/app/core/models/builder/media/file-response-model';
import { UserProfileRequestModel } from '../../../../../src/app/core/models/session/request/user-profile.request.model';
import { UserProfileResponseModel } from '../../../../../src/app/core/models/session/response/user-profile.response.model';
import { SaltResponseModel } from '../../../../../src/app/core/models/session/salt.response.model';
import { UserSessionModel } from '../../../../../src/app/core/models/session/user-session.model';
import { UserModel } from '../../../../../src/app/core/models/session/user.model';
import { UserSignUpStatusResponseModel } from '../../../../../src/app/core/models/shared/user/response/user-sign-up-status.response.model';
import { UserSessionApplicationService } from '../../../../../src/app/core/services/session/user-session-application.service';
import { AccountApplicationService } from '../../../../../src/app/core/services/teacher/account/account.application.service';
import { InstitutionSettingResponseModel } from '../../../core/models/institution/response/institution-settings.response.model';
import { AnalyticsService } from '../../helper/analytics/analytics.service';
import { AnalyticsViewModel } from '../../helper/analytics/analytics.viewmodel';
import { IntercomService } from '../../helper/intercom/intercom-service';
import { LoggerHelper } from '../../helper/logging/logger';
import { NavigationKeys } from '../../helper/navigation-keys';
import { SessionHelper } from '../../helper/session/session.helper';

@Injectable({
  providedIn: 'root',
})
export class UserSessionViewModel {
  constructor(
    private service: AuthenticationService,
    private userSessionApplicationService: UserSessionApplicationService,
    private accountApplicationService: AccountApplicationService,
    private analyticsService: AnalyticsService,
    private _router: Router,
    public intercomService: IntercomService,
    private analyticsViewModel: AnalyticsViewModel
  ) {}

  /**
   * @name DeleteSession
   * Delete user session and log out
   */
  DeleteSession() {
    var data = this.service.LogoutSession();
    return data;
  }

  /**
   * @name clearSessionData
   * Clear all session data on the browser
   */
  async clearSessionData() {
    // var analyticsUserSessionModel: AnalyticsUserSessionModel = new AnalyticsUserSessionModel();
    // analyticsUserSessionModel.username = UserSessionModel.Instance.user.username;
    // analyticsUserSessionModel.id = UserSessionModel.Instance.user.code;
    // analyticsUserSessionModel.date = DateTimeHelper.FormatDateTime(new Date(), 'HH:mm dd-MM-yyyy', 'en-US');
    // this.analyticsService.logEventWithValue(AnalyticsProperties.EventLogout, analyticsUserSessionModel, this.analyticsViewModel.getTrackEventExtras());

    this.intercomService.shutdown();
    this.analyticsService.destroyAnalyticsService();

    SessionHelper.clearSessionData();
    this._router.navigate(['/' + NavigationKeys.LoginPage]);
  }

  /**
   * @name getSaltFromServer
   * @param body
   * @returns
   * Get salt from server based on username
   */
  public async getSaltFromServer(body: string): Promise<string> {
    return await this.service
      .getSaltFromServer(body)
      .toPromise()
      .then((result: SaltResponseModel) => {
        return result.encryptedSalt;
      })
      .catch((error) => {
        return null;
      });
  }

  /**
   * @name getUserStatus
   * @param body
   * @returns
   * Get user status
   */
  public async getUserStatus(body: string): Promise<UserSignUpStatusResponseModel> {
    return await this.service
      .getUserStatus(body)
      .toPromise()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return null;
      });
  }

  /**
   * @name authenticateUser
   * @param body
   * @returns
   * Authenticate the user
   */
  public async authenticateUser(body: string): Promise<UserSessionModel> {
    return await this.service
      .authenticateUser(body)
      .toPromise()
      .then((result: UserSessionModel) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * @name authenticateUserSilentlyUsingCode
   * @param body
   * @returns
   * Authenticate the user silently using the code
   */
  public async authenticateUserSilentlyUsingCode(authenticationCode: string): Promise<UserSessionModel> {
    return await this.service
      .authenticateUserSilentlyUsingCode(authenticationCode)
      .toPromise()
      .then((result: UserSessionModel) => {
        return result;
      })
      .catch((error) => {
        return null;
      });
  }

  /**
   * @name updateUserProfile
   * @param body
   * @returns
   * Authenticate the user
   */
  public async updateUserProfile(body: string, userId: number): Promise<UserProfileResponseModel> {
    return await this.userSessionApplicationService
      .updateUserProfile(body, userId)
      .toPromise()
      .then((result: UserProfileResponseModel) => {
        return result;
      })
      .catch((error) => {
        return null;
      });
  }

  /**
   * @name uploadUserProfileImage
   * @param formData
   * @param userId
   * @returns
   * Upload user profile image
   */
  public async uploadUserProfileImage(formData: FormData, userId: number): Promise<FileResponseModel[]> {
    return await this.userSessionApplicationService
      .uploadUserProfileImage(formData, userId)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              var progress = Math.round((100 * event.loaded) / event.total);
              LoggerHelper.log('PROGRESS ====> ' + progress);
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      )
      .toPromise()
      .then((result: any) => {
        return result;
      })
      .catch((error) => {
        return null;
      });
  }

  /**
   * @name fillUserRequestModel
   * @param userModel
   * @returns
   */
  fillUserRequestModel(userModel: UserModel): UserProfileRequestModel {
    var userProfileRequestModel = new UserProfileRequestModel();
    userProfileRequestModel.photo = userModel.photo;
    userProfileRequestModel.firstName = userModel.firstName;
    userProfileRequestModel.lastName = userModel.lastName;
    userProfileRequestModel.title = userModel.title;
    userProfileRequestModel.description = userModel.description;
    return userProfileRequestModel;
  }

  /**
   * @name getAccountSettings
   * @returns
   * Get account settings
   */
  public async getAccountSettings(): Promise<InstitutionSettingResponseModel> {
    return await this.accountApplicationService
      .getAccountSettings()
      .toPromise()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return null;
      });
  }
}
