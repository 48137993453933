import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule
} from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogLoading } from './dialog-loading.component';

const routes: Routes = [
    {
        path: '',
        component: DialogLoading
    }
];
@NgModule({
    imports: [
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        TranslateModule.forChild()
    ],
    entryComponents: [DialogLoading],
    exports: [RouterModule],
    declarations: [DialogLoading]
})
export class DialogLoadingModule { }
