export class DialogData {
    theme?: string;
    icon?: string;
    title?: string;
    description?: string;
    negativeButtonText?: string;
    positiveButtonText?: string;
    positiveButtonActionUrl?: string;
    actionSecondButtonUrl?: string;
    actionSecondButtonText?: string = '';
    inputLimitNumberOfCharacters?: number;
    inputFirstField?: string;
    disableClickOutsideDialog?: boolean = true;
}
