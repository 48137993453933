import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatSelectModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PopupFooterComponent } from './builder/footer/popup-footer.component';
import { PopupHeaderComponent } from './builder/header/popup-header.component';
import { PopupInformativeComponent } from './informative/popup-informative.component';
import { PopupInputStepComponent } from './manage-input/components/step/popup-input-step.component';
import { PopupInputComponent } from './manage-input/popup-input.component';
import { PopupSelectionComponent } from './select-list/popup-selection.component';
import { PopupCardSelectionComponent } from './select-card/popup-card-selection.component';

@NgModule({
  imports: [MatSelectModule, MatInputModule, MatIconModule, MatButtonModule, MatDialogModule, MatListModule, CommonModule, FormsModule, ReactiveFormsModule, TranslateModule.forChild()],
  entryComponents: [PopupInputComponent, PopupInformativeComponent, PopupSelectionComponent, PopupCardSelectionComponent],
  exports: [RouterModule, PopupHeaderComponent, PopupFooterComponent, PopupSelectionComponent, PopupCardSelectionComponent],
  declarations: [PopupInputComponent, PopupInputStepComponent, PopupInformativeComponent, PopupHeaderComponent, PopupFooterComponent, PopupSelectionComponent, PopupCardSelectionComponent],
})
export class PopupModule { }
