import { Injectable } from '@angular/core';
import { BrandingResponseModel } from '../../../../core/models/shared/branding/response/branding.response.model';
import { BrandingApplicationService } from '../../../../core/services/shared/branding/branding.application.service';

@Injectable({
    providedIn: 'root',
})
export class BrandingViewModel {
    constructor(private applicationService: BrandingApplicationService) { }

    /**
     * @name getAccountBranding
     * @returns
     */
    public async getAccountBranding(): Promise<BrandingResponseModel> {
        return await this.applicationService
            .getAccountBranding()
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getAccountBrandingByAccountId
     * @param accountId
     * @returns
     */
    public async getAccountBrandingByAccountId(accountId: number): Promise<BrandingResponseModel> {
        return await this.applicationService
            .getAccountBrandingByAccountId(accountId)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }
}
