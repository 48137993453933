export class GlobalVaribales {
    // Intercom
    public static IntercomAppID: string = 'o7r5vzhb';

    public static DelayToLoadLazyDataBetweenComponents = 150;
    public static ShortDelayToLoadLazyDataBetweenComponents = 50;

    //Level colors
    public static ReportBeginnerLevelColor: string = '#7F78D2';
    public static ReportIntermediateLevelColor: string = '#EFB1FF';
    public static ReportAdvancedLevelColor: string = '#FFE2FF';

    //Content colors
    public static ReportContentImageColor: string = '#4F3961';
    public static ReportContentAudioColor: string = '#FDBDBD';
    public static ReportContentTextColor: string = '#F590A6';
    public static ReportContentVideoColor: string = '#C55870';
    public static ReportContentMixedColor: string = '#F7DDDD';

    //Achievement colors
    public static ReportCompletedColor: string = '#4EE4B4';
    public static ReportAchievedColor: string = '#21D39A';
    public static ReportStrugglingColor: string = '#F4516C';
    public static ReportInProgressColor: string = '#F4AA51';
    public static ReportNotStartedColor: string = '#B2B2B2';

    public static DefaultCourseBackgroundImage: string[] = [
        'https://f002.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zce334b432d349fbd6ace011b_f1027e6781bed4b70_d20230404_m123356_c002_v0001164_t0057_u01680611636026',
        'https://f002.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zce334b432d349fbd6ace011b_f10380fa876b82715_d20230404_m123401_c002_v0001143_t0018_u01680611641242',
        'https://f002.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zce334b432d349fbd6ace011b_f1090216dee8db355_d20230404_m123406_c002_v0001126_t0039_u01680611646214',
        'https://f002.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zce334b432d349fbd6ace011b_f1105908524528a9a_d20230404_m123409_c002_v0001108_t0034_u01680611649988',
        'https://f002.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zce334b432d349fbd6ace011b_f10996f520a7eb0f3_d20230404_m123414_c002_v0001163_t0001_u01680611654591',
    ];
}
