import { AnalyticsAccountModel } from '../account/analytics-account.model';

export class AnalyticsUserModel {
    name: string;
    email: string;
    plan: string;
    logins: number;
    age: number;
    avatar: string;
    birthday: Date;
    company: AnalyticsAccountModel;
    createdAt: string;
    description: string;
    firstName: string;
    gender: string;
    id: string;
    lastName: string;
    phone: string;
    title: string;
    username: string;
    website: string;

    //Extra
    nationality: string;
    role: string[];
}
