import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatSelectModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PopupCodePreviewComponent } from '../code/popup-code-preview.component';
import { DialogFeatureReleaseComponent } from '../feature-release/dialog-feature-release.component';
import { DialogInformative } from '../informative/dialog-informative.component';
import { DialogInformativeMultipleActions } from '../informative/multiple-actions/dialog-informative-multiple-actions.component';
import { DialogInformativeNewComponent } from '../informative/new/dialog-informative.component';
import { DialogList } from '../list/dialog-list.component';
import { DialogSearchableList } from '../searchable-list/dialog-searchable-list.component';
import { DialogSingleOutput } from '../single-output/dialog-single-output.component';
import { WarningRecurrentSessionPopupComponent } from './../../../../theme/teacher/pages/live-session/templates/warning-recurrent-session-popup/warning-recurrent-session-popup.component';
import { DialogSingleInput } from './dialog-single-input.component';

const routes: Routes = [
    {
        path: '',
        component: DialogSingleInput,
        // children: [
        //   {
        //     path: '',
        //     component: ModalComponent
        //   }
        // ]
    },
];
@NgModule({
    imports: [
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
    ],
    entryComponents: [
        DialogSingleInput,
        DialogList,
        DialogSearchableList,
        DialogInformative,
        DialogInformativeMultipleActions,
        DialogSingleOutput,
        WarningRecurrentSessionPopupComponent,
        DialogFeatureReleaseComponent,
        DialogInformativeNewComponent,
        PopupCodePreviewComponent,
    ],
    exports: [RouterModule],
    declarations: [
        DialogSingleInput,
        DialogList,
        DialogSearchableList,
        DialogInformative,
        DialogInformativeMultipleActions,
        DialogSingleOutput,
        WarningRecurrentSessionPopupComponent,
        DialogFeatureReleaseComponent,
        DialogInformativeNewComponent,
        PopupCodePreviewComponent,
    ],
})
export class DialogSingleInputModule { }
