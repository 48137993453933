import { CachingHelper } from '../../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../../helper/serialization-helper';

export class CourseFullResponseModel {
    id: number;
    name: string;
    description: string;
    category: string;
    isPublished: boolean;
    translatedStatus: string;
    descendantCourses: CourseFullResponseModel[];

    private static _instance: CourseFullResponseModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(
                new CourseFullResponseModel(),
                CachingHelper.getItemFromCache(CachingKeys.SubCourseSession)
            );

            //if instance does not exist
            if (!this._instance) {
                this.UpdateInstance(new CourseFullResponseModel());
                this._instance = SerializationHelper.toInstance(
                    new CourseFullResponseModel(),
                    CachingHelper.getItemFromCache(CachingKeys.SubCourseSession)
                );
            }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }
    public static UpdateInstance(data: CourseFullResponseModel) {
        CachingHelper.saveItemIntoCache(
            CachingKeys.SubCourseSession,
            JSON.stringify(data)
        );
    }
}
