import * as CryptoJS from 'crypto-js';
import { DecryptionHelper } from './decryption.hepler';
import { EncryptionHelper } from './encryption.hepler';

export class EncryptionDecryptionHelper {
    /**
     * Decrypt function common with mobile
     * @param key
     * @param iv
     * @param cipherText
     */
    public static DecryptStringAES(key: string, iv: string, cipherText: string, feedbackSize: number = 16): string {
        var decryptedText = DecryptionHelper.DecryptText_Aes_Advanced(cipherText, key, iv, CryptoJS.mode.CBC, CryptoJS.pad.Pkcs7, feedbackSize);
        return decryptedText;
    }

    /**
     * @name EncryptStringHmacSHA256
     * @param key
     * @param normalText
     * Encrypt function common with content studio
     */
    public static EncryptStringHmacSHA256(key: string, normalText: string) {
        var encryptedText = EncryptionHelper.EncryptStringHmacSHA256(normalText, key);
        return encryptedText;
    }

    /**
     * Encrypt function common with content studio
     * @param key
     * @param iv
     * @param normalText
     */
    public static EncryptStringAES(key: string, iv: string, normalText: string, feedbackSize: number = 16) {
        var encryptedText = EncryptionHelper.EncryptStringAES_Advanced(normalText, key, iv, CryptoJS.mode.CBC, CryptoJS.pad.Pkcs7, feedbackSize);
        return encryptedText;
    }
}
