export enum KnowledgeBaseKey {
    CourseLessonPlan = 'lesson-plan',
    CourseCertificate = 'certificate',
    CourseDripSchedule = 'drip-schedule',
    CourseSettings = 'course-settings',
    CourseContentQuestions = 'content-questions',
    CoursePublish = 'publish',
    CourseMonetize = 'monetize',
    AccountManageType = 'account-type',
    AccountManageBranding = 'account-branding',
    AccountManageSubAccounts = 'account-sub-accounts',
    AccountManageCustomDomain = 'account-custom-domain',
    IntegrationsPaymentGateawy = 'integrations-payment-gateway',
    SiteBuilderFeaturedCourses = 'site-builder-featured-courses',
    SiteBuilderAllCourses = 'site-builder-all-courses',
}
