import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerHelper } from '../../shared/helper/logging/logger';
import { NavigationKeys } from '../../shared/helper/navigation-keys';
import { ActionType } from '../../shared/helper/role/action.enum';
import { UserRolesViewModel } from '../../shared/viewmodel/roles/user-roles.viewmodel';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
    // Reference:
    // https://medium.com/angular-in-depth/new-in-angular-v7-1-updates-to-the-router-fd67d526ad05
    // https://stackoverflow.com/a/63955377

    constructor(private _router: Router, private userRolesViewModel: UserRolesViewModel) { }

    /**
     * @name canActivate
     * @param route
     * @param state
     * @returns
     * Validates if the user has the right persmissions to access the desired page or not
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
        LoggerHelper.log(route.data.category);
        LoggerHelper.log(route.data.permission);
        LoggerHelper.log(route.data.role);

        return this.validateConditions(route);
    }

    /**
     * @name validateConditions
     * @param route
     * @returns
     * Validates the conditions based on the roles and permissions
     */
    private validateConditions(route: ActivatedRouteSnapshot): boolean | UrlTree {
        if (route.data.role) {
            if (this.validateRole(route)) {
                if (route.data.category && route.data.permission) {
                    if (this.validatePermission(route)) {
                        LoggerHelper.log('User has the required roles/permissions to access the module');
                        return true;
                    } else {
                        LoggerHelper.log('User has missing permissions to access the module');
                        return this.forceNavigateUserToInitialPage();
                    }
                } else {
                    LoggerHelper.log('User has the required role to access the module');
                    return true;
                }
            } else {
                LoggerHelper.log('User has missing role to access the module');
                return this.forceNavigateUserToInitialPage();
            }
        } else {
            LoggerHelper.log('User can bypass and access the module');
            return true;
        }
    }

    /**
     * @name validateRole
     * @param route
     * @returns true if role is granted, else false
     * Validates the role of the user if granted or not
     */
    private validateRole(route: ActivatedRouteSnapshot): boolean {
        return this.userRolesViewModel.isRoleGranted(route.data.role);
    }

    /**
     * @name validatePermission
     * @param route
     * @returns true if permissions is granted, else false
     * Validates the permission of the user if granted or not
     */
    private validatePermission(route: ActivatedRouteSnapshot): boolean {
        return this.userRolesViewModel.isGranted(route.data.category, route.data.permission, ActionType.Visit);
    }

    /**
     * @forceNavigateUserToInitialPage
     * @returns the URL of the navigation
     */
    private forceNavigateUserToInitialPage(): UrlTree {
        return this._router.createUrlTree([NavigationKeys.RoutingPage]);
    }
}
