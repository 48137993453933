import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule, MatIconModule, MatListModule } from "@angular/material";
import { OwlModule } from "ngx-owl-carousel";
import { OnBoardingPopupComponent } from "./on-boarding-popup.component";
import { OnBoardingPopupTitleComponent } from "./templates/title/on-boarding-popup-title.component";

@NgModule({
    imports: [CommonModule, OwlModule, MatListModule, MatIconModule, MatButtonModule],
    exports: [OnBoardingPopupComponent],
    entryComponents: [OnBoardingPopupComponent],
    declarations: [OnBoardingPopupComponent, OnBoardingPopupTitleComponent],
})
export class OnBoardingModule { }