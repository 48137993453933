import { Injectable } from '@angular/core';
import { SharedService } from '../../../../../src/app/_services/SharedService';
import { UserRoleCategoryModel } from '../../../core/models/roles/user-role-category.model';
import { UserRolePermissionResponseModel } from '../../../core/models/roles/user-role-permission-response.model';
import { UserRolesApplicationService } from '../../../core/services/roles/user-roles-application-service.service';
import { LoggerHelper } from '../../helper/logging/logger';
import { CategoryType } from '../../helper/role/category.enum';

@Injectable({
    providedIn: 'root',
})
export class UserRolesViewModel {
    constructor(private sharedService: SharedService, private service: UserRolesApplicationService) { }

    /**
     * @name isRoleGranted
     * @param userRole
     * @returns true in case hasAccess, false otherwise
     */
    isRoleGranted(userRole: string[]): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        if (roles) {
            for (let role of roles) {
                var tmpUserRole = userRole.find((item) => item.toLowerCase().includes(role.role.toLowerCase()));
                if (tmpUserRole) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * @name isGranted
     * @param userCategory
     * @param userPermission
     * @param userAction
     * @returns true in case hasAccess, false otherwise
     */
    isGranted(userCategory: string, userPermission: string, userAction: string): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        if (roles) {
            for (let role of roles) {
                for (let category of role.categories) {
                    if (category && this.isValueValid(category.category, userCategory)) {
                        for (let permission of category.permissions) {
                            if (permission && this.isValueValid(permission.permission, userPermission)) {
                                for (let action of permission.actions) {
                                    if (action && this.isValueValid(action.action, userAction)) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    /**
     * @name isRolePermissionGranted
     * @param userCategory
     * @param userPermission
     * @param userAction
     * @returns the category chosen, else null
     */
    isRolePermissionGranted(userCategory: string, userPermission: string, userAction: string): UserRoleCategoryModel {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        if (roles) {
            for (let role of roles) {
                var tmpUserCategory = role.categories.find((element) => this.isValueValid(element.category, userCategory));
                if (tmpUserCategory) {
                    return tmpUserCategory;
                    // var tmpUserPermission = tmpUserCategory.permissions.find((element) => this.isValueValid(element.permission, userPermission));
                    // if (tmpUserPermission) {
                    //   var tmpUserAction = tmpUserPermission.actions.find((element) => this.isValueValid(element.action, userAction));
                    //   if (tmpUserAction) {
                    //     return tmpUserCategory;
                    //   }
                    // } else {
                    //   return tmpUserCategory;
                    // }
                }
            }
        }
        return null;
    }

    /**
     * @name isSpecificPermissionGranted
     * @param userCategory
     * @param userPermission
     * @param userAction
     * @returns true in case hasAccess, false otherwise
     */
    isSpecificPermissionGranted(userCategory: string, userPermission: string, userAction: string): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        if (roles) {
            for (let role of roles) {
                var tmpUserCategory = role.categories.find((element) => this.isValueValid(element.category, userCategory));
                if (tmpUserCategory) {
                    if (!tmpUserCategory.hasAccess || tmpUserCategory.requireUpgrade) {
                        return false;
                    } else {
                        var tmpUserPermission = tmpUserCategory.permissions.find((element) => this.isValueValid(element.permission, userPermission));
                        if (tmpUserPermission) {
                            var tmpUserAction = tmpUserPermission.actions.find((element) => this.isValueValid(element.action, userAction));
                            if (tmpUserAction) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    /**
     * @name isAccessToAllFeaturesDisabled
     * @returns true if the user has all features disabled, false otherwise
     */
    public isAccessToAllFeaturesDisabled(): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        var hasAccessDisabled: boolean = true;
        if (roles) {
            for (let role of roles) {
                if (role.categories && role.categories.length > 0) {
                    for (let category of role.categories) {
                        if (category.hasAccess && !category.requireUpgrade) {
                            if (category.permissions && category.permissions.length > 0) {
                                hasAccessDisabled = false;
                            }
                        }
                    }
                }
            }
        }
        return hasAccessDisabled;
    }

    /**
     * @name
     * @returns true if the user has all features require upgrade, false otherwise
     * Validates if the user has access to features or all features require them to upgrade
     */
    public isAccessToAllFeaturesRequireUpgrade(): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        var hasAccessDisabled: boolean = true;
        if (roles) {
            for (let role of roles) {
                for (let category of role.categories) {
                    if (!category.requireUpgrade) {
                        hasAccessDisabled = false;
                    }
                }
            }
        }
        return hasAccessDisabled;
    }

    /**
     * @name isRoleValid
     * @param userRole
     * @returns true in case has this role, false otherwise
     */
    isRoleValid(userRole: string): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        return !!roles.find((item) => item.role === userRole);
    }

    /**
     * @name isCategoryValid
     * @param userCategories
     * @returns true in case has this category, false otherwise
     */
    isCategoryValid(userCategories: string[]): boolean {
        const roles = UserRolePermissionResponseModel.Instance.roles;
        if (roles) {
            for (let role of roles) {
                for (let category of role.categories) {
                    if (category) {
                        for (let userCategory of userCategories) {
                            if (this.isValueValid(category.category, userCategory)) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    /**
     * @name isValueValid
     * @param valueInitial
     * @param valueToCompare
     * @returns true in case has the string matches, false otherwise
     */
    private isValueValid(valueInitial: string, valueToCompare: string): boolean {
        if (valueInitial) {
            if (valueInitial.toLowerCase() === valueToCompare) {
                return true;
            }
        }
        return false;
    }

    /**
     * @name verifyTheAvailableModuleToAccess
     * @returns UserRoleCategoryModel
     * Return the course element if available, otherwise it returns the first available item in the modules list
     */
    public verifyTheAvailableModuleToAccess(): UserRoleCategoryModel {
        LoggerHelper.log('-----');
        const roles = UserRolePermissionResponseModel.Instance.roles;

        if (roles) {
            for (let role of roles) {
                var tmpUserCategory = role.categories.find((element) => this.isValueValid(element.category, CategoryType.MyCourses));
                LoggerHelper.log('Case 1');
                LoggerHelper.log(tmpUserCategory);

                if (tmpUserCategory && tmpUserCategory.hasAccess && !tmpUserCategory.requireUpgrade) {
                    return tmpUserCategory;
                } else {
                    var tmpUserCategory = role.categories.find((element) => element.hasAccess);
                    LoggerHelper.log('Case 2');
                    LoggerHelper.log(tmpUserCategory);
                    if (tmpUserCategory.hasAccess && !tmpUserCategory.requireUpgrade) {
                        return tmpUserCategory;
                    }
                }
            }
        }
        return null;
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  START API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------

    /**
     * @name GetUserPermission
     * @param institutionId
     * @param userId
     * Get user permissions in a specific institution
     * @deprecated Use `getUserPermission()` instead.
     */
    GetUserPermission(institutionId: number, userId: number) {
        var data = this.service.GetUserPermission(institutionId, userId);
        return data;
    }

    /**
     * @name getUserPermission
     * @param institutionId
     * @param userId
     * Get user permissions in a specific institution
     */
    async getUserPermission(institutionId: number, userId: number) {
        return await this.service
            .GetUserPermission(institutionId, userId)
            .toPromise()
            .then((result: UserRolePermissionResponseModel) => {
                UserRolePermissionResponseModel.ClearInstance();
                UserRolePermissionResponseModel.UpdateInstance(result);
                UserRolePermissionResponseModel.ClearInstance();
                this.sharedService.onUserRoleChange.emit(true);
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
    //  END API Calls
    //-------------------------------------------------
    //-------------------------------------------------
    //-------------------------------------------------
}
