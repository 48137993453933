import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwlCarousel } from 'ngx-owl-carousel';
import { GlobalVaribales } from '../../../../../../../../../../src/app/shared/helper/global-variable';
import { UtilityHelper } from '../../../../../../../../../../src/app/shared/helper/utility';
import { TranslationService } from '../../../../../../../../config/services/translation.service';
import { DialogOnBoardingData } from '../on-boarding-builder-dialog-model';

//References
//https://owlcarousel2.github.io/OwlCarousel2/docs/api-options.html
//https://www.freakyjolly.com/angular-7-6-adding-owl-carousel-2-image-and-content-slider-in-angular-application-with-custom-navigation-in-3-steps/

@Component({
    selector: 'on-boarding-popup',
    templateUrl: './on-boarding-popup.component.html',
    styleUrls: [`on-boarding-popup-style.scss`],
    encapsulation: ViewEncapsulation.None
})
export class OnBoardingPopupComponent {
    isQuestion: boolean = false;
    dataList: DialogOnBoardingData[] = [];
    @ViewChild('owlElement') owlElement: OwlCarousel;

    carouselOptions = {
        items: 1,
        loop: false,
        dots: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        rewind: true,
        responsiveClass: false
    };

    constructor(
        public dialogRef: MatDialogRef<OnBoardingPopupComponent>,
        public translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.LoadData();
    }

    async LoadData() {
        await UtilityHelper.delay(
            GlobalVaribales.DelayToLoadLazyDataBetweenComponents
        ); //delay the data reading, until the data has been set from the previous page using lazy loading

        if (this.data.dataList.length > 0) {
            this.dataList = this.data.dataList;
            this.isQuestion = this.data.isQuestion;
        }
    }

    /**
     * Go to the next slide
     */
    NextSlide() {
        this.owlElement.next();
    }

    /**
     * Go to the previous slide
     */
    PreviousSlide() {
        this.owlElement.previous();
    }


    Close() {
        this.dialogRef.close();
    }
}
