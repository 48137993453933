import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { TranslationService } from '../../../../../../src/app/config/services/translation.service';
import { CountryResponseModel } from '../../../../../../src/app/core/models/country/response/country-response.model';
import { AccountSetupRequestModel } from '../../../../../../src/app/core/models/shared/account/request/account-setup.request.model';
import { AccountTypeResponseModel } from '../../../../../../src/app/core/models/shared/account/response/account-type.response.model';
import { CountryApplicationService } from '../../../../../../src/app/core/services/shared/country/country.application.service';
import { AccountApplicationService } from '../../../../../../src/app/core/services/teacher/account/account.application.service';
import { DialogData } from '../../../../../../src/app/shared/components/dialog-builder/dialog-single-input-model';
import { DialogInformativeNewComponent } from '../../../../../../src/app/shared/components/dialog-builder/informative/new/dialog-informative.component';
import { LoggerHelper } from '../../../../../../src/app/shared/helper/logging/logger';
import { IconsList } from '../../../../../../src/app/shared/helper/theme/icons';
import { ThemeType } from '../../../../../../src/app/shared/helper/theme/theme-type.enum';
import { RegexValidationHelper } from '../../../../../../src/app/shared/helper/validation/regex.enum';
import { environment } from '../../../../../../src/environments/environment';
import { InstitutionSettingResponseModel } from '../../../../core/models/institution/response/institution-settings.response.model';

@Injectable({
    providedIn: 'root',
})
export class AccountViewModel implements OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    private isRTL: boolean = false;

    constructor(
        public translate: TranslateService,
        public translationService: TranslationService,
        public dialog: MatDialog,
        private _router: Router,
        public service: AccountApplicationService,
        private countryApplicationService: CountryApplicationService
    ) {
        // this.labelUpdate();
        // this.translate.onLangChange.takeUntil(this.destroyed$).subscribe((event: LangChangeEvent) => {
        //   this.labelUpdate();
        // });
    }

    /**
     * @name labelUpdate
     * Loads the translations
     */
    // private labelUpdate() {
    //   this.isRTL = this.translationService.isPlatformDirectionRtl();
    // }

    /**
     * On page destroy
     */
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    /**
     * @name getAllCountries
     * @returns data
     */
    public async getAllCountries(): Promise<CountryResponseModel[]> {
        return await this.countryApplicationService
            .getAllCountries()
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getAccountTypes
     * @returns the account types list
     */
    public async getAccountTypes(): Promise<AccountTypeResponseModel[]> {
        return await this.service
            .getAccountTypes()
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name validateDomainNameAvailability
     * @param body
     * @returns
     * Validate the domain name availability
     */
    public async validateDomainNameAvailability(body: string): Promise<boolean> {
        return await this.service
            .validateDomainNameAvailability("'" + body + "'")
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getAccountSettings
     * @returns
     * Get account settings
     */
    public async getAccountSettings(): Promise<InstitutionSettingResponseModel> {
        return await this.service
            .getAccountSettings()
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name registerMainAccount
     * @param usercode
     * @param body
     * @returns
     * Register the main account
     */
    public async registerMainAccount(usercode: string, body: string): Promise<boolean> {
        return await this.service
            .registerMainAccount(usercode, body)
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                return null;
            });
    }
    // public async registerMainAccount(usercode: string, body: string): Promise<string> {
    //   return await this.service
    //     .registerMainAccount(usercode, body)
    //     .toPromise()
    //     .then((result) => {
    //       return result;
    //     })
    //     .catch((error) => {
    //       return null;
    //     });
    // }

    /**
     * @name explainFieldPopup
     * @param title
     * @param description
     * @returns
     */
    explainFieldPopup(title: string, description: string): MatDialogRef<DialogInformativeNewComponent, any> {
        var dialogFeatureReleaseModel: DialogData = new DialogData();
        dialogFeatureReleaseModel.icon = IconsList.Info;
        dialogFeatureReleaseModel.theme = ThemeType.Primary;
        dialogFeatureReleaseModel.title = title;
        dialogFeatureReleaseModel.description = description;
        dialogFeatureReleaseModel.negativeButtonText = this.translationService.GetTranslatedValue('GENERAL.Dismiss');
        dialogFeatureReleaseModel.positiveButtonText = '';
        dialogFeatureReleaseModel.positiveButtonActionUrl = '';

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = dialogFeatureReleaseModel;
        dialogConfig.panelClass = 'custom-dialog-container';
        if (this.translationService.isPlatformDirectionRtl()) {
            dialogConfig.direction = 'rtl';
        } else {
            dialogConfig.direction = 'ltr';
        }

        return this.dialog.open(DialogInformativeNewComponent, dialogConfig);
    }

    /**
     * @name validateAllAccountSetupSteps
     * @param accountSetupRequestModel
     * @returns
     * Validate all the steps of the account setup
     */
    async validateAllAccountSetupSteps(accountSetupRequestModel: AccountSetupRequestModel, isQuickFlowEnabled: boolean): Promise<string> {
        LoggerHelper.log(accountSetupRequestModel);
        var errors: string[] = [];

        if (!isQuickFlowEnabled && !accountSetupRequestModel.isBusinessAccount && !accountSetupRequestModel.isIndividualAccount) {
            errors.push('Business type');
        }

        if (!accountSetupRequestModel.learningSpaceName || accountSetupRequestModel.learningSpaceName.trim().length == 0) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step1.LearningSpace'));
        }

        if (!accountSetupRequestModel.isAccessPrivate && !accountSetupRequestModel.isAccessPublic) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step1.Access'));
        }

        if (
            !isQuickFlowEnabled &&
            (!accountSetupRequestModel.primaryColor ||
                accountSetupRequestModel.primaryColor.length == 0 ||
                !accountSetupRequestModel.darkColor ||
                accountSetupRequestModel.darkColor.length == 0 ||
                !accountSetupRequestModel.lightColor ||
                accountSetupRequestModel.lightColor.length == 0 ||
                !accountSetupRequestModel.secondaryColor ||
                accountSetupRequestModel.secondaryColor.length == 0)
        ) {
            errors.push('Color');
        }

        if (!isQuickFlowEnabled) {
            if (!accountSetupRequestModel.customDomain || accountSetupRequestModel.customDomain.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step3.Domain'));
            } else {
                //add a condition to check if the domain in edit mode in the same, then do not send a check request
                if (!(await this.validateDomainNameAvailability(accountSetupRequestModel.customDomain + environment.webAppDomain))) {
                    errors.push(this.translationService.GetTranslatedValue('Account.Step3.DomainInvalidShortMessage'));
                }
            }
        }

        if (!isQuickFlowEnabled && accountSetupRequestModel.isBusinessAccount) {
            if (!accountSetupRequestModel.website || accountSetupRequestModel.website.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.Website'));
            }

            if (accountSetupRequestModel.countryId <= 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.CountryOfOperation'));
            }

            if (!accountSetupRequestModel.address || accountSetupRequestModel.address.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.Address'));
            }

            if (!accountSetupRequestModel.legalBusinessName || accountSetupRequestModel.legalBusinessName.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.LegalBusinessName'));
            }
        }

        if (accountSetupRequestModel.learningSpaceTargetId == null || accountSetupRequestModel.learningSpaceTargetId <= 0) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step5.LearningSpaceTarget'));
        }

        var errorMessage: string = '';
        if (errors.length > 0) {
            errorMessage = errors
                .filter(function(val) {
                    return val;
                })
                .join(', ');
            errorMessage = errorMessage + '.';
        } else {
            errorMessage = '';
        }

        return errorMessage;
    }

    /**
     * @name validateAllAccountSetupManagement
     * @param accountSetupRequestModel
     * @returns
     * Validate all the steps of the account setup
     */
    async validateAllAccountSetupManagement(accountSetupRequestModel: AccountSetupRequestModel, shouldValidateDomain: boolean = true): Promise<string> {
        LoggerHelper.log(accountSetupRequestModel);
        var errors: string[] = [];

        if (!accountSetupRequestModel.isBusinessAccount && !accountSetupRequestModel.isIndividualAccount) {
            errors.push('Business type');
        }

        if (!accountSetupRequestModel.learningSpaceName || accountSetupRequestModel.learningSpaceName.trim().length == 0) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step1.LearningSpace'));
        }

        if (!accountSetupRequestModel.isAccessPrivate && !accountSetupRequestModel.isAccessPublic) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step1.Access'));
        }

        if (
            !accountSetupRequestModel.primaryColor ||
            accountSetupRequestModel.primaryColor.length == 0 ||
            !accountSetupRequestModel.darkColor ||
            accountSetupRequestModel.darkColor.length == 0 ||
            !accountSetupRequestModel.lightColor ||
            accountSetupRequestModel.lightColor.length == 0 ||
            !accountSetupRequestModel.secondaryColor ||
            accountSetupRequestModel.secondaryColor.length == 0
        ) {
            errors.push('Color');
        }

        if (shouldValidateDomain) {
            if (!accountSetupRequestModel.customDomain || accountSetupRequestModel.customDomain.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step3.Domain'));
            } else {
                //add a condition to check if the domain in edit mode in the same, then do not send a check request
                if (!(await this.validateDomainNameAvailability(accountSetupRequestModel.customDomain + environment.webAppDomain))) {
                    errors.push(this.translationService.GetTranslatedValue('Account.Step3.DomainInvalidShortMessage'));
                }
            }
        }

        // Validate the terms link
        var validateWebsiteRegexp = new RegExp(RegexValidationHelper.Website);
        if (accountSetupRequestModel.termsLink && accountSetupRequestModel.termsLink.length > 0) {
            try {
                if (!validateWebsiteRegexp.test(accountSetupRequestModel.termsLink)) {
                    errors.push(this.translationService.GetTranslatedValue('Account.Step3.TermsInvalidShortMessage'));
                }
            } catch (exception) {
                LoggerHelper.logError(exception);
            }
        }

        // Validate the privacy link
        if (accountSetupRequestModel.privacyLink && accountSetupRequestModel.privacyLink.length > 0) {
            LoggerHelper.log(accountSetupRequestModel.privacyLink);
            try {
                if (!validateWebsiteRegexp.test(accountSetupRequestModel.privacyLink)) {
                    errors.push(this.translationService.GetTranslatedValue('Account.Step3.PrivacyInvalidShortMessage'));
                }
            } catch (exception) {
                LoggerHelper.logError(exception);
            }
        }

        // Validate business account
        if (accountSetupRequestModel.isBusinessAccount) {
            if (!accountSetupRequestModel.website || accountSetupRequestModel.website.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.Website'));
            }

            if (accountSetupRequestModel.countryId <= 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.CountryOfOperation'));
            }

            if (!accountSetupRequestModel.address || accountSetupRequestModel.address.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.Address'));
            }

            if (!accountSetupRequestModel.legalBusinessName || accountSetupRequestModel.legalBusinessName.trim().length == 0) {
                errors.push(this.translationService.GetTranslatedValue('Account.Step2.LegalBusinessName'));
            }
        }

        if (accountSetupRequestModel.learningSpaceTargetId == null || accountSetupRequestModel.learningSpaceTargetId <= 0) {
            errors.push(this.translationService.GetTranslatedValue('Account.Step5.LearningSpaceTarget'));
        }

        var errorMessage: string = '';
        if (errors.length > 0) {
            errorMessage = errors
                .filter(function(val) {
                    return val;
                })
                .join(', ');
            errorMessage = errorMessage + '.';
        } else {
            errorMessage = '';
        }

        return errorMessage;
    }
}
