import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslationService } from '../../../../../src/app/config/services/translation.service';

@Injectable({
    providedIn: 'root',
})
export class SnackBar {
    DURATION_TIME = 3000;
    isRTL: boolean;

    constructor(public translationService: TranslationService, public _snackBar: MatSnackBar, private _router: Router) {
        this.isRTL = this.translationService.isPlatformDirectionRtl();
    }

    /**
     * @name ShowWarning
     * @param message
     */
    ShowWarning(message: string) {
        this._snackBar.open(message, null, {
            duration: this.DURATION_TIME,
        });
    }

    /**
     * @name ShowWarningWithAction
     * @param message
     * @param buttonText
     */
    ShowWarningWithAction(message: string, buttonText: string): MatSnackBarRef<SimpleSnackBar> {
        return this._snackBar.open(message, buttonText, {
            duration: this.DURATION_TIME,
        });
    }

    /**
     * @name configureSnackbarWithAction
     * @param message
     * @param actionMessage
     * @param duration
     * @param navigationUrl
     */
    configureSnackbarWithAction(message: string, actionMessage: string, duration: number = 3000, navigationUrl: string) {
        const dialogConfig = new MatSnackBarConfig();
        if (this.isRTL) {
            dialogConfig.direction = 'rtl';
        } else {
            dialogConfig.direction = 'ltr';
        }
        dialogConfig.duration = duration;

        let snackBarRef = this._snackBar.open(message, actionMessage, dialogConfig);
        snackBarRef.onAction().subscribe(() => {
            this._router.navigate([navigationUrl]);
        });
    }

    /**
     * @name configureSnackbarWithActionListener
     * @param message
     * @param actionMessage
     * @param duration
     */
    configureSnackbarWithActionListener(message: string, actionMessage: string, duration: number = 3000): MatSnackBarRef<SimpleSnackBar> {
        const dialogConfig = new MatSnackBarConfig();
        if (this.isRTL) {
            dialogConfig.direction = 'rtl';
        } else {
            dialogConfig.direction = 'ltr';
        }
        dialogConfig.duration = duration;

        return this._snackBar.open(message, actionMessage, dialogConfig);
    }
}
