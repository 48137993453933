import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GlobalApiVariables {
  public readonly AugmentalIdentityApiServer = environment.identityApiServer;
  public readonly AugmentalBaseApiServer = environment.baseApiServer;
  public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;
  /**
   * @name ApiGetApiStatus
   * Api to get status of a running api
   */
  public readonly ApiGetApiStatus = this.AugmentalBaseApiServerBff + '/api/Tasks/{0}/Status';
  public readonly ApiGetApiStatusGateway = this.AugmentalBaseApiServer + '/api/Tasks/{0}/Status';

  public readonly GetSaltFromServer = this.AugmentalIdentityApiServer + '/api/Users/Salt';
  public readonly AuthenticateUser = this.AugmentalIdentityApiServer + '/api/v2/Sessions';
  public readonly AuthenticateUserSilently = this.AugmentalIdentityApiServer + '/api/Users/Session?code={0}';
  public readonly ApiLogout = this.AugmentalIdentityApiServer + '/api/Sessions/{0}';
  public readonly ApiGetAllInstitutions = this.AugmentalBaseApiServerBff + '/api/Instructors/{0}/Institutions';
  public readonly ApiUpdateUserProfile = this.AugmentalIdentityApiServer + '/api/Users/{0}/Profile';
  public readonly ApiUpdateUserProfileImage = this.AugmentalBaseApiServer + '/api/Users/{0}/UploadFiles';

  //start UserPreferences
  public readonly ApiGetUserPreferences = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Apps/{1}/Instructors/{2}/Preferences';
  public readonly ApiPostUserPreferences = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Apps/{1}/Instructors/{2}/Preferences';
  public readonly ApiUpdateUserPreferences = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Apps/{1}/Instructors/{2}/Preferences';
  //end UserPreferences

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------Country--------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Country

  /**
   * Get all currencies
   */
  public readonly ApiGetAllCurrencies = this.AugmentalBaseApiServer + '/api/Currencies';

  // #endregion Country

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------Institution preferences--------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Institution preferences

  /**
   * Get all institution preferences
   */
  public readonly ApiGetAllInstitutionPreferences = this.AugmentalBaseApiServer + '/api/Users/{0}/AvailableInstitutionPreferences/All()';

  /**
   * Put a selected institution preferences
   */
  public readonly ApiPutInstitutionPreferences = this.AugmentalBaseApiServer + '/api/SelectedInstitutionPreference';

  /**
   * Get institution providers
   */
  public readonly ApiGetInstitutionProviders = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Resources/InstitutionProviders';
  // #endregion Institution preferences

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------My courses--------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region MyCourses
  public readonly ApiGetAllMyCourses = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses';
  public readonly ApiGetCourseInfoBasedOnCourseId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}';
  public readonly ApiGetCurriculumsBasedOnInstitution = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums';
  public readonly ApiGetCategoriesBasedOnCurriculum = this.AugmentalBaseApiServer + '/api/Curriculums/{0}/Languages/{1}/Categories';
  public readonly ApiGetLanguagesBasedOnCurriculum = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums/{1}/CurriculumLanguages';
  public readonly ApiGetLanguagesBasedOnInstitutionCurriculum = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums/{1}/CurriculumLanguageInstitutions';
  public readonly ApiGetAllCourseStructure = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Structures';
  public readonly ApiGetCourseStructure = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Structures?level={1}';
  public readonly ApiCreateCourseStructure = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/Structures?level={2}';
  public readonly ApiGetStructureByCourseId = this.AugmentalBaseApiServer + '/api/Courses/{0}/CourseStructures';
  public readonly ApiVerifyCourseStructure = this.AugmentalBaseApiServer + '/api/Courses/{0}/CourseStructures/IsAssigned()';
  public readonly ApiUpdateCourseStructure = this.AugmentalBaseApiServer + '/api/Courses/{0}/CourseStructures';
  public readonly ApiPostCourseStructureSelection = this.AugmentalBaseApiServer + '/api/CourseStructures';
  public readonly ApiGetAllCourseGroups = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Languages/{3}/Grades';
  public readonly ApiCreateNewGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades';
  public readonly ApiPostCourseByInstructor = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses';
  public readonly ApiPutCourseGateway = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}';
  public readonly ApiPutCourseBff = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}';
  public readonly ApiUpdateCourseLearnerVisibility = this.AugmentalBaseApiServer + '/api/Courses/{0}/Learner/Visibility';
  public readonly ApiUpdateCoursePublisherVisibility = this.AugmentalBaseApiServer + '/api/Courses/{0}/Publisher/Visibility';
  public readonly ApiUpdateCourseTimer = this.AugmentalBaseApiServer + '/api/Courses/{0}/Timer';
  public readonly ApiUpdateCourseGenerativeAI = this.AugmentalBaseApiServer + '/api/Courses/{0}/Settings/GenerativeAI';
  public readonly ApiGetCourse = this.AugmentalBaseApiServerBff + '/api/Courses/{0}';
  public readonly ApiGetCourseInfo = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}/CourseInfo';
  public readonly ApiDeleteCourse = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}';

  public readonly ApiInstructorClassSharedLinkWithoutGroup = this.AugmentalBaseApiServer + '/api/Instructors/{0}/Classs/{1}/SharedLink';
  public readonly ApiInstructorClassSharedLinkWithGroup = this.AugmentalBaseApiServer + '/api/Instructors/{0}/Classs/{1}/SharedLink?groupId={2}';
  public readonly ApiGetProviderInstitutionCoursesByInstructor = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/ProviderInstitutionCourses?isArchived={2}';
  public readonly ApiGetClassInfo = this.AugmentalBaseApiServerBff + '/api/Classes/{0}/Course/Info';
  public readonly ApiDuplicateCourse = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/Duplicate()?publishedOnly={3}';
  public readonly ApiGetPreviewCourseInfo = this.AugmentalBaseApiServerBff + '/api/Classes/{0}/Course/Preview';
  public readonly ApiGetIfCourseHasLearningOutcomesAssigned = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/LearningOutcomes/Assigned/Any()';
  public readonly ApiGetIfCourseHasUsersAssigned = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/Grades/{2}/Class/Learners/Enrollments/Any()';

  public readonly ApiGetCertificateInfo = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/Certificates';
  public readonly ApiGetCertificatePreviewInfo = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/Certificates/Preview';
  public readonly ApiCreateCertificateInfo = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/Certificates';
  public readonly ApiGetAllCoursePricingPlan = this.AugmentalBaseApiServer + '/api/Courses/{0}/Plans?recentPlansOnly={1}';
  public readonly ApiCreateCoursePricingPlan = this.AugmentalBaseApiServer + '/api/Courses/{0}/Plans';
  public readonly ApiUpdateCoursePricingPlan = this.AugmentalBaseApiServer + '/api/Courses/{0}/Plans/{1}';
  //------------------------- learner ----------------------------------

  public readonly ApiGetProviderInstitutionCoursesByLearner = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Learners/{1}/ProviderInstitutionCourses?isArchived={2}';

  //------------------------- learner -----------------------------------

  // #endregion MyCourses

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------Course Summary------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Course Summary
  /**
   * Get all course summary list by course id
   */
  public readonly ApiGetAllCourseSummaryByCourseId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}/CourseSummary';

  /**
   * Get all course summary list by courseId and subcourseId
   */
  public readonly ApiGetAllCourseSummaryByCourseIdAndSubCourseId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}/CourseSummary?subCourseId={2}';

  /**
   * Create a subcourse
   */
  public readonly ApiCreateSubCourse = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/SubCourses';

  /**
   * Update subcourses order
   */
  public readonly ApiUpdateSubCourseOrder = this.AugmentalBaseApiServer + '/api/SubCoursesOrder';

  public readonly ApiValidateCourse = this.AugmentalBaseApiServerBff + '/api/Courses/{0}/SubCourses/{1}/Validation()?isCurrentLevelLastSubCourseLevel={2}';

  public readonly ApiUnpublishCourse = this.AugmentalBaseApiServer + '/api/CourseElement/{0}/UnPublish()?isNotionType=false';

  public readonly ApiPublishCourse = this.AugmentalBaseApiServer + '/api/CourseElement/{0}/Publish()?isNotionType=false';

  public readonly ApiGetLearnerCertificateHistory = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Courses/{3}/Learners/Certificates';

  public readonly ApiGetCourseDripLessonSettings = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/DripSchedules/Settings?groupId={2}';
  public readonly ApiUpdateCourseDripLessonSettings = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/DripSchedules/Settings?groupId={2}';
  public readonly ApiGetCourseDripLessonSchedule = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/SubCourses/DripSchedules?groupId={2}';
  public readonly ApiUpdateCourseDripLessonSchedule = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/SubCourses/DripSchedules?groupId={2}';

  // #endregion Course Summary

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  public readonly ApiGetSystemLanguages = this.AugmentalBaseApiServer + '/api/Institutions/{0}/System/Languages';

  public readonly ApiGetAllLanguagesByInstitutionAndCurriculums = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums/Languages';

  public readonly CourseCategoryUrl = this.AugmentalBaseApiServerBff + '/api/CourseCategories';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-----------------------Content studio------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
  // #region ContentBuilder
  /**
   * Get all notions for a specific course
   */
  public readonly ApiGetSectionBySectionId = this.AugmentalBaseApiServer + '/api/Notions/{0}';

  /**
   * Get all notions for a specific course
   */
  public readonly ApiGetAllNotions = this.AugmentalBaseApiServer + '/api/Courses/{0}/Notions?isRequestingPublishedNotionsOnly=false';

  /**
   * Create a new notion
   */
  public readonly ApiPostNewNotion = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/Notions';

  /**
   * Update a notion order
   */
  public readonly ApiUpdateNotionOrder = this.AugmentalBaseApiServer + '/api/Courses/{0}/Notions/{1}';

  /**
   * Update a notion
   */
  public readonly ApiUpdateNotion = this.AugmentalBaseApiServer + '/api/Notions/{0}';

  /**
   * Delete notion
   */
  public readonly ApiDeleteNotion = this.AugmentalBaseApiServer + '/api/Notions/{0}';

  /**
   * Get all notion elements for a specific notion
   */
  public readonly ApiGetAllNotionElements = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Notions/{1}/Contents';

  /**
   * Update a notion order
   */
  public readonly ApiUpdateNotionElementOrder = this.AugmentalBaseApiServer + '/api/Notions/{0}/Exercices/{1}';

  /**
   * Create a new notion element
   */
  public readonly ApiPostNewExerciseNotionElement = this.AugmentalBaseApiServer + '/api/Notions/{0}/Languages/{1}/Exercices';

  /**
   * Delete an exericse container
   */
  public readonly ApiDeleteExerciseNotionElement = this.AugmentalBaseApiServer + '/api/Exercices/{0}';

  /**
   * Delete a content item inside content container
   */
  public readonly ApiDeleteContentItemByContentId = this.AugmentalBaseApiServer + '/api/Contents/{0}';
  /**
   * Delete a question item inside exercise container
   */
  public readonly ApiDeleteQuestionItemByQuestionId = this.AugmentalBaseApiServer + '/api/ExerciseQuestions/{0}';

  /**
   * Get exercise question full details
   */
  public readonly ApiGetExerciseQuestionFullDetails = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}';

  /**
   * Get exercise question full details
   */
  public readonly ApiGetExerciseQuestionFullDetailsWithLearnerCourseTracking = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}?learnerId={1}&courseTrackingId={2}';

  /**
   * Get exercise question full details
   */
  public readonly ApiGetExerciseQuestionFullDetailsWithLearnerAssessmentTracking = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}?learnerId={1}&assessmentTrackingId={2}';

  /**
   * Get exercise question full details
   */
  public readonly ApiGetExerciseQuestionFullDetailsWithCourseTracking = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}?courseTrackingId={1}';

  /**
   * Get exercise question full details
   */
  public readonly ApiGetExerciseQuestionFullDetailsWithAssessmentTracking = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}?assessmentTrackingId={1}';

  /**
   * Edit Question Weight
   */
  public readonly ApiEditQuestionWeight = this.AugmentalBaseApiServer + '/api/ExerciseQuestions/{0}/Weight';

  /**
   * Get all course learning outcomes by notion id
   */
  public readonly GetAllCourseLearningOutcomesByNotionId = this.AugmentalBaseApiServer + '/api/Notions/{0}/NotionCourseLearningOutcomes';

  /**
   * Get course learning outcomes list based on exercise id
   */
  public readonly ApiGetCourseLearningOutcomesByExerciseId = this.AugmentalBaseApiServer + '/api/Exercices/{0}/ExerciceCourseLearningOutcomes';

  /**
   * Get all course learning outcomes by course id
   */
  public readonly GetAllCourseLearningOutcomesByCourseId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/CourseLearningOutcomesTree';

  /**
   * Create a sub course learning outcome for certain predifined course learning outcome
   */
  public readonly CreateSubCourseLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/CourseLearningOutcomes/{2}/SubCourseLearningOutcomes';

  /**
   * update a sub course learning outcome for certain predifined course learning outcome
   */
  public readonly UpdateSubCourseLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/CourseLearningOutcomes/{2}';

  /**
   * Delete course learning outcome
   */
  public readonly DeleteCourseLearningOutcome = this.AugmentalBaseApiServer + '/api/CourseLearningOutcomes/{0}';

  /**
   * Put all course learning outcomes by notion id
   */
  public readonly PutCourseLearningOutcomesByNotionId = this.AugmentalBaseApiServer + '/api/Notions/{0}/NotionCourseLearningOutcomes';

  /**
   * Put all course learning outcomes by exercise id
   */
  public readonly PutCourseLearningOutcomesByExerciseId = this.AugmentalBaseApiServer + '/api/Exercise/{0}/ExerciseCourseLearningOutcomes';

  /**
   * Update a content order
   */
  public readonly ApiUpdateContentLevel = this.AugmentalBaseApiServer + '/api/Contents/{0}/LevelType';

  /**
   * Update a content order
   */
  public readonly ApiUpdateContentOrder = this.AugmentalBaseApiServer + '/api/Contents/Order';

  /**
   * Update an exercise order
   */
  public readonly ApiUpdateExerciseOrder = this.AugmentalBaseApiServer + '/api/ExerciseQuestions/Order';

  /**
   * Update an exercise title
   */
  public readonly ApiUpdateExerciseTitle = this.AugmentalBaseApiServer + '/api/Languages/{0}/Exercise/{1}/ExerciseTitle';

  /**
   * Update an exercise level
   */
  public readonly ApiUpdateExerciseLevel = this.AugmentalBaseApiServer + '/api/Exercises/{0}/ExerciceQuestions/{1}/Level';

  /**
   * Get content details based on content id
   */
  public readonly ApiGetContentInfoByContentId = this.AugmentalBaseApiServerBff + '/api/Contents/{0}';

  /**
   * Post content info
   */
  public readonly ApiPostContentInfo = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Notions/{1}/Languages/{2}/Contents';

  /**
   * Post content info
   */
  public readonly ApiPostContentInfoByResource = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Resources/{1}/Languages/{2}/Contents';

  /**
   * Put content info
   */
  public readonly ApiPutContentInfo = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Languages/{1}/Contents/{2}';

  /**
   * Post content media files
   */
  public readonly ApiPostContentFiles = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/UploadFiles';

  /**
   * Post content media url files by course id
   */
  public readonly ApiUploadPdfUrlFilesByCourseId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/Url/UploadFiles';

  /**
   * Post content media url files by resource id
   */
  public readonly ApiUploadPdfUrlFilesByResourceId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Resources/{1}/Url/UploadFiles';

  /**
   * Post content media files by resource
   */
  public readonly ApiPostContentFilesByResource = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Resources/{1}/UploadFiles';

  /**
   * Post question media files
   */
  public readonly ApiPostQuestionFiles = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/UploadFiles';

  /**
   * Post question media files
   */
  public readonly ApiPostAnswerFiles = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/UploadFiles';

  /**
   * @name ApiGetSameAsQuestionListByQuestionId
   * Get same as question list based on question id
   */
  public readonly ApiGetSameAsQuestionListByQuestionId = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/SameAsExerciseQuestions';

  /**
   * @name ApiGetSameAsQuestionListByExerciseId
   * Get same as question list based on exercise id
   */
  public readonly ApiGetSameAsQuestionListByExerciseId = this.AugmentalBaseApiServerBff + '/api/Exercises/{0}/SameAsExerciseQuestions';

  /**
   * @name ApiGetExerciseQuestions
   * Get exercise question based on exercise id
   */
  public readonly ApiGetExerciseQuestions = this.AugmentalBaseApiServer + '/api/Exercices/{0}/ExerciceQuestions';

  /**
   * @name ApiGetTrueFalseQuestionInfo
   * Get true false questions + answers info
   */
  public readonly ApiGetTrueFalseQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/TrueOrFalseQuestions';

  /**
   * @name ApiGetMultipleChoiceQuestionInfo
   * Get multiple choice questions + answers info
   */
  public readonly ApiGetMultipleChoiceQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/MultipleChoiceQuestions';

  /**
   * @name ApiGetMultipleChoiceQuestionInfoForDiagnostic
   * Get multiple choice question + answers info for diagnotic test
   */
  public readonly ApiGetMultipleChoiceQuestionInfoForDiagnostic = this.AugmentalBaseApiServerBff + '/api/MultipleChoiceQuestions/{0}/Languages/{1}/MultipleChoiceQuestions';

  /**
   * @name ApiGetMultipleAnswerQuestionInfo
   * Get multiple answer questions + answers info
   */
  public readonly ApiGetMultipleAnswerQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/MultipleAnswerQuestions';

  /**
   * @name ApiGetMultipleAnswerQuestionInfoForDiagnosticTest
   * Get multiple answer questions + answers info for diagnostic test
   */
  public readonly ApiGetMultipleAnswerQuestionInfoForDiagnosticTest = this.AugmentalBaseApiServerBff + '/api/MultipleAnswerQuestions/{0}/Languages/{1}/MultipleAnswerQuestions';

  /**
   * @name ApiGetFillInTheBlankQuestionInfo
   * Get fill in the blank questions + answers info
   */
  public readonly ApiGetFillInTheBlankQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/FillTheBlankQuestions';

  /**
   * @name ApiGetOpenEndedQuestionInfo
   * Get open ended questions + answers info
   */
  public readonly ApiGetOpenEndedQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/OpenEndQuestions';

  /**
   * @name ApiGetClassificationQuestionInfo
   * Get classification questions + answers info
   */
  public readonly ApiGetClassificationQuestionInfo = this.AugmentalBaseApiServerBff + '/api/ExerciseQuestions/{0}/Languages/{1}/ClassificationQuestions';

  /**
   * Post true false question info
   */
  public readonly ApiPostTrueFalseQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/TrueOrFalseQuestions';

  /**
   * Put true false question info
   */
  public readonly ApiPutTrueFalseQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/TrueOrFalseQuestions';

  /**
   * Post multiple choice question info
   */
  public readonly ApiPostMultipleChoiceQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/MultipleChoiceQuestions';

  /**
   * Put multiple choice question info
   */
  public readonly ApiPutMultipleChoiceQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/MultipleChoiceQuestions';

  /**
   * Put multiple choice question info + answer for diagnostic test
   */
  public readonly ApiPutMultipleChoiceQuestionInfoForDiagnosticTest = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/DiagnosticTestQuestions/{1}/MultipleChoiceQuestions';

  /**
   * Put multiple answer question info + answer for diagnostic test
   */
  public readonly ApiPutMultipleAnswerQuestionInfoForDiagnosticTest = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/DiagnosticTestQuestions/{1}/MultipleAnswerQuestions';

  /**
   * Post multiple answer question info
   */
  public readonly ApiPostMultipleAnswerQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/MultipleAnswerQuestions';

  /**
   * Put multiple answer question info
   */
  public readonly ApiPutMultipleAnswerQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/MultipleAnswerQuestions';

  /**
   * Post classification question info
   */
  public readonly ApiPostClassificationQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/ClassificationQuestions';

  /**
   * Put classification question info
   */
  public readonly ApiPutClassificationQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/ClassificationQuestions';

  /**
   * Post fill in the blank question info
   */
  public readonly ApiPostFillTheBlankQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/FillTheBlankQuestions';

  /**
   * Put fill in the blank question info
   */
  public readonly ApiPutFillTheBlankQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/FillTheBlankQuestions';

  /**
   * Post open ended question info
   */
  public readonly ApiPostOpenEndedQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/Exercises/{1}/OpenEndQuestions';

  /**
   * Put open ended question info
   */
  public readonly ApiPutOpenEndedQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/ExerciseQuestions/{1}/OpenEndQuestions';

  /**
   * @name ApiGenerateContent
   * Api to auto generate content
   */
  public readonly ApiGenerateContent = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/SubCourses/{2}/Notions/{3}/Contents/Automated';

  /**
   * @name ApiGenerateQuestion
   * Api to auto generate question
   */
  public readonly ApiGenerateQuestion = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/SubCourses/{2}/Notions/{3}/Exercise/Questions/Automated?exerciseId={4}';

  // #endregion ContentBuilder

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------Diagnostic Test-----------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region DiagnosticTest
  /**
   * Get Diagnostic test with all questions related to this diagnostic
   */
  public readonly ApiGetDiagnosticTestWithAllQuestion = this.AugmentalBaseApiServerBff + '/api/subCourses/{0}/DiagnosticTestWtihQuestions';

  /**
   * Delete diagnostic test question
   */
  public readonly ApiDeleteDiagnosticTestQuestions = this.AugmentalBaseApiServer + '/api/DiagnosticTestQuestions/{0}';

  /**
   * Post diagnostic multiple choice question info
   */
  public readonly ApiPostDiagnosticMultipleChoiceQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/DiagnosticTests/{1}/MultipleChoiceQuestions';

  /**
   * Post diagnostic multiple answer question info
   */
  public readonly ApiPostDiagnosticMultipleAnswerQuestionInfo = this.AugmentalBaseApiServerBff + '/api/Languages/{0}/DiagnosticTests/{1}/MultipleAnswerQuestions';

  // #endregion DiagnosticTest

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------Preview course------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Preview course
  /**
   * Get preview course data
   */
  public readonly ApiGetLessonPreview = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/Preview()';

  /**
   * @name ApiGetTableOfContentsResources
   * Api to get the resources of the table of content
   */
  public readonly ApiGetTableOfContentsResources = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/TableOfContent'; //?selectedContentId={1}&selectedNotionId={2}&selectedExerciseQuestionId={3}

  // #endregion Preview course

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------OnBoarding----------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
  public readonly ApiPostOnBoarding = this.AugmentalBaseApiServer + '/api/OnBoardings?userId={0}&isContentStudioApp=true&isLearnerApp=false&isInstructorApp=false&isBotApp=false';

  public readonly ApiGetOnBoardingByUserId = this.AugmentalBaseApiServer + '/api/OnBoardings?userId={0}&isContentStudioApp=true';

  // #endregion OnBoarding

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------Manage Users------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Manage users

  public readonly ApiGetAllUsersByInstitutionId = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users/Enrolled';

  /**
   * Get all users in a sub institution
   */
  public readonly ApiGetAllUsersInSubInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/UserManagement/Users?subInstitutionId={1}';

  /**
   * Get institution user statistics overview
   */
  public readonly ApiGetUserStatisticsOverview = this.AugmentalBaseApiServer + '/api/Institutions/{0}/UserManagement/Overview?subInstitutionId={1}';

  /**
   * Get user info
   */
  public readonly ApiGetUserInfo = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users/{1}';

  /**
   * Get account roles
   */
  public readonly ApiGetAccountRoles = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Roles?subInstitutionId={1}';

  /**
   * Activate and deactivate user
   */
  public readonly ApiToActivateDeactivateUser = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users/Activation?isActivating={1}&isDeactivating={2}';

  /**
   * Check email valdiation by userid
   */
  public readonly ApiGetCheckUserValidationByUserId = this.AugmentalIdentityApiServer + '/api/Users/{0}/Email/{1}/CheckAvailability()';

  /**
   * Put user information
   */
  public readonly ApiPutUserInformation = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users/{1}';

  /**
   * Api get all genders
   */
  public readonly ApiGetAllGenders = this.AugmentalBaseApiServer + '/api/Genders';

  /**
   * Api get all countries
   */
  public readonly ApiGetAllCountries = this.AugmentalBaseApiServer + '/api/Countries';

  /**
   * Post user information
   */
  public readonly ApiPostUserInformation = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users';

  /**
   * Reset user password
   */
  public readonly ApiPatchResetUserPassword = this.AugmentalIdentityApiServer + '/api/Users/{0}/Password/Reset()';

  /**
   * Reset users password
   */
  public readonly ApiPatchResetUsersPassword = this.AugmentalIdentityApiServer + '/api/Users/Password/Reset()';

  /**
   * Notify users
   */
  public readonly ApiPostNotifyUser = this.AugmentalIdentityApiServer + '/api/ConfirmationCodes/Notify()';

  /**
   * Add existing user by email
   */
  public readonly ApiPostExistingUserByEmail = this.AugmentalIdentityApiServer + '/api/Institutions/{0}/Users/{1}/Enroll()';

  // #endregion Manage users

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-----------------------Forgot password------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * Notify users
   */
  public readonly ApiSendConfirmationCode = this.AugmentalIdentityApiServer + '/api/Users/{0}/ConfirmationCode/Resend()';

  /**
   * Notify authorized users
   */
  public readonly ApiSendConfirmationCodeToAuthorizedUser = this.AugmentalIdentityApiServer + '/api/AuthorizedUsers/{0}/ConfirmationCode/Resend()';

  public readonly ApiValidateConfirmationCode = this.AugmentalIdentityApiServer + '/api/Users/{0}/ConfirmationCode/{1}/Validate()';

  public readonly ApiValidateAuthorizedUsersConfirmationCode = this.AugmentalIdentityApiServer + '/api/AuthorizedUsers/{0}/ConfirmationCode/{1}/Validate()';

  public readonly ApiSetNewPassword = this.AugmentalIdentityApiServer + '/api/Users/{0}/Password/SetNewPassword()';

  // #endregion forgot password

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------Manage User Enrollment---------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // public readonly ApiCreateClassEnrollmentWithGroup =
  //   this.AugmentalBaseApiServer + '/api/UsersClassEnrollments?groupId={0}';

  public readonly ApiGetClassEnrollmentByInstitutionIdAndUserId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Users/{1}/ClassEnrollments';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------Manage User Enrollment---------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  public readonly ApiGetGroupsByInstitutionAndGrade = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Groups';

  // #Manage User Enrollment

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------------User----------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
  // #region User roles
  /**
   * Get user permissions
   */
  public readonly ApiGetUserPermissions = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Users/{1}/Permissions';
  // #endregion Manage users

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-----------------------------Report---------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  // #region Report
  /**
   * Get report subcourse
   */
  public readonly GetReportSubcourseDetail =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/RootCourses/{3}/GenerateReport()?numberOfHighLowToReturn={4}&percentageThreshold={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get report subcourse by group id
   */
  public readonly GetReportSubcourseDetailByGroupId =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/RootCourses/{3}/GenerateReport()?groupId={4}&numberOfHighLowToReturn={5}&percentageThreshold={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * Get report last subcourse
   */
  public readonly GetReportLastSubcourse =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/GenerateReport()?numberOfHighLowToReturn={4}&percentageThreshold={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get report lesson
   */
  public readonly GetReportLessonWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons?primaryOnly={4}&secondaryOnly={5}&allCourseSessions={6}';

  /**
   * Get report lesson
   */
  public readonly GetReportLessonWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons?groupId={4}&primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * Get report last subcourse
   */
  public readonly GetReportLastSubcourseWithGroupId =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/GenerateReport()?groupId={4}&numberOfHighLowToReturn={5}&percentageThreshold={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * Get report learning outcome
   */
  public readonly GetReportLearningOutcomeDetail =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/CourseLearningOutcomes/GenerateReport()?primaryOnly={4}&secondaryOnly={5}&allCourseSessions={6}&loadLearnerOverview=true';

  /**
   * Get report learning outcome by group id
   */
  public readonly GetReportLearningOutcomeDetailByGroupId =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/CourseLearningOutcomes/GenerateReport()?groupId={4}&primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}&loadLearnerOverview=true';

  /**
   * Get report learning outcome for learner overview
   */
  public readonly GetReportLearningOutcomeForLearnerOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ClassOverview/GenerateReport()?groupId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get report learning outcome for learner overview
   */
  public readonly GetReportLearningOutcomeForLearnerOverviewWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ClassOverview/GenerateReport()?primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * Get report content learning outcome for learner overview
   */
  public readonly GetReportContentLearningOutcomeForLearnerOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ContentsOverview/GenerateReport()?courseLearningOutcomeId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * Get report content learning outcome for learner overview
   */
  public readonly GetReportContentLearningOutcomeForLearnerOverviewWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ContentsOverview/GenerateReport()?courseLearningOutcomeId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  public readonly GetReportQuestionLearningOutcomeForLearnerOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/QuestionsOverview/GenerateReport()?courseLearningOutcomeId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  public readonly GetReportQuestionLearningOutcomeForLearnerOverviewWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/QuestionsOverview/GenerateReport()?courseLearningOutcomeId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  public readonly GetClassInformationOverviewForLearnerOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ClassInformationOverview/GenerateReport()?courseLearningOutcomeId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  public readonly GetClassInformationOverviewForLearnerOverviewWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/ClassInformationOverview/GenerateReport()?courseLearningOutcomeId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name ApiGetUserClassOverview
   * Api to get user class overview
   */
  public readonly ApiGetUserClassOverview =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Grades/{4}/LiveBoard/ClassOverView()?primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * @name ApiGetUserClassOverviewWithGroupId
   * Api to get user class overview with group id
   */
  public readonly ApiGetUserClassOverviewWithGroupId =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Grades/{4}/LiveBoard/ClassOverView()?groupId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Get course learning outcomes for report details
   */
  public readonly GetCourseLearningOutcomesForReportDetails = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/DetailedReport/CourseLearningOutcomes';

  /**
   * Generate course learning outcome report with group
   */
  public readonly GenerateCourseLearningOutcomeReportWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/CourseLearningOutcomes/{4}/GenerateReport()?groupId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * Generate course learning outcome report
   */
  public readonly GenerateCourseLearningOutcomeReportWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/CourseLearningOutcomes/{4}/GenerateReport()?primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * Get report for live board
   */
  public readonly ApiGetReportForLiveBoard = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Learners/{3}/CourseLearningOutcomes/GenerateReportForLiveBoard()';

  /**
   * Get report for live board
   */
  public readonly ApiGetReportForLiveBoardByGroupId =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Learners/{3}/CourseLearningOutcomes/GenerateReportForLiveBoard()?groupId={4}';

  /**
   * Get report header information
   */
  public readonly GetReportHeaderInformation = this.AugmentalBaseApiServerBff + '/api/SubCourses/{0}/ParentTree';

  public readonly GetCoursesDetailsForReportMainPage = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/CoursesDetails';

  public readonly GetLearnersDetailsForReportMainPage = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/LearnersDetails';

  public readonly GetCourseElements = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/ReportingCourseElements()';

  public readonly GetSubCoursesByCourseId = this.AugmentalBaseApiServer + '/api/Courses/{0}/SubCourses';
  public readonly GetSubCoursesById = this.AugmentalBaseApiServer + '/api/SubCourses/{0}';

  public readonly GetCourseOverviewWithoutGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/Overview';
  public readonly GetCourseOverviewWithGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/Overview?groupId={3}';

  public readonly GetReportDiagnosticTestClassInformationWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestClassInformation/GenerateReport()?groupId={4}';

  public readonly GetReportDiagnosticTestClassInformationWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestClassInformation/GenerateReport()';

  public readonly GetGenerateReportForLearningOutcomesWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/GenerateReportForLearningOutcomes()';

  public readonly GetGenerateReportForLearningOutcomesWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/GenerateReportForLearningOutcomes()?groupId={4}';

  public readonly GetDiagnosticTestStatisticsQuestionWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/Questions';

  public readonly GetDiagnosticTestStatisticsQuestionWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/Questions?groupId={4}';

  public readonly GetDiagnosticTestQuestionWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestQuestions/{4}/DiagnosticTestStatistics/GenerateReport()?groupId={5}';

  public readonly GetDiagnosticTestQuestionWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestQuestions/{4}/DiagnosticTestStatistics/GenerateReport()';

  public readonly GetDiagnosticTestLeanerWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/DiagnosticTestStatistics/GenerateReport()';

  public readonly GetDiagnosticTestLeanerWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/Learners/{4}/DiagnosticTestStatistics/GenerateReport()?groupId={5}';

  public readonly GetDiagnosticTestStatisticsLearnerWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/Learners?groupId={4}';

  public readonly GetDiagnosticTestStatisticsLearnerWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Instructors/{3}/DiagnosticTestStatistics/Learners';

  public readonly GetReportLearnerHistoryWithGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Learners/{3}/GeneratedReports()?groupId={4}';

  public readonly GetReportLearnerHistoryWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Learners/{3}/GeneratedReports()';

  public readonly GetLearnerReportWithGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Courses/{3}/Learners/{4}/Report?startDate={5}&endDate={6}&groupId={7}';

  public readonly GetLearnerReportWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Courses/{3}/Learners/{4}/Report?startDate={5}&endDate={6}';

  public readonly GetLearnersForLearnerReportCard = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/LearnerCardReport/Learners';

  public readonly GetLearnerCoursesForLearnerReportCard = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Learners/{2}/LearnerCardReport/Courses';

  public readonly GetLearnersByGradeGroupWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Learners?groupId={3}&primaryOnly={4}&secondaryOnly={5}&allCourseSessions={6}';

  public readonly GetLearnersByGradeGroupWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Learners?primaryOnly={3}&secondaryOnly={4}&allCourseSessions={5}';

  public readonly GetLearnerProgressPercentageWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Learners/{3}/ProgressPercentages?groupId={4}&numberOfHighLowToReturn=5&percentageThreshold=50&primaryOnly=false&secondaryOnly=false&allCourseSessions=true';

  public readonly GetLearnerProgressPercentageWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Learners/{3}/ProgressPercentages?numberOfHighLowToReturn=5&percentageThreshold=50&primaryOnly=false&secondaryOnly=false&allCourseSessions=true';

  /**
   * @name GetContentReportOverview
   */
  public readonly GetContentReportOverview = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Contents/Analytics/Overview?primaryOnly={1}&secondaryOnly={2}&allCourseSessions={3}';

  /**
   * @name GetQuestionReportOverview
   */
  public readonly GetQuestionReportOverview = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Questions/Analytics/Overview?primaryOnly={1}&secondaryOnly={2}&allCourseSessions={3}';

  /**
   * @name GetQuestionReportDetailsWithGroup
   */
  public readonly GetQuestionReportDetailsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/Details?lessonId={4}&groupId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetQuestionReportDetailsWithoutGroup
   */
  public readonly GetQuestionReportDetailsWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/Details?lessonId={4}&primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * @name GetReportResourceDetails
   */
  public readonly GetReportResourceDetailsWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Details?lessonId={4}&primaryOnly={5}&secondaryOnly={6}&allCourseSessions={7}';

  /**
   * @name GetReportResourceDetails
   */
  public readonly GetReportResourceDetailsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Details?lessonId={4}&groupId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetNotionsBySubCourse
   * get notions by subcourse
   */
  public readonly GetNotionsBySubCourse = this.AugmentalBaseApiServer + '/api/Courses/{0}/Notions';

  /**
   * @name GetResourceReportSubCourseOverview
   * get resource report sub course overview
   */
  public readonly GetResourceReportSubCourseOverview = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/SubCourses/{2}/Overview';

  /**
   * @name GetResourceReportInsightsWithGroup
   * get resource report insights
   */
  public readonly GetResourceReportInsightsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/{4}/Insights?lessonId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetQuestionResourceReportInsightsWithGroup
   * get question resource report insights
   */
  public readonly GetQuestionResourceReportInsightsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/{4}/Insights?lessonId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetQuestionResourceReportInsightsWithoutGroup
   * get question resource report insights
   */
  public readonly GetQuestionResourceReportInsightsWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/{4}/Insights?lessonId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetResourceReportInsightsWithoutGroup
   * get resource report insights
   */
  public readonly GetResourceReportInsightsWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/{4}/Insights?lessonId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetContentAnalyticsOverview
   * get content analytics overview
   */
  public readonly GetContentAnalyticsOverview =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Lessons/Resources/{2}/Overview?lessonId={3}&primaryOnly={4}&secondaryOnly={5}&allCourseSessions={6}';

  /**
   * @name GetQuestionAnalyticsOverview
   * get question analytics overview
   */
  public readonly GetQuestionAnalyticsOverview =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Lessons/Resources/Questions/{2}/Overview?lessonId={3}&primaryOnly={4}&secondaryOnly={5}&allCourseSessions={6}';

  /**
   * @name GetContentResourceOverviewWithGroup
   * get content resource overview
   */
  public readonly GetContentResourceOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/{4}/Overview?lessonId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetContentResourceOverviewWithoutGroup
   * get content resource overview
   */
  public readonly GetContentResourceOverviewWithoutGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/{4}/Overview?lessonId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetQuestionResourceOverviewWithGroup
   * get question resource overview
   */
  public readonly GetQuestionResourceOverviewWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/{4}/Overview?lessonId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetQuestionResourceOverviewWithoutGroup
   * get question resource overview
   */
  public readonly GetQuestionResourceOverviewWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/Resources/Questions/{4}/Overview?lessonId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetReportAvailableContentsWithGroup
   * get available contents
   */
  public readonly GetReportAvailableContentsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/AvailableContents?lessonId={4}&selectedContentId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetReportAvailableContentsWithoutGroup
   * get available contents
   */
  public readonly GetReportAvailableContentsWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/AvailableContents?lessonId={4}&selectedContentId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetReportAvailableQuestionsWithoutGroup
   * get available questions
   */
  public readonly GetReportAvailableQuestionsWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/AvailableQuestions?lessonId={4}&selectedQuestionId={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  /**
   * @name GetReportAvailableQuestionsWithGroup
   * get available questions
   */
  public readonly GetReportAvailableQuestionsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/SubCourses/{3}/Lessons/AvailableQuestions?lessonId={4}&selectedQuestionId={5}&groupId={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetUserProgressPercentageDetailsWithGroup
   * Get user progress percentage details
   */
  public readonly GetUserProgressPercentageDetailsWithGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/RootCourses/{3}/Learners/ProgressPercentages?groupId={4}&numberOfHighLowToReturn={5}&percentageThreshold={6}&primaryOnly={7}&secondaryOnly={8}&allCourseSessions={9}';

  /**
   * @name GetUserProgressPercentageDetailsWithoutGroup
   * Get user progress percentage details
   */
  public readonly GetUserProgressPercentageDetailsWithoutGroup =
    this.AugmentalBaseApiServer +
    '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/RootCourses/{3}/Learners/ProgressPercentages?numberOfHighLowToReturn={4}&percentageThreshold={5}&primaryOnly={6}&secondaryOnly={7}&allCourseSessions={8}';

  // #endregion Report

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //-------------------------live session-------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  public readonly ApiGetLiveSessions = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/LiveSessions?inProgressRangeMinutes={2}&todayOnly={3}&upcomingOnly={4}&rangeOfDays={5}';

  public readonly ApiGetGroupsByInstitutionGradeCourse = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Courses/{1}/Grades/{2}/Groups?userId={3}';

  public readonly ApiCreateLiveSession = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/LiveSessions';

  public readonly ApiUpdateLiveSession =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/LiveSessions/{2}?updateCurrentAndUpcomingSchedulesToo={3}&updateSingleScheduleOnly={4}';

  public readonly ApiUpdateLiveSessionWithRootSessionId =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/LiveSessions/{2}?updateCurrentAndUpcomingSchedulesToo={3}&updateSingleScheduleOnly={4}&liveSessionRootId={5}';

  public readonly ApiGetLiveSessionById = this.AugmentalBaseApiServerBff + '/api/LiveSessions/{0}?editOnlyThisSession={1}';

  public readonly ApiGetLiveSessionByIdWithRootSession = this.AugmentalBaseApiServerBff + '/api/LiveSessions/{0}?editOnlyThisSession={1}&liveSessionRootId={2}';

  public readonly ApiDeleteLiveSession = this.AugmentalBaseApiServer + '/api/MeetingSessions/{0}?deleteSelectedSessionToo=true&deleteSelectedSessionOnlyWithoutChild={1}&isModerator=true';

  public readonly ApiEndLiveSession = this.AugmentalBaseApiServer + '/api/MeetingSessions/{0}/EndMeeting()?isModerator=true';

  public readonly ApiJoinLiveSession = this.AugmentalBaseApiServer + '/api/MeetingSessions/{0}/JoinMeetingLink?isModerator=true&isAttendee=false';

  public readonly ApiCheckIfMeetingSessionEnrollmentIsAvailableWithoutSessionIdAndDuration =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Moderators/{1}/MeetingEnrollments/IsAvailable?scheduleDateTime={2}';

  public readonly ApiCheckIfMeetingSessionEnrollmentIsAvailableWithSessionIdAndWithoutDuration =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Moderators/{1}/MeetingEnrollments/IsAvailable?scheduleDateTime={2}&meetingSessionId={3}';

  public readonly ApiCheckIfMeetingSessionEnrollmentIsAvailableWithoutSessionIdAndWithDuration =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Moderators/{1}/MeetingEnrollments/IsAvailable?scheduleDateTime={2}&duration={3}';

  public readonly ApiCheckIfMeetingSessionEnrollmentIsAvailableWithSessionIdAndWithDuration =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Moderators/{1}/MeetingEnrollments/IsAvailable?scheduleDateTime={2}&meetingSessionId={3}&duration={4}';

  public readonly ApiGenerateLiveSessionShareLink = this.AugmentalBaseApiServer + '/api/Instructors/{0}/MeetingSessions/{1}/SharedLink';

  public readonly ApiGetRecordedSession = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/LiveSessionRecordings';

  public readonly ApiPublishRecordedSession = this.AugmentalBaseApiServer + '/api/Instructors/{0}/MeetingSessionRecordings?meetingSessionRecordingId={1}&isPublishingRecording={2}';

  public readonly ApiDeleteRecordedSession = this.AugmentalBaseApiServer + '/api/MeetingSessionRecordings/{0}';

  public readonly ApiGetRecurrentSession = this.AugmentalBaseApiServerBff + '/api/RecurrentSessions/{0}/SelectedAndUpcomingRecurrentSessions?inProgressRangeMinutes={1}';

  public readonly ApiCheckRecurrentLiveSessionAvailabilityOnCreate = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Moderators/{1}/Schedules/AreAvailable';

  public readonly ApiCheckRecurrentLiveSessionAvailabilityOnCreateWithRootSessionId =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Moderators/{1}/Schedules/AreAvailable&livesessionRootId={2}';

  public readonly ApiCheckRecurrentLiveSessionAvailabilityOnUpdate = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Moderators/{1}/Schedules/AreAvailable?meetingSessionId={2}';

  public readonly ApiCheckRecurrentLiveSessionAvailabilityOnUpdateWithRootSessionId =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Moderators/{1}/Schedules/AreAvailable?meetingSessionId={2}&livesessionRootId={3}';

  //end live session

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------- Quiz -------------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetAvailableQuizzesWithoutGroup
   * api to retrieve all available quizzes without filtering by group
   */
  public readonly ApiGetAvailableQuizzesWithoutGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/Quizzes?isPublished={3}&isDraft={4}&isArchived={5}';

  /**
   * @name ApiGetAvailableQuizzesWithGroup
   * api to retrieve all avaialble quizzes and filter them by group
   */
  public readonly ApiGetAvailableQuizzesWithGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/Quizzes?groupId={3}&isPublished={4}&isDraft={5}&isArchived={6}';

  /**
   * @name ApiCreateQuiz
   * api to create a quiz
   */
  public readonly ApiCreateQuiz = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}/Instructors/{2}/Quizzes';

  /**
   * @name ApiCreateQuiz
   * api to create a quiz
   */
  public readonly ApiDuplicateQuiz = this.AugmentalBaseApiServer + '/api/Courses/{0}/Exams/{1}/Duplicate()';

  /**
   * @name ApiGetQuiz
   * api to get a quiz
   */
  public readonly ApiGetQuiz = this.AugmentalBaseApiServer + '/api/Quizzes/{0}';

  /**
   * @name ApiUpdateQuiz
   * api to update a quiz
   */
  public readonly ApiUpdateQuiz = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Courses/{1}/Instructors/{2}/Quizzes/{3}';

  /**
   * @name ApiGetQuizDetails
   * api to get quiz details
   */
  public readonly ApiGetQuizDetails = this.AugmentalBaseApiServerBff + '/api/Quizzes/{0}/QuizDetail()';

  /**
   * @name ApiGetQuizClassInformation
   * api to get quiz class information statstics
   */
  public readonly ApiGetQuizClassInformation = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/ClassInformation';

  /**
   * @name ApiGetQuizInfo
   * api to get quiz info
   */
  public readonly ApiGetQuizInfo = this.AugmentalBaseApiServerBff + '/api/Quizzes/{0}/Info';

  /**
   * Delete a quiz exercise
   */
  public readonly ApiDeleteQuizExercise = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Exercices/{1}';

  /**
   * Create a quiz exercise
   */
  public readonly ApiCreateAssessmentExercise = this.AugmentalBaseApiServerBff + '/api/Quizzes/{0}/QuizExercices';

  /**
   * Make a quiz draft
   */
  public readonly ApiMarkQuizAsDraft = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Status/Draft';

  /**
   * Make a quiz archived
   */
  public readonly ApiMarkQuizAsArchived = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Status/Archived';

  /**
   * @name ApiGetExerciseQuestionByQuizId
   * api to get exercise question by quiz id
   */
  public readonly ApiGetExerciseQuestionByQuizId = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Questions';

  /**
   * @name ApiGetQuizResultByQuestion
   * api to get quiz result by question
   */
  public readonly ApiGetQuizResultByQuestion = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/ExerciseQuestions/{1}/GenerateReport()';

  /**
   * @name ApiGetLearnersWithQuizSessionByQuizId
   * api to get learners with their quiz sessions by quiz Id
   */
  public readonly ApiGetLearnersWithQuizSessionByQuizId = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Learners';

  /**
   * @name ApiGetQuizResultByUser
   * api to get learners with their quiz sessions by quiz Id
   */
  public readonly ApiGetQuizResultByUser = this.AugmentalBaseApiServer + '/api/Quizzes/{0}/Learners/{1}/UserAnswers/GenerateReport()';

  //end quiz

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //----------------- Question Correction ------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetQuestionCorrectionDetailsWithGroup
   * Api to get question correction
   */
  public readonly ApiGetQuestionCorrectionDetailsWithGroup =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/QuestionCorrections?groupId={3}&isPending={4}&isCorrected={5}';

  /**
   * @name ApiGetQuestionCorrectionDetailsWithoutGroup
   * Api to get question correction
   */
  public readonly ApiGetQuestionCorrectionDetailsWithoutGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/QuestionCorrections?isPending={3}&isCorrected={4}';

  /**
   * @name ApiGetQuestionCorrectionSummaryWithGroup
   * Api to get question correction summary
   */
  public readonly ApiGetQuestionCorrectionSummaryWithGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Exercises/{1}/ExerciseQuestions/{2}/ExerciseCorrectionSummaryCases?groupId={3}';

  /**
   * @name ApiGetQuestionCorrectionSummaryWithoutGroup
   * Api to get question correction summary
   */
  public readonly ApiGetQuestionCorrectionSummaryWithoutGroup = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Exercises/{1}/ExerciseQuestions/{2}/ExerciseCorrectionSummaryCases';

  /**
   * @name ApiGetQuestionCorrectionResult
   * Api to get question correction result
   */
  public readonly ApiGetQuestionCorrectionResult = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/ExerciseQuestions/{2}/GenerateReport()?onlyArchivedRecords=false';

  /**
   * @name ApiCorrectOpenEndedPendingAnswers
   * Api to correct open ended pending answers
   */
  public readonly ApiCorrectOpenEndedPendingAnswers = this.AugmentalBaseApiServer + '/api/PendingCorrections/Correct()';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------- Content library ------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  public readonly ApiGetCategoriesByInstitution = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Languages/{1}/Categories';

  public readonly ApiGetGradesByInstitutionAndLanguage = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Languages/{1}/Grades';

  public readonly ApiGetAllMultimediaType = this.AugmentalBaseApiServer + '/api/MultimediaTypes';

  public readonly ApiGetResourceContentTypes = this.AugmentalBaseApiServer + '/api/Languages/{0}/ResourceContentTypes';

  public readonly ApiGetAllLevelTypes = this.AugmentalBaseApiServer + '/api/LevelTypes';
  public readonly ApiGetSubcourseLevelTypes = this.AugmentalBaseApiServer + '/api/SubCourses/{0}/LevelTypes/Available';
  public readonly ApiGetResourceLevelTypes = this.AugmentalBaseApiServer + '/api/Languages/{0}/ResourceLevelTypes';

  public readonly ApiGetResourcesByUserId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Users/{1}/Resources?pageSize={2}&pageNumber={3}';

  public readonly ApiGetUsedAndAvailableResources = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Notions/{2}/UsedAndAvailableResources?pageSize={3}&pageNumber={4}';

  public readonly ApiGetInstitutionResourceSharingLevel = this.AugmentalBaseApiServer + '/api/Institutions/{0}/InstitutionResourceSharingLevels';

  public readonly ApiGetAllAgeRange = this.AugmentalBaseApiServer + '/Institutions/{0}/System/AgeRanges';

  public readonly ApiGetAllLicenses = this.AugmentalBaseApiServer + '/api/Licenses';

  public readonly ApiCreateResource = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Resources';

  public readonly ApiUpdateResource = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Resources?idEncrypted={1}';

  public readonly ApiGetResourceFullDetails = this.AugmentalBaseApiServerBff + '/api/Resources/Details?idEncrypted={0}&userId={1}';

  public readonly ApiCheckResourceIfUsed = this.AugmentalBaseApiServer + '/api/Notions/{0}/Resources/Used?idEncrypted={1}';

  public readonly ApiCheckIfResourceContentCanBeAdded =
    this.AugmentalBaseApiServer + '/api/Notions/{0}/Contents/Resources/CanAdd()?idEncrypted={1}&isBeginnerLevel={2}&isIntermediateLevel={3}&isAdvancedLevel={4}';

  public readonly ApiUseResource =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Notions/{2}/Resources?idEncrypted={3}&isBeginnerLevel={4}&isIntermediateLevel={5}&isAdvancedLevel={6}';

  public readonly ApiPublishResource = this.AugmentalBaseApiServer + '/api/Resources/Publish()?idEncrypted={0}';

  public readonly ApiGetResourceConfiguration = this.AugmentalBaseApiServerBff + '/api/Resources/Configurations?idEncrypted={0}';

  public readonly ApiGetContentByResourceId = this.AugmentalBaseApiServer + '/api/Resources/Content?idEncrypted={0}';

  public readonly ApiGetPublisherByInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Publishers';

  public readonly ApiGetResourcePublisherId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Resources/ConsumerPublishers';

  public readonly ApiGetResourceCategoriesId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Languages/{1}/Resources/ConsumerCategories';

  public readonly ApiAssignTagsToResource = this.AugmentalBaseApiServer + '/api/Resources/{0}/Tags';

  public readonly ApiRateAResource = this.AugmentalBaseApiServer + '/api/Resources/{0}/Users/{1}/Rating';

  public readonly ApiGetResourceUserRatingDetails = this.AugmentalBaseApiServerBff + '/api/Resources/Users/{0}/Rating?idEncrypted={1}';

  public readonly ApiDeleteResource = this.AugmentalBaseApiServer + '/api/Resources/{0}';

  public readonly ApiCheckIfResourceCanBeDeleted = this.AugmentalBaseApiServer + '/api/Resources/{0}/CanBeDeleted';

  public readonly ApiSetResourceAsFavorite = this.AugmentalBaseApiServer + '/api/Resources/{0}/Users/{1}/Favorite/SetOrUnset()';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //------------------------- Liveboard --------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetClassesByUserId
   * Api to get classes by user id
   */
  public readonly ApiGetClassesByUserIdAndInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Classes';

  /**
   * @name ApiGetLiveBoardLearnersLatestActivitiesWithGroup
   * Api to get live board learners latest activities for certain group
   */
  public readonly ApiGetLiveBoardLearnersLatestActivitiesWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Classes/{4}/Grades/{5}/LiveBoard/LearnersLatestActivities()?groupId={6}';

  /**
   * @name ApiGetLiveBoardLearnersLatestActivitiesWithGroup
   * Api to get live board learners latest activities
   */
  public readonly ApiGetLiveBoardLearnersLatestActivities =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Classes/{4}/Grades/{5}/LiveBoard/LearnersLatestActivities()';

  /**
   * @name ApiGetLiveBoardClassOverview
   * Api to get live board class overview
   */
  public readonly ApiGetLiveBoardClassOverview = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Classes/{4}/Grades/{5}/LiveBoard/ClassOverView()';

  /**
   * @name ApiGetLiveBoardClassOverviewWithGroup
   * Api to get live board class overview with group
   */
  public readonly ApiGetLiveBoardClassOverviewWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/{3}/Classes/{4}/Grades/{5}/LiveBoard/ClassOverView()?groupId={6}';

  /**
   * @name ApiGetContentHistory
   * Api to get live board content history
   */
  public readonly ApiGetContentHistory = this.AugmentalBaseApiServerBff + '/api/Usersessions/{0}/Contenthistory?learnerId={1}&isOfflineUser={2}';

  /**
   * @name ApiGetContentHistoryForReporting
   * Api to get live board content history for reporting
   */
  public readonly ApiGetContentHistoryForReporting = this.AugmentalBaseApiServerBff + '/api/Usersessions/{0}/Contenthistory?learnerId={1}&isOfflineUser={2}&isCourseHistoryReportWidget={3}';

  /**
   * @name ApiGetLastQuestionStuckAt
   * Api to get live board question stuck at
   */
  public readonly ApiGetLastQuestionStuckAt = this.AugmentalBaseApiServerBff + '/api/Usersessions/{0}/Contenthistory/LastQuestionStuckAt?learnerId={1}&isOfflineUser={2}';

  /**
   * @name ApiGetLiveBoardLastContent
   * Api to get live board last content
   */
  public readonly ApiGetLiveBoardLastContent = this.AugmentalBaseApiServerBff + '/api/UserSessions/{0}/ContentHistory/LastContent?learnerId={1}&isOfflineUser={2}';

  /**
   * @name ApiHelpLearner
   * Api to help learner
   */
  public readonly ApiHelpLearner = this.AugmentalBaseApiServer + '/api/UserTrackings/{0}/HelpLearner()';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------- learning outcome -----------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * Get curriculum info by institution id
   */
  public readonly ApiGetCurriculumInformation = this.AugmentalBaseApiServer + '/api/Curricula/{0}?$select=id,name';

  /**
   * Get all curricula by institution id
   */
  public readonly ApiGetAllCurriculaByInstitutionId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula';

  /**
   * Create a curriculum by institution id
   */
  public readonly ApiCreateCurriculumByInstitutionId = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula';

  /**
   * Update a curriculum
   */
  public readonly ApiUpdateCurriculum = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}';

  /**
   * Delete a curriculum
   */
  public readonly ApiDeleteCurriculum = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}';

  /**
   * Update linked curricula
   */
  public readonly ApiUpdateLinkedCurricula = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/Link?isLinking={1}&isUnlinking={2}';

  /**
   * Get available curricula in a certain institution
   */
  public readonly ApiGetAvailableCurricula = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula/Unlinked';

  /**
   * Get subject info by institution id
   */
  public readonly ApiGetSubjectInformation = this.AugmentalBaseApiServer + '/api/Categories/{0}?$select=id,description,icon,languageId';

  /**
   * Get all subject by curriculum id
   */
  public readonly ApiGetAllSubjectsByCurriculumId = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula/{1}/Categories';

  /**
   * Create a subject
   */
  public readonly ApiCreateSubject = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories';

  /**
   * Update a subject
   */
  public readonly ApiUpdateSubject = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}';

  /**
   * Delete a subject
   */
  public readonly ApiDeleteSubject = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}';

  /**
   * Update linked subjects
   */
  public readonly ApiUpdateLinkedSubjects = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/Link?isLinking={2}&isUnlinking={3}';

  /**
   * Get available subjects in a certain institution
   */
  public readonly ApiGetAvailableSubjects = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula/{1}/Categories/Unlinked';

  /**
   * Get classes in a category
   */
  public readonly ApiGetAllClassesByCurriculumAndSubject = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades';

  /**
   * Create a class
   */
  public readonly ApiCreateClass = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades';

  /**
   * Update a class
   */
  public readonly ApiUpdateClass = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades/{3}';

  /**
   * Delete a class
   */
  public readonly ApiDeleteClass = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades/{3}';

  /**
   * Update linked classes
   */
  public readonly ApiUpdateLinkedClasses = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades/Link?isLinking={3}&isUnlinking={4}';

  /**
   * Get available classes in a certain institution
   */
  public readonly ApiGetAvailableClasses = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/Grades/Unlinked';

  /**
   * @name ApiGetInstructorEnrolledCategories
   * Api to get instructor enrolled categories
   */
  public readonly ApiGetInstructorEnrolledCategories = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/EnrolledCategories';

  /**
   * @name ApiGetImportedLearningOutcome
   * Api to get imported learning outcome
   */
  public readonly ApiGetImportedLearningOutcome = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/LearningOutcomes/Imported';

  /**
   * @name ApiCheckIfASubjectHasLearningOutcomes
   * Api to check if a subject has learning outcomes
   */
  public readonly ApiCheckIfASubjectHasLearningOutcomes = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curricula/{1}/Categories/{2}/LearningOutcomes/Any()?gradeId={3}';

  /**
   * @name ApiGetGradesForImportedLearningOutcomes
   * Api get grades for imported learning outcome
   */
  public readonly ApiGetGradesForImportedLearningOutcomes = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Languages/{1}/Curriculums/{2}/Categories/{3}/ImportedLearningOutcomes/Grades';

  /**
   * @name ApiCreateCustomLearningOutcome
   * Api to create custom learning outcome
   */
  public readonly ApiCreateCustomLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Curriculums/{3}/EnrolledCategories/{4}/CustomLearningOutcomes';

  /**
   * @name ApiDeleteCustomLearningOutcome
   * Api to delete custom learning outcome
   */
  public readonly ApiDeleteCustomLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Curriculums/{3}/EnrolledCategories/{4}/CustomLearningOutcomes/{5}';

  /**
   * @name ApiGetMissingStandardLearningOutcomes
   * Api to get missing standard learning outcome without grade
   */
  public readonly ApiGetMissingStandardLearningOutcomesWithoutGrade =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/MissingStandardLearningOutcomes';

  /**
   * @name ApiGetMissingStandardLearningOutcomes
   * Api to get missing standard learning outcome with grade
   */
  public readonly ApiGetMissingStandardLearningOutcomesWithGrade =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/MissingStandardLearningOutcomes?gradeId={4}';

  /**
   * @name ApiImportStandardLearningOutcomeWithoutGrade
   * Api to import standard learning outcome without grade
   */
  public readonly ApiImportStandardLearningOutcomeWithoutGrade =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/StandardLearningOutcomes/Import()';

  /**
   * @name ApiImportStandardLearningOutcomeWithGrade
   * Api to import standard learning outcome with grade
   */
  public readonly ApiImportStandardLearningOutcomeWithGrade =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/StandardLearningOutcomes/Import()?gradeId={4}';

  /**
   * @name ApiUpdateCustomLearningOutcome
   * Api to update custom learning outcome
   */
  public readonly ApiUpdateCustomLearningOutcome =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/CustomLearningOutcomes/{4}?oldGradeId={5}&newGradeId={6}';

  /**
   * @name ApiGetGradesByInstitution
   * Api to get grades by institution
   */
  public readonly ApiGetGradesByInstitution = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades';

  /**
   * @name ApiUpdateCustomLearningOutcomesGrade
   * Api to update custom learning outcomes grade
   */
  public readonly ApiUpdateCustomLearningOutcomesGrade =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/CustomLearningOutcomes/Grades?gradeId={4}';

  /**
   * @name ApiGetCourseLearningOutcomeOverview
   * Api to get course learning outcome overview
   */
  public readonly ApiGetCourseLearningOutcomeOverview =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Instructors/{2}/Curriculums/{3}/EnrolledCategories/{4}/LearningOutcomes/{5}/Overview()';

  /**
   * @name ApiCreateCourseLearningOutcomeRelatedToLearningOutcome
   * Api to create course learning outcome related to learning outcome
   */
  public readonly ApiCreateCourseLearningOutcomeRelatedToLearningOutcome =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums/{1}/Categories/{2}/LearningOutcomes/{3}/SubCourseLearningOutcomes';

  /**
   * @name ApiToUpdateCourseLearningOutcome
   * Api to update course learning outcome
   */
  public readonly ApiToUpdateCourseLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Curriculums/{1}/Categories/{2}/CourseLearningOutcomes/{3}';

  /**
   * @name ApiGetLearningOutcomeOverview
   * Api get learning outcome overview
   */
  public readonly ApiGetLearningOutcomeOverview = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Curriculums/{2}/EnrolledCategories/{3}/LearningOutcomes/Overview()';

  /**
   * @name ApiGetAvailableLearningOutcomes
   * Api get available learning outcomes
   */
  public readonly ApiGetAvailableLearningOutcomes = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/Notions/{3}/AvailableLearningOutcomes';

  /**
   * @name ApiGetAvailableLearningOutcomes
   * Api get available learning outcomes by assessment
   */
  public readonly ApiGetAvailableLearningOutcomesByAssessment = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Assessments/{2}/Exercises/{3}/AvailableLearningOutcomes';

  /**
   * @name ApiGetAvailableLearningOutcomesByCourse
   * Api get available learning outcomes by course for the assessment
   */
  public readonly ApiGetAvailableLearningOutcomesByCourse = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/AvailableLearningOutcomes';

  /**
   * @name ApiAssignLearningOutcome
   * Api assign learning outcome
   */
  public readonly ApiAssignLearningOutcome = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Classes/{2}/Courses/{3}/Notions/{4}/NotionCourseLearningOutcomes';

  /**
   * @name ApiAssignLearningOutcomeByAssessment
   * Api assign learning outcome
   */
  public readonly ApiAssignLearningOutcomeByAssessment = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Assessments/{2}/Exercise/{3}/ExerciseCourseLearningOutcomes';

  /**
   * @name ApiAssignLearningOutcome
   * Api assign learning outcome
   */
  public readonly ApiUpdateCourseLearningOutcomeDescription = this.AugmentalBaseApiServer + '/api/CourseLearningOutcomes/{0}/Description';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------- learning outcome -----------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Assessment--------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetInstructorAssessmentWithoutGroup
   * Api get instructor assessment without group
   */
  public readonly ApiGetInstructorAssessmentWithoutGroup =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Assessments?courseId={2}&gradeId={3}&isArchived={4}&isPublished={5}&isDraft={6}';

  /**
   * @name ApiGetInstructorAssessmentWithGroup
   * Api get instructor assessment with group
   */
  public readonly ApiGetInstructorAssessmentWithGroup =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Assessments?courseId={2}&gradeId={3}&groupId={4}&isArchived={5}&isPublished={6}&isDraft={7}';

  /**
   * @name ApiDeleteAssessment
   * api to delete assessment
   */
  public readonly ApiDeleteAssessment = this.AugmentalBaseApiServer + '/api/Quizzes/{0}';

  /**
   * @name ApiGetAssessmentSummary
   * api to get assessment summary
   */
  public readonly ApiGetAssessmentSummary = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Assessments/{2}/Summary';

  /**
   * @name ApiGetInstructorAssessmentOverview
   * api to get assessment overview
   */
  public readonly ApiGetInstructorAssessmentOverviewWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Overview';

  /**
   * @name ApiGetInstructorAssessmentOverview
   * api to get assessment overview
   */
  public readonly ApiGetInstructorAssessmentOverviewWithGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Overview?groupId={4}';

  /**
   * @name ApiGetInstructorAssessmentReportOverviewWithoutGroup
   * api to get assessment report overview
   */
  public readonly ApiGetInstructorAssessmentReportOverviewWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Report?attempts={4}';

  /**
   * @name ApiGetInstructorAssessmentReportOverviewWithGroup
   * api to get assessment report overview
   */
  public readonly ApiGetInstructorAssessmentReportOverviewWithGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Report?attempts={4}&groupId={5}';

  /**
   * @name ApiGetInstructorAssessmentReportOverviewWithoutGroup
   * api to get assessment report attempts
   */
  public readonly ApiGetInstructorAssessmentReportOverviewAttemptsWithoutGroup = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Report/Attempts';

  /**
   * @name ApiGetInstructorAssessmentReportOverviewWithGroup
   * api to get assessment report attempts
   */
  public readonly ApiGetInstructorAssessmentReportOverviewAttemptsWithGroup =
    this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Report/Attempts?groupId={4}';

  /**
   * @name ApiGetInstructorAssessmentReportOverviewGroups
   * api to get assessment report groups
   */
  public readonly ApiGetInstructorAssessmentReportOverviewGroups = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Instructors/{1}/Grades/{2}/Assessments/{3}/Report/Groups';

  /**
   * @name ApiGetAssessmentExercise
   * api to get assessment exercise
   */
  public readonly ApiGetAssessmentExercise = this.AugmentalBaseApiServerBff + '/api/Assessments/{0}/Exercises';

  /**
   * @name ApiGetAssessmentExerciseQuestions
   * api to get assessment exercise questions
   */
  public readonly ApiGetAssessmentExerciseQuestions = this.AugmentalBaseApiServerBff + '/api/Assessments/{0}/Exercises/{1}/ExerciseQuestions?loadQuestion=true';

  /**
   * @name ApiReorderAssessmentExercise
   * api to reorder assessment exercise
   */
  public readonly ApiReorderAssessmentExercise = this.AugmentalBaseApiServer + '/api/Assessments/{0}/Exercises/{1}';

  /**
   * @name ApiGenerateAssessment
   * api to auto generate assessment
   */
  public readonly ApiGenerateAssessment = this.AugmentalBaseApiServerBff + '/api/V2/Institutions/{0}/Courses/{1}/Instructors/{2}/Assessments/Automated';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Assessment--------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Analytics---------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  /**
   * @name ApiGetAcademicYears
   * api get acadamic years
   */
  public readonly ApiGetAcademicYears = this.AugmentalBaseApiServer + '/api/Institutions/{0}/AcademicYears';

  /**
   * @name ApiCheckIfSubInstitutionAvailable
   * api to check if current institution has sub institutions
   */
  public readonly ApiCheckIfSubInstitutionAvailable = this.AugmentalBaseApiServer + '/api/Institutions/{0}/SubInstitutions/Any()';

  /**
   * @name ApiGetSubInstitutions
   * api to get sub institutions
   */
  public readonly ApiGetSubInstitutions = this.AugmentalBaseApiServer + '/api/Institutions/{0}/SubInstitutions';

  /**
   * @name ApiGetInstitutionAnalyticsOverview
   * api to get institution analytics overview
   */
  public readonly ApiGetInstitutionAnalyticsOverview = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Overview';

  /**
   * @name ApiGetInstitutionAnalyticsDemographics
   * api to get institution analytics demographics
   */
  public readonly ApiGetInstitutionAnalyticsDemographics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Demographics';

  /**
   * @name ApiGetInstitutionAnalyticsSubjects
   * api to get institution analytics subjects
   */
  public readonly ApiGetInstitutionAnalyticsSubjects = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Subjects';

  /**
   * @name ApiGetSubInstitutionAnalytics
   * api to get sub institution analytics
   */
  public readonly ApiGetSubInstitutionAnalytics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/SubInstitutions';

  /**
   * @name ApiGetInstitutionAnalyticsCourses
   * api to get institution analytics courses
   */
  public readonly ApiGetInstitutionAnalyticsCourses = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Courses';

  /**
   * @name ApiGetInstitutionSettings
   * api to get institution settings
   */
  public readonly ApiGetInstitutionSettings = this.AugmentalBaseApiServer + '/api/Institutions/{0}/InstitutionSettings';

  /**
   * @name ApiGetCoursesByInstitutionGrade
   * api to get course by institution and grade
   */
  public readonly ApiGetCoursesByInstitutionGrade = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Grades/{1}/Courses';

  /**
   * @name ApiGetLearnerDistributionAnalytics
   * api to get learner distribution analytics
   */
  public readonly ApiGetLearnerDistributionAnalytics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/LearnersDristribution';

  /**
   * @name ApiGetAverageCourseMasteryAnalytics
   * api to get average course mastery analytics
   */
  public readonly ApiGetAverageCourseMasteryAnalytics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/CourseMastery/Average';

  /**
   * @name ApiGetAverageTimeSpentAnalytics
   * api to get average time spent analytics
   */
  public readonly ApiGetAverageTimeSpentAnalytics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/TimeSpent/Average';

  /**
   * @name ApiGetAverageLevelAnalytics
   * api to get average level analytics
   */
  public readonly ApiGetAverageLevelAnalytics = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Level/Average';

  /**
   * @name ApiGetCourseComparisonOverview
   * api to get course comparison overview
   */
  public readonly ApiGetCourseComparisonOverview =
    this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Grades/{1}/Courses/{2}/Overview?groupId={3}&loadCourseCard={4}&loadDataForInstitutionAnalytics={5}&isCardInGreen={6}';

  /**
   * @name ApiGetCourseComparisonOverview
   * api to get course comparison overview
   */
  public readonly ApiGetLearnersDistributionCourseCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/LearnersDristribution/Courses/Compare()';

  /**
   * @name ApiGetCourseComparisonOverview
   * api to get course comparison overview
   */
  public readonly ApiGetResourceCourseCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Resources/Courses/Compare()';

  /**
   * @name ApiGetAchievementCourseCompare
   * api to get achievement course compare
   */
  public readonly ApiGetAchievementCourseCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Achievement/Courses/Compare()';

  /**
   * @name ApiGetCourseMasteryCompare
   * api to get course mastery compare
   */
  public readonly ApiGetCourseMasteryCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Mastery/Courses/Compare()';

  /**
   * @name ApiGetTimeSpentCompare
   * api to get time spent compare
   */
  public readonly ApiGetTimeSpentCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/TimeSpent/Courses/Compare()';

  /**
   * @name ApiGetLevelCompare
   * api to get level compare
   */
  public readonly ApiGetLevelCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Level/Courses/Compare()';

  /**
   * @name ApiGetAssessmentCompare
   * api to get assessment compare
   */
  public readonly ApiGetAssessmentCompare = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Analytics/Courses/Assessments/Compare()';

  //--------------------------------------------------------------
  //--------------------------------------------------------------
  //--------------------------Analytics---------------------------
  //--------------------------------------------------------------
  //--------------------------------------------------------------
}
