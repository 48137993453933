import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../dialog-single-input-model';

@Component({
    selector: 'm-modal',
    templateUrl: './dialog-single-input.component.html',
    styleUrls: ['dialog-single-input-style.scss'],
})
export class DialogSingleInput {
    limitNumberOfCharacters: number = null;

    constructor(public dialogRef: MatDialogRef<DialogSingleInput>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        dialogRef.disableClose = data.disableClickOutsideDialog;
        this.limitNumberOfCharacters = data.inputLimitNumberOfCharacters;
    }

    onNegativeClick(): void {
        this.dialogRef.close();
    }
}
