export class UtilityHelper {
    //Add delay
    static delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    /**
     * @name validURL
     * @param str
     * Check if a string is a valid url
     */
    static isValidUrl(str): boolean {
        var pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }

    /**
     * @name isYoutubeVideo
     * @param str
     * Check if a url is a valid youtube
     */
    static isYoutubeVideo(url: string): boolean {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @name isMp4Url
     * @param url
     * Check if a url is a valid mp4
     */
    static isMp4Url(url: string): boolean {
        var regExp = '.(mp4)$';
        var match = url.match(regExp);
        if (match) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @name isMp4Url
     * @param url
     * Check if a url is a valid mp4
     */
    static isMovUrl(url: string): boolean {
        var regExp = '.(mov)$';
        var match = url.match(regExp);
        if (match) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @name isMp4Url
     * @param url
     * Check if a url is a valid mp4
     */
    static isMp3Url(url: string): boolean {
        var regExp = '.(mp3)$';
        var match = url.match(regExp);
        if (match) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @name isJson
     * @param str
     * Check if a string is a json or not
     */
    static isJson(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * @name ReplaceKeywordWithAnotherKeyword
     * @param data
     * @param keywordToReplace
     * @param keywordToAdd
     * Replace a specific word with another one.
     * By default Regex replaces only the first occurence.
     * the RegExp('word', 'g') : 'g' to replace all the occurence
     */
    static ReplaceKeywordWithAnotherKeyword(data: string, keywordToReplace: string, keywordToAdd: string): string {
        return data.replace(new RegExp(keywordToReplace, 'g'), keywordToAdd);
    }

    /**
     * @name SortValues
     * @param a
     * @param b
     * @param isAsc
     */
    static SortValues(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * @name GetCurrentTimeIn12HoursFormat
     * Get current time in 12hours format
     */
    static GetCurrentTimeIn12HoursFormat(date: Date) {
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        // var hours = date.getHours();
        // var minutes = date.getMinutes();
        // var ampm = hours >= 12 ? 'pm' : 'am';
        // hours = hours % 12;
        // hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0'+minutes : minutes;
        // var strTime = hours + ':' + minutes + ' ' + ampm;
        // return strTime;
    }

    /**
     * @name RemoveLastSlashFromUrl
     * @param url
     * @returns url
     * Remove last slash from url
     */
    static RemoveLastSlashFromUrl(url: string) {
        let lastChr = url.charAt(url.length - 1);

        if (lastChr == '/') {
            url = url.slice(0, -1);
        }

        return url;
    }

    /**
     * @name checkIfNumber
     * @param element
     */
    static checkIfNumber(element: string): boolean {
        if (isNaN(+element)) {
            return false;
        } else {
            return true;
        }
    }
}
