import * as CryptoJS from 'crypto-js';

export class DecryptionHelper {
    /**
     * Decrypt text
     * @param encryptedText
     * @param key
     * @param iv
     * @param cipherMode
     * @param paddingMode
     * @param feedbackSize
     */
    public static DecryptText_Aes_Advanced(
        encryptedText: string,
        key: string,
        iv: string,
        cipherMode: any,
        paddingMode: any,
        feedbackSize: number
    ): string {
        try {
            var keyBytes = CryptoJS.enc.Utf8.parse(key);
            var ivBytes = CryptoJS.enc.Utf8.parse(iv);

            const bytes = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
                keySize: feedbackSize,
                iv: ivBytes,
                mode: cipherMode,
                padding: paddingMode,
            });
            if (bytes.toString()) {
                return bytes.toString(CryptoJS.enc.Utf8);
            }
        } catch (e) {
            return '';
        }
    }

    /**
     * Decrypt text
     * @param encryptedText
     * @param key
     * @param iv
     */
    public static DecryptText_Aes(
        encryptedText: string,
        key: string,
        iv: string
    ): string {
        try {
            var keyBytes = CryptoJS.enc.Utf8.parse(key);
            var ivBytes = CryptoJS.enc.Utf8.parse(iv);

            const bytes = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
                iv: ivBytes,
            });
            if (bytes.toString()) {
                return bytes.toString(CryptoJS.enc.Utf8);
            }
        } catch (e) {
            return '';
        }
    }
}
