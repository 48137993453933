import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatSlideToggleModule, MatSnackBarModule } from '@angular/material';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntercomModule } from 'ng-intercom';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { environment } from '../../src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { Globals } from './globals';
import { BottomSheetSharedModule } from './shared/components/bottom-sheet/bottom-sheet.module';
import { DialogLoadingModule } from './shared/components/dialog-builder/loading/dialog-loading.module';
import { DialogSingleInputModule } from './shared/components/dialog-builder/single-input/dialog-single-input.module';
import { PopupModule } from './shared/components/popup/popup.module';
import { ANALYTICS } from './shared/helper/analytics/analytics.injection.service';
import { AnalyticsService } from './shared/helper/analytics/analytics.service';
import { FirebaseService } from './shared/helper/analytics/providers/firebase/analytics-firebase';
import { MixpanelService } from './shared/helper/analytics/providers/mixpanel/analytics-mixpanel';
import { SegmentService } from './shared/helper/analytics/providers/segment/analytics-segment';
import { GlobalVaribales } from './shared/helper/global-variable';
import { RoutingWorkflowSetupModule } from './theme/pages/route/setup-workflow-routing.module';
import { OnBoardingModule } from './theme/teacher/pages/content-builder/Templates/popup/on-boarding/content-builder/on-boarding-popup.module';

@NgModule({
    exports: [],
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxGoogleAnalyticsModule.forRoot(environment.analytics.firebase.googleAnalytics.tag),
        IntercomModule.forRoot({
            appId: GlobalVaribales.IntercomAppID,
            updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        RoutingWorkflowSetupModule,
        // --------------------------------------------------------------------
        // These module are define here because we are using it inside the view models
        // and the view models are injected at the beginning of the application
        MatSnackBarModule,
        MatDialogModule,
        MatBottomSheetModule,
        DialogSingleInputModule,
        PopupModule,
        DialogLoadingModule,
        MatSlideToggleModule,
        BottomSheetSharedModule,
        OnBoardingModule,
        NgxMaterialTimepickerModule,
        // --------------------------------------------------------------------
        AuthModule,
    ],
    providers: [
        AnalyticsService,
        { provide: ANALYTICS, useClass: FirebaseService, multi: true },
        { provide: ANALYTICS, useClass: MixpanelService, multi: true },
        { provide: ANALYTICS, useClass: SegmentService, multi: true },
        FirebaseService,
        MixpanelService,
        SegmentService,

        Globals,
        // {
        //   provide: LOCALE_ID,
        //   useValue: 'fr',
        // },
        {
            provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true },
        },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler }
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: RequestInterceptor,
        //   multi: true
        // }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
