import { BrowserPreferenceModel } from '../../../../../src/app/core/models/session/browser-preferences.model';

export class LoggerHelper {
    //Normal logging

    static log(message: any) {
        if (BrowserPreferenceModel.Instance.enableDebugging) {
            console.log(message);
        }
        // if (!environment.production) {
        //   console.log(message);
        // }
    }

    //Error logging
    static logError(message: any) {
        if (BrowserPreferenceModel.Instance.enableDebugging) {
            console.error(message);
        }
    }

    //Error logging
    static logInfo(message: any) {
        if (BrowserPreferenceModel.Instance.enableDebugging) {
            console.info(message);
        }
    }
}
