import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AccountType } from '../../../../src/app/shared/helper/account/account.enum';
import { LanguageCode } from '../../../../src/app/shared/helper/language/language.enum';
import { LoggerHelper } from '../../../../src/app/shared/helper/logging/logger';
import { UserPreferencesModel } from '../../core/models/session/user-preferences.model';
import { Helpers } from '../../helpers';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    // Reference
    // Implementation: https://github.com/ngx-translate/core#api
    // Implementation: https://www.digitalocean.com/community/tutorials/angular-ngx-translate
    // Auto translate the JSON file: https://translate.i18next.com/

    constructor(private translate: TranslateService, private http: HttpClient) {
        // // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang(LanguageCode.English);
        // // the lang to use, if the lang isn't available, it will use the current loader to get them
        // translate.use(LanguageCode.English);
        this.setupDefaultLanguage(LanguageCode.English);
    }

    /**
     * @name setupDefaultLanguage
     * @param language
     * Set up the default language
     */
    private async setupDefaultLanguage(language: string) {
        var defaultLanguage = ((await this.loadDefaultTranslationFile(language)) as any).default;
        this.translate.setTranslation(language, defaultLanguage, true);
        this.translate.use(language);
    }

    /**
     * @name isPlatformDirectionRtl
     * Detect the platform direction
     */
    isPlatformDirectionRtl(): boolean {
        if (this.translate.currentLang == LanguageCode.Arabic) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @name setLanguage
     * @param language
     */
    public async setLanguage(language: string) {
        LoggerHelper.log('setLanguage');
        LoggerHelper.log(language);
        if (language == LanguageCode.Arabic) {
            Helpers.SwitchToRTL();
        } else {
            Helpers.SwitchToLTR();
        }
        if (language) {
            await this.setupDefaultLanguage(language);

            //update user preferences
            UserPreferencesModel.Instance.languageIsoCode = language;
            UserPreferencesModel.UpdateInstance(UserPreferencesModel.Instance);
        }
    }

    /**
     * @name setLanguageBasedOnAccountType
     * @param language
     * @param accountType
     */
    public async setLanguageBasedOnAccountType(userPreferences: UserPreferencesModel) {
        LoggerHelper.log('setLanguageBasedOnAccountType');
        LoggerHelper.log(userPreferences);
        if (userPreferences && userPreferences.institutionId && userPreferences.institutionId > 0) {
            LoggerHelper.log('HAS INSTITUTION');
            if (userPreferences.languageIsoCode == LanguageCode.Arabic) {
                Helpers.SwitchToRTL();
            } else {
                Helpers.SwitchToLTR();
            }
            if (userPreferences.languageIsoCode) {
                LoggerHelper.log('Inside Translations');

                var defaultLanguage = ((await this.loadDefaultTranslationFile(userPreferences.languageIsoCode)) as any).default;
                var customLanguage = ((await this.loadAccountTranslationFile(userPreferences)) as any).default;
                const mergedTranslations = { ...defaultLanguage, ...customLanguage };
                LoggerHelper.log(mergedTranslations);

                // Set and use the merged translations
                this.translate.setTranslation(userPreferences.languageIsoCode.toLowerCase(), mergedTranslations);
                this.translate.use(userPreferences.languageIsoCode.toLowerCase());

                //update user preferences
                UserPreferencesModel.Instance.languageIsoCode = userPreferences.languageIsoCode;
                UserPreferencesModel.UpdateInstance(UserPreferencesModel.Instance);
            }
        } else {
            LoggerHelper.log('HAS NO INSTITUTION');
            await this.setupDefaultLanguage(LanguageCode.English);

            //update user preferences
            // UserPreferencesModel.Instance.languageIsoCode = LanguageCode.English;
            // UserPreferencesModel.UpdateInstance(UserPreferencesModel.Instance);
        }
    }

    /**
     * @name loadDefaultTranslationFile
     * @param language
     * @returns
     * Loads the default translation file
     */
    private async loadDefaultTranslationFile(language: string) {
        return await import('../../../assets/i18n/' + language.toLowerCase() + '.json');
    }

    /**
     * @name loadAccountTranslationFile
     * @param userPreferences
     * @returns
     * Loads the account specific translation file
     */
    private async loadAccountTranslationFile(userPreferences: UserPreferencesModel) {
        var accountType: string = '';
        if (userPreferences.isK12Education) {
            accountType = AccountType.School;
        } else {
            if (userPreferences.isHigherEducation) {
                accountType = AccountType.HigherEducation;
            } else {
                if (userPreferences.isTrainingEducation) {
                    accountType = AccountType.Training;
                }
            }
        }
        return await import('../../../assets/i18n/' + accountType + '/' + userPreferences.languageIsoCode.toLowerCase() + '.json');
    }

    /**
     * @name getSelectedLanguage
     * @returns
     * Get the selected language
     */
    public getSelectedLanguage(): Observable<any> {
        if (UserPreferencesModel.Instance.languageIsoCode) {
            return of(UserPreferencesModel.Instance.languageIsoCode);
        }
        return of(LanguageCode.English);
    }

    /**
     * @name GetTranslatedValue
     * @param key
     * @param object
     * Asynchronous - gets translations then completes.
     */
    public GetTranslatedValue(key: string, object?: Object): string {
        let tranlsatedValue = '';
        if (object) {
            this.translate.get(key, object).subscribe((res: string) => {
                tranlsatedValue = res;
            });
        } else {
            this.translate.get(key).subscribe((res: string) => {
                tranlsatedValue = res;
            });
        }

        return tranlsatedValue;
    }
}
