export class UrlFormatterHelper {
    /**
     * Function to add the variables in the API string
     * @param str
     * @param val
     */
    static FormatString(str: string, val: any[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    /**
     * Function to add the variables in the API string
     * @param str
     * @param val
     */
    static FormatStringWithMultipleValues(str: string, ...val: any[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }
}
