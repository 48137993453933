import { Injectable } from '@angular/core';
import { UserPreferencesApplicationService } from '../.../../../../../../src/app/core/services/session/user-preferences-application-service.service';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesViewModel {
    constructor(private service: UserPreferencesApplicationService) { }

    GetUserPreferences(institutionId: number, userId: number) {
        var data = this.service.GetUserPreferences(institutionId, userId);
        return data;
    }

    SaveUserPreferences(body: any, institutionId: number, userId: number) {
        var data = this.service.SaveUserPreferences(body, institutionId, userId);
        return data;
    }

    UpdateUserPreferences(body: any, institutionId: number, userId: number) {
        var data = this.service.UpdateUserPreferences(body, institutionId, userId);
        return data;
    }
}
