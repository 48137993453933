import { Injectable } from '@angular/core';
import { HttpCall } from '../../../../../../src/app/core/http/http-call/http-call';
import { AccountApiVariables } from '../../../http/http-call/apis/shared/account/account.api';

@Injectable({
    providedIn: 'root',
})
export class BrandingApplicationService {
    constructor(private accountApiVariables: AccountApiVariables, private http: HttpCall) { }

    /**
     * @name getAccountBranding
     * @returns
     */
    public getAccountBranding() {
        return this.http.GetAsync(false, this.accountApiVariables.ApiGetAccountBranding);
    }

    /**
     * @name getAccountBrandingByAccountId
     * @param accountId
     * @returns
     */
    public getAccountBrandingByAccountId(accountId: number) {
        return this.http.GetAsync(true, this.accountApiVariables.ApiGetAccountBrandingByAccount, accountId);
    }
}
