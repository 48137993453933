import { CachingHelper } from '../../../../../src/app/shared/helper/caching/caching';
import { CachingKeys } from '../../../../../src/app/shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../helper/serialization-helper';
import { UserRoleModel } from './user-role.model';

export class UserRolePermissionResponseModel {
    roles: UserRoleModel[];

    private static _instance: UserRolePermissionResponseModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(
                new UserRolePermissionResponseModel(),
                CachingHelper.getItemFromCache(CachingKeys.UserRolePermissions)
            );

            //if instance does not exist
            if (!this._instance) {
                this.UpdateInstance(new UserRolePermissionResponseModel());
                this._instance = SerializationHelper.toInstance(
                    new UserRolePermissionResponseModel(),
                    CachingHelper.getItemFromCache(CachingKeys.UserRolePermissions)
                );
            }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }

    public static UpdateInstance(data: UserRolePermissionResponseModel) {
        CachingHelper.saveItemIntoCache(
            CachingKeys.UserRolePermissions,
            JSON.stringify(data)
        );
    }
}
