import { CachingHelper } from '../../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../../helper/serialization-helper';
import { GroupResponseModel } from '../../institution/response/group-response.model';
import { CourseGroup } from './course-group.model';
import { UserWithOwnershipFlagResponseModel } from './user-ownership-flag-response.model';

export class InstructorInstitutionCourseResponseModel {
    /// <summary>
    /// Course Id
    /// </summary>
    id: number;
    /// <summary>
    /// List of grades
    /// </summary>
    grades: CourseGroup[];
    /// <summary>
    /// Course Title
    /// </summary>
    name: string;
    /// <summary>
    /// Course Decription
    /// </summary>
    description: string;
    /// <summary>
    /// author name
    /// </summary>
    author: string;
    /// <summary>
    /// author firstname
    /// </summary>
    authorFirstName: string;
    /// <summary>
    /// author lastname
    /// </summary>
    authorLastName: string;
    /// <summary>
    /// author username
    /// </summary>
    authorUsername: string;
    /// <summary>
    /// institution
    /// </summary>
    institution: string;
    /// <summary>
    /// Coure version
    /// </summary>
    version: string;
    /// <summary>
    /// Iso code of course language
    /// </summary>
    languageIsoCode: string;
    /// <summary>
    /// Course language name
    /// </summary>
    language: string;
    /// <summary>
    /// Course language id
    /// </summary>
    languageId: number;
    /// <summary>
    /// Course Status
    /// </summary>
    status: string;
    /// <summary>
    /// Course Status boolean
    /// </summary>
    isPublished: boolean;
    /// <summary>
    /// True if course is assigned to a structure
    /// </summary>
    isAssignedToStructure: boolean;
    /// <summary>
    /// True if was duplicate
    /// </summary>
    wasDuplicated: boolean;
    /// <summary>
    /// True if is already enrolled
    /// </summary>
    isAlreadyEnrolled: boolean;
    /// <summary>
    /// Duration
    /// </summary>
    duration: string;
    /// <summary>
    /// Category id
    /// </summary>
    categoryId: number;
    /// <summary>
    /// Category description
    /// </summary>
    category: string;
    /// <summary>
    /// Icon
    /// </summary>
    icon: string;
    /// <summary>
    /// Resource count text
    /// </summary>
    resourcesCountText: string;
    /// <summary>
    /// Question count text
    /// </summary>
    questionsCountText: string;
    /// <summary>
    /// User with ownership flags
    /// </summary>
    usersWithOwnershipFlags: UserWithOwnershipFlagResponseModel[];
    /// <summary>
    /// Curriculum id
    /// </summary>
    curriculumId: number;
    /// <summary>
    /// Curriculum name
    /// </summary>
    curriculumName: string;
    /// <summary>
    /// Curriculum language
    /// </summary>
    curriculumLanguage: string;
    /// <summary>
    /// Class id
    /// </summary>
    classId: number;
    /// <summary>
    /// groups
    /// </summary>
    groups: GroupResponseModel[];
    /// <summary>
    /// courseType
    /// </summary>
    courseType: string;
    /// <summary>
    /// is Adaptive learning enabled
    /// </summary>
    isAdaptiveLearningEnabled: boolean;
    /// <summary>
    /// is Linear Learning Enabled
    /// </summary>
    isLinearLearningEnabled: boolean;
    /// <summary>
    /// is learner Visible
    /// </summary>
    isLearnerVisible: boolean;
    /// <summary>
    /// is publisher Visible
    /// </summary>
    isPublisherVisible: boolean;
    /// <summary>
    /// progress percentage
    /// </summary>
    progressPercentage: number;
    /// <summary>
    /// is self enrolled
    /// </summary>
    isSelfEnrolled: boolean;
    /// <summary>
    /// progress status
    /// </summary>
    progressStatusTranslated: string;
    /// <summary>
    /// is not started
    /// </summary>
    isNotStarted: boolean;
    /// <summary>
    /// is in progress
    /// </summary>
    isInProgress: boolean;
    /// <summary>
    /// is completed
    /// </summary>
    isCompleted: boolean;
    /// <summary>
    /// isGenerativeAIEnabled
    /// </summary>
    isGenerativeAIEnabled: boolean;
    /// <summary>
    /// hasCertificate
    /// </summary>
    hasCertificate: boolean;

    private static _instance: InstructorInstitutionCourseResponseModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new InstructorInstitutionCourseResponseModel(), CachingHelper.getItemFromCache(CachingKeys.CourseSession));

            //if instance does not exist
            if (!this._instance) {
                this.updateInstance(new InstructorInstitutionCourseResponseModel());
                this._instance = SerializationHelper.toInstance(new InstructorInstitutionCourseResponseModel(), CachingHelper.getItemFromCache(CachingKeys.CourseSession));
            }
            return this._instance;
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.CourseSession);
    }

    public static updateInstance(data: InstructorInstitutionCourseResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.CourseSession, JSON.stringify(data));
    }
}
