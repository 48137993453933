import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../config/services/translation.service';
import { DialogFeatureReleaseModel } from './model/dialog-feature-release.model';

@Component({
    selector: 'dialog-feature-release',
    templateUrl: './dialog-feature-release.component.html',
    styleUrls: ['dialog-feature-release.component.scss'],
})
export class DialogFeatureReleaseComponent {
    //------------ translation --------------------
    translationTitle: string = '';
    //------------ translation --------------------

    title: string;
    featureImage: string;
    featureTitle: string;
    featureDescription: string;
    featureActionButtonUrl: string;
    cancelButtonText: string = '';
    actionButtonText: string = '';

    constructor(
        public translate: TranslateService,
        public translationService: TranslationService,
        public dialogRef: MatDialogRef<DialogFeatureReleaseComponent>,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogFeatureReleaseModel
    ) {
        this.LabelUpdate();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.LabelUpdate();
        });

        this.initializeData(data);
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: DialogFeatureReleaseModel) {
        this.featureTitle = data.featureTitle;
        this.featureDescription = data.featureDescription;
        this.featureImage = data.featureImage;
        this.cancelButtonText = data.negativeButtonText;
        this.actionButtonText = data.positiveButtonText;
        this.featureActionButtonUrl = data.positiveButtonActionUrl;
    }

    /**
     * Update the UI labels based on the translations
     */
    LabelUpdate() {
        this.translationTitle = this.translationService.GetTranslatedValue('GENERAL.NewAnnouncement');
    }

    /**
     * @name onCancelButtonClick
     */
    onCancelButtonClick(): void {
        this.dialogRef.close();
    }

    /**
     * @name onActionButtonClick
     */
    onActionButtonClick(): void {
        this.dialogRef.close();
        this._router.navigate([this.featureActionButtonUrl]);
    }
}
