import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ThemeType } from '../../../helper/theme/theme-type.enum';
import { PopupCodePreviewModel } from '../../../models/popups/popup-code-preview.model';

@Component({
    selector: 'popup-code-preview',
    templateUrl: './popup-code-preview.component.html',
    styleUrls: ['popup-code-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupCodePreviewComponent {
    icon: string;
    theme: string;

    title: string;
    code: string;
    description: string;

    actionButtonUrl: string;
    cancelButtonText: string = '';
    actionButtonText: string = '';

    public predefinedTheme = ThemeType;

    constructor(public dialogRef: MatDialogRef<PopupCodePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: PopupCodePreviewModel) {
        this.initializeData(data);
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: PopupCodePreviewModel) {
        this.theme = data.theme;
        this.icon = data.icon;

        this.title = data.title;
        this.description = data.description;
        this.code = data.code;

        this.cancelButtonText = data.negativeButtonText;
        this.actionButtonText = data.positiveButtonText;
        this.actionButtonUrl = data.positiveButtonActionUrl;
    }

    /**
     * @name onCancelButtonClick
     */
    onCancelButtonClick(): void {
        this.dialogRef.close(false);
    }

    /**
     * @name onActionButtonClick
     */
    onActionButtonClick(): void {
        if (this.actionButtonUrl && this.actionButtonUrl.length > 0) {
            window.open(this.actionButtonUrl, '_blank');
        }
        this.dialogRef.close(true);
    }
}
