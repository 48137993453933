import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root'
})
export class UserRolesApplicationService {
    private langIds: any = [];

    constructor(
        private globalApiVariables: GlobalApiVariables,
        private http: HttpCall
    ) { }

    /**
     * @name GetUserPermission
     * @param institutionId
     * @param userId
     * Get user permissions in a specific institution
     */
    public GetUserPermission(institutionId: number, userId: number) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiGetUserPermissions,
            institutionId,
            userId
        );
        return data;
    }
}
