import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    MatDialogRef,
    MatListOption,
    MatSelectionList,
    MAT_DIALOG_DATA
} from '@angular/material';
import { QuestionList } from '../dialog-list-model';

@Component({
    selector: 'dialog-list',
    templateUrl: './dialog-list.component.html',
    styleUrls: ['dialog-list-style.scss']
})
export class DialogList implements OnInit {
    @ViewChild('questionList') selectionList: MatSelectionList;

    itemLevel1: string = '';
    itemListLevel1: QuestionList[] = [];

    itemLevel2: string = '';
    itemListLevel2: QuestionList[] = [];

    selectedOptions: QuestionList[] = [];

    itemLists: QuestionList[] = [];

    ngOnInit() {
        this.selectionList.selectedOptions = new SelectionModel<MatListOption>(
            false
        );
    }

    constructor(
        public dialogRef: MatDialogRef<DialogList, QuestionList[]>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.itemLists = data.questionSameAsList;
        this.FilterData();
        this.FillPreSelectedItems(data.questionSameAsSelectedList);

        dialogRef.disableClose = data.data.disableClickOutsideDialog;
    }

    /**
     * @name filterData
     * Filter the data into levels
     */
    FilterData() {
        if (this.itemLists) {
            if (this.itemLists.length > 0) {
                var list1 = this.itemLists.filter(
                    element => element.isBeginner == true
                );
                if (list1.length > 0) {
                    this.itemLevel1 = list1[0].level;
                    this.itemListLevel1 = list1;
                }

                var list2 = this.itemLists.filter(
                    element => element.isIntermediate == true
                );
                if (list2.length > 0) {
                    if (this.itemListLevel1.length > 0) {
                        this.itemLevel2 = list2[0].level;
                        this.itemListLevel2 = list2;
                    } else {
                        this.itemLevel1 = list2[0].level;
                        this.itemListLevel1 = list2;
                    }
                }

                var list3 = this.itemLists.filter(
                    element => element.isAdvanced == true
                );
                if (list3.length > 0) {
                    if (this.itemListLevel1.length > 0) {
                        if (this.itemListLevel2.length == 0) {
                            this.itemLevel2 = list3[0].level;
                            this.itemListLevel2 = list3;
                        }
                    } else {
                        this.itemLevel1 = list3[0].level;
                        this.itemListLevel1 = list3;
                    }
                }
            }
        }
    }

    /**
     * @name fillPreSelectedItems
     * @param preSelectedItems
     * Fill the item already selected in the list
     */
    FillPreSelectedItems(preSelectedItems: QuestionList[]) {
        this.selectedOptions = [];
        if (preSelectedItems) {
            if (preSelectedItems.length > 0) {
                this.itemLists.forEach(element => {
                    if (preSelectedItems.find(item => item.id === element.id)) {
                        this.selectedOptions.push(element);
                    }
                });
            }
        }
    }

    /**
     * @name onNegativeClick
     * On cancel button click, close the dialog
     */
    onNegativeClick(): void {
        this.dialogRef.close();
    }
}
