import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotionCourseLearningOutcomeResponseModel } from '../../../../../../src/app/core/models/builder/course-learning-outcome/response/notion-course-learning-outcome-response-model';
import { DialogSearchableListModel } from '../dialog-searchable-list-model';

@Component({
    selector: 'dialog-searchable-list',
    templateUrl: './dialog-searchable-list.component.html',
    styleUrls: ['dialog-searchable-list-style.scss']
})
export class DialogSearchableList {
    questionList: NotionCourseLearningOutcomeResponseModel[] = [];

    backupSelectedOptions: DialogSearchableListModel[] = [];
    backupItemLists: DialogSearchableListModel[] = [];

    itemLists: DialogSearchableListModel[] = [
        // {
        //   id: 1,
        //   description: 'course learning outcome 1 is here'
        // },
        // {
        //   id: 2,
        //   description: 'course learning outcome 2 is here'
        // },
        // {
        //   id: 3,
        //   description: 'course learning outcome 3 is here'
        // },
        // {
        //   id: 4,
        //   description: 'course learning outcome 4 is here'
        // },
        // {
        //   id: 5,
        //   description: 'course learning outcome 5 is here'
        // },
        // {
        //   id: 6,
        //   description: 'course learning outcome 6 is here'
        // },
        // {
        //   id: 7,
        //   description: 'course learning outcome 7 is here'
        // }
    ];

    constructor(
        public dialogRef: MatDialogRef<
            DialogSearchableList,
            DialogSearchableListModel[]
            >,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.itemLists = data.itemLists;
        this.fillPreSelectedItems(data.questionSelectedList);
        this.backupItemLists = this.itemLists; //backup the list

        dialogRef.disableClose = data.data.disableClickOutsideDialog;
    }

    /**
     * @name fillPreSelectedItems
     * @param preSelectedItems
     * Fill the item already selected in the list
     */
    fillPreSelectedItems(preSelectedItems: DialogSearchableListModel[]) {
        if (preSelectedItems) {
            if (preSelectedItems.length > 0) {
                this.itemLists.forEach(element => {
                    if (preSelectedItems.find(item => item.id === element.id)) {
                        element.selected = true;
                        this.backupSelectedOptions.push(element);
                    }
                });
            }
        }
    }

    /**
     * @name onSearchChange
     * @param searchValue
     */
    onSearchChange(searchValue: string): void {
        if (searchValue) {
            if (searchValue.length > 0) {
                this.FilterSearchList(searchValue);
            } else {
                this.itemLists = this.backupItemLists;
            }
        } else {
            this.itemLists = this.backupItemLists;
        }
    }

    /**
     * @name FilterSearchList
     * @param searchValue
     */
    FilterSearchList(searchValue: string) {
        this.itemLists = this.backupItemLists.filter(item => {
            return item.description.toLowerCase().includes(searchValue.toLowerCase());
        });
    }

    /**
     * @name onNegativeClick
     * On cancel button click, close the dialog
     */
    onNegativeClick(): void {
        this.dialogRef.close();
    }

    /**
     * @name returnSelectedListData
     * Return the selected list
     */
    returnSelectedListData(): DialogSearchableListModel[] {
        return this.backupSelectedOptions;
    }

    /**
     * @name itemClickEvent
     * @param item
     * On item click event to save/remove from the selected list
     */
    itemClickEvent(item: DialogSearchableListModel): boolean {
        if (!item.selected) {
            item.selected = false;
        }
        item.selected = !item.selected;

        if (item.selected == false) {
            if (this.backupSelectedOptions) {
                if (this.backupSelectedOptions.length > 0) {
                    var value = this.backupSelectedOptions.find(
                        element => element.id == item.id
                    );
                    if (value) {
                        this.backupSelectedOptions.splice(
                            this.backupSelectedOptions.indexOf(value),
                            1
                        );
                    }
                }
            }
        } else {
            if (this.backupSelectedOptions) {
                if (this.backupSelectedOptions.length > 0) {
                    var value = this.backupSelectedOptions.find(
                        element => element.id == item.id
                    );
                    if (!value) {
                        this.backupSelectedOptions.push(item);
                    }
                } else {
                    this.backupSelectedOptions.push(item);
                }
            } else {
                this.backupSelectedOptions = [];
                this.backupSelectedOptions.push(item);
            }
        }

        return item.selected;
    }
}
