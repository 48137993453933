import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupActionButtonType } from '../../../../../../src/app/shared/helper/popup/popup-action.enum';
import { ThemeType } from '../../../../../../src/app/shared/helper/theme/theme-type.enum';
import { DialogData } from '../../dialog-builder/dialog-single-input-model';

@Component({
    selector: 'popup-informative',
    templateUrl: './popup-informative.component.html',
    styleUrls: ['popup-informative.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupInformativeComponent {
    icon: string;
    theme: string;
    title: string;
    description: string;
    cancelButtonText: string = '';
    actionButtonUrl: string;
    actionButtonText: string = '';
    actionSecondButtonUrl?: string;
    actionSecondButtonText?: string = '';
    popupData: DialogData;

    public predefinedTheme = ThemeType;

    constructor(public dialogRef: MatDialogRef<PopupInformativeComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.initializeData(data);
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: DialogData) {
        this.popupData = data;
        this.title = data.title;
        this.theme = data.theme;
        this.description = data.description;
        this.icon = data.icon;
        this.cancelButtonText = data.negativeButtonText;
        this.actionButtonText = data.positiveButtonText;
        this.actionButtonUrl = data.positiveButtonActionUrl;
        this.actionSecondButtonText = data.actionSecondButtonText;
        this.actionSecondButtonUrl = data.actionSecondButtonUrl;
    }

    /**
     * @name onActionButtonClick
     */
    onActionButtonClick(actionButtonSelected: string): void {
        switch (actionButtonSelected) {
            case PopupActionButtonType.NegativeActionButton:
                this.dialogRef.close(actionButtonSelected);
                break;
            case PopupActionButtonType.PrimaryActionButton:
                if (this.actionButtonUrl && this.actionButtonUrl.length > 0) {
                    window.open(this.actionButtonUrl, '_blank');
                }
                this.dialogRef.close(actionButtonSelected);
                break;
            case PopupActionButtonType.SecondaryActionButton:
                if (this.actionSecondButtonUrl && this.actionSecondButtonUrl.length > 0) {
                    window.open(this.actionSecondButtonUrl, '_blank');
                }
                this.dialogRef.close(actionButtonSelected);
                break;
            default:
                break;
        }
    }
}
