import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { PopupActionButtonType } from '../../../../../../../../src/app/shared/helper/popup/popup-action.enum';
import { TranslationService } from '../../../../../../config/services/translation.service';
import { IconsList } from '../../../../../helper/theme/icons';
import { ThemeType } from '../../../../../helper/theme/theme-type.enum';
import { DialogData } from '../../../../dialog-builder/dialog-single-input-model';
import { SnackBar } from '../../../../snackbar/snackbar';
import { PopupInputComponent } from '../../popup-input.component';

@Component({
    selector: 'popup-input-step',
    templateUrl: './popup-input-step.component.html',
    styleUrls: ['popup-input-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupInputStepComponent implements OnDestroy, OnChanges {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    iconsList = IconsList;
    isRTL: boolean = false;

    @Input() popupData: DialogData;
    @Input() preFilledItemName: string;

    theme: string;

    public predefinedTheme = ThemeType;
    isEditMode: boolean = false;

    translationNameLabel: string = '';
    translationErrorFillAllFields: string = '';

    /**
     * On page destroy
     */
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    /**
     * @name ngOnChanges
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.popupData) {
            this.initializeData(this.popupData);
        }
    }

    constructor(public translate: TranslateService, public translationService: TranslationService, public _snackBar: SnackBar, @Inject(PopupInputComponent) public parent: PopupInputComponent) {
        this.labelUpdate();
        this.translate.onLangChange.takeUntil(this.destroyed$).subscribe((event: LangChangeEvent) => {
            this.labelUpdate();
        });
    }

    /**
     * @name LabelUpdate
     * Update label string based on languages
     */
    private labelUpdate() {
        this.isRTL = this.translationService.isPlatformDirectionRtl();
        this.translationNameLabel = this.translationService.GetTranslatedValue('Curriculum.Name');
        this.translationErrorFillAllFields = this.translationService.GetTranslatedValue('GENERAL.AllFieldsAreRequired');
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: DialogData) {
        this.theme = data.theme;
    }

    /**
     * onActionButtonClick
     * On card click
     */
    onActionButtonClick(actionButtonSelected: string): void {
        switch (actionButtonSelected) {
            case PopupActionButtonType.NegativeActionButton:
                this.parent.onCancelButtonClick();
                break;
            case PopupActionButtonType.PrimaryActionButton:
                if (this.validateData()) {
                    this.parent.updateData(this.preFilledItemName);
                }
                break;
            default:
                break;
        }
    }

    /**
     * @name validateData
     * @returns true if data is valid, false otherwise
     */
    private validateData(): boolean {
        if (!this.preFilledItemName || this.preFilledItemName.trim().length == 0) {
            this._snackBar.ShowWarning(this.translationErrorFillAllFields);
            return false;
        }
        return true;
    }
}
