export enum NavigationType {
    /*------------------------------------*\
      #Teacher
    \*------------------------------------*/
    Admin = 'admin',

    /*------------------------------------*\
      #Learner
    \*------------------------------------*/
    Learner = 'learner',
}
