import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoggerHelper } from '../../../../../../src/app/shared/helper/logging/logger';
import { DialogData } from '../dialog-single-input-model';

@Component({
    selector: 'dialog-loading',
    templateUrl: './dialog-loading.component.html',
    styleUrls: ['dialog-loading-style.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogLoading {
    dialogData: DialogData;
    progressSpinnerMode: string;
    constructor(
        public dialogRef: MatDialogRef<DialogLoading>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        LoggerHelper.log(data);
        if (data.dialogData) {
            this.dialogData = new DialogData();
            this.dialogData = data.dialogData;
            dialogRef.disableClose = data.dialogData.disableClickOutsideDialog;
        }

        if (data.progress == -1) {
            this.progressSpinnerMode = 'indeterminate';
        } else {
            this.progressSpinnerMode = 'determinate';
        }
    }

    onNegativeClick(): void {
        this.dialogRef.close();
    }
}
