import { Inject, Injectable } from '@angular/core';
import { CustomParams } from 'firebase/analytics';
import { ANALYTICS } from './analytics.injection.service';
import { IAnalyticsService } from './analytics.interface.service';
import { AnalyticsExtraModel } from './models/other/analytics-extra.model';
import { FirebaseService } from './providers/firebase/analytics-firebase';
import { MixpanelService } from './providers/mixpanel/analytics-mixpanel';
import { SegmentService } from './providers/segment/analytics-segment';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(
        public mixpanelService: MixpanelService,
        public firebaseService: FirebaseService,
        public segmentService: SegmentService,
        @Inject(ANALYTICS) private analyticsServices: IAnalyticsService[]
    ) { }

    /**
     * @name initAnalyticsService
     * Init analytics service
     */
    public initAnalyticsService() {
        this.analyticsServices.forEach((service) => service.initAnalyticsService());
    }

    /**
     * @name initAnalyticsService
     * Init analytics service
     */
    public destroyAnalyticsService() {
        this.analyticsServices.forEach((service) => service.destroyAnalyticsService());
    }

    /**
     * @name registerUser
     * @param userCode
     * Registers the user code
     */
    public registerUser(userCode: string) {
        this.analyticsServices.forEach((service) => service.registerUser(userCode));
    }

    /**
     * Register user property
     *
     * @name registerUserProperty
     * @param property
     */
    public registerUserProperty(property: CustomParams) {
        this.analyticsServices.forEach((service) => service.registerUserProperty(property));
    }

    /**
     * @name setFirebaseUserProperty
     * @param property
     * Sets the custom user property
     */
    public setSegmentUserProperty(userCode: string, property: any) {
        this.segmentService.registerUserProperties(userCode, property);
    }

    /**
     * @name setFirebaseUserProperty
     * @param property
     * Sets the custom user property
     */
    public setFirebaseUserProperty(property: CustomParams) {
        this.firebaseService.setUserProperty(property);
    }

    /**
     * @name setMixpanelUserProperty
     * @param property
     * Sets the custom user property
     */
    public setMixpanelUserProperty(property: CustomParams) {
        this.mixpanelService.setUserProperty(property);
    }

    /**
     * Register company once
     *
     * @name registerCompanyPropertyOnce
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyPropertyOnce(companyCode: string) {
        this.analyticsServices.forEach((service) => service.registerCompanyPropertyOnce(companyCode));
    }

    /**
     * Register company
     *
     * @name registerCompanyProperty
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyProperty(companyCode: string) {
        this.analyticsServices.forEach((service) => service.registerCompanyProperty(companyCode));
    }

    /**
     * Update company
     *
     * @name updateCompanyProperty
     * @param {string} companyCode
     * @param {any} property
     * @param {AnalyticsExtraModel} extras
     * @memberof MixpanelService
     */
    public updateCompanyProperty(companyCode: string, property: any, extras?: AnalyticsExtraModel) {
        this.analyticsServices.forEach((service) => service.updateCompanyProperty(companyCode, property, extras));
    }

    /**
     * @name logEvent
     * @param eventName
     * @param extras
     * Sends an event event with given eventParams.
     */
    public logEvent(eventName: string, extras?: AnalyticsExtraModel) {
        this.analyticsServices.forEach((service) => service.logEvent(eventName, extras));
    }

    /**
     * @name logEventWithValue
     * @param eventName
     * @param eventValue
     * @param extras
     * Sends an event with given eventParams.
     */
    public logEventWithValue(eventName: string, eventValue: any, extras?: AnalyticsExtraModel) {
        this.analyticsServices.forEach((service) => service.logEventWithValue(eventName, eventValue, extras));
    }
}
