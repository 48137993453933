import { Component, Inject, OnDestroy, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReplaySubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GenericSelectionCardModel } from "../../../models/generic-selection-card-model";
import { DialogSelectionData } from "../../dialog-builder/dialog-selection.model";
import { TranslationService } from "../../../../../../src/app/config/services/translation.service";

@Component({
  selector: "popup-card-selection",
  templateUrl: "./popup-card-selection.component.html",
  styleUrls: ["./popup-card-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PopupCardSelectionComponent implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  icon: string;
  theme: string;
  title: string;
  description: string;
  lookupArray: GenericSelectionCardModel[] = [];

  isRTL: boolean = false;

  /**
   * Component constructor to inject dependencies.
   */
  constructor(
    public dialogRef: MatDialogRef<PopupCardSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectionData,
    public translate: TranslateService,
    public translationService: TranslationService
  ) {
    this.initializeData(data);
    this.labelUpdate();
    this.translate.onLangChange.takeUntil(this.destroyed$).subscribe(() => {
      this.labelUpdate();
    });

    // Close dialog when user clicks outside the popup (backdrop click)
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });
  }

  /**
   * On component destroy, clean up subscriptions
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * @name labelUpdate
   * Update translation labels when the language changes
   */
  private labelUpdate(): void {
    this.isRTL = this.translationService.isPlatformDirectionRtl();
  }

  /**
   * @name initializeData
   * @param data - The dialog data passed in
   * Initializes the dialog with the passed data
   */
  private initializeData(data: DialogSelectionData): void {
    this.title = data.title;
    this.theme = data.theme;
    this.description = data.description;
    this.icon = data.icon;
    // Deep clone each item in the lookupArray to avoid two-way binding
    this.lookupArray = (data.lookupArray || []).map((item) => ({ ...item }));
  }

  /**
   * @name onItemSelect
   * @param item - The selected item
   * Sends the selected item back to the parent.
   */
  onItemSelect(item: GenericSelectionCardModel): void {
    // Close the dialog and return the selected item
    this.dialogRef.close(item);
  }
}
