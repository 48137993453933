import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
    ActionItem,
    DialogDataDynamicActionsModel
} from '../../dialog-dynamic-actions-model';

@Component({
    selector: 'dialog-informative-multiple-actions',
    templateUrl: './dialog-informative-multiple-actions.component.html',
    styleUrls: ['dialog-informative-multiple-actions-style.scss']
})
export class DialogInformativeMultipleActions {
    constructor(
        public dialogRef: MatDialogRef<DialogInformativeMultipleActions>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataDynamicActionsModel
    ) {
        dialogRef.disableClose = data.disableClickOutsideDialog;
    }

    /**
     * @name OnNegativeClick
     * On negative click
     */
    public OnNegativeClick(): number {
        return -1;
    }

    /**
     * @name OnButtonClick
     * On button click
     */
    public OnButtonClick(actionKey: ActionItem): number {
        return actionKey.key;
    }
}
