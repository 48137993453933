import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { TranslationService } from '../../../../../../src/app/config/services/translation.service';
import { DialogData } from '../dialog-single-input-model';

@Component({
    selector: 'dialog-informative',
    templateUrl: './dialog-informative.component.html',
    styleUrls: ['dialog-informative-style.scss'],
})
export class DialogInformative {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    isRTL: boolean = false;
    direction: string = 'ltr';

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    constructor(public translate: TranslateService, public translationService: TranslationService, public dialogRef: MatDialogRef<DialogInformative>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        dialogRef.disableClose = data.disableClickOutsideDialog;

        this.updateRTLDesign();
        this.translate.onLangChange.takeUntil(this.destroyed$).subscribe((event: LangChangeEvent) => {
            this.updateRTLDesign();
        });
    }

    updateRTLDesign() {
        this.isRTL = this.translationService.isPlatformDirectionRtl();
        if (this.isRTL) {
            this.direction = 'rtl';
        } else {
            this.direction = 'ltr';
        }
    }

    /**
     * @name OnNegativeClick
     * On negative click
     */
    OnNegativeClick() {
        this.dialogRef.close(false);
    }

    /**
     * @name OnPositiveClick
     * On positive click
     */
    OnPositiveClick() {
        this.dialogRef.close(true);
    }
}
