import { EventEmitter, Injectable } from '@angular/core';
import { NotionElementResponseModel } from '../core/models/builder/content/notion-element-info-model';
import { ContentInsideMixedContentResponseModel } from '../core/models/builder/content/response/content-inside-mixed-content-response-model';
import { ContentModelResponseModel } from '../core/models/builder/content/response/content-response-model';
import { ComplexQuestionResponseModel } from '../core/models/builder/question/response/complex-question-response-model';
import { QuestionAnswerSM } from '../core/models/builder/question/response/question-answer-response-sm';
import { CourseSummaryLevelResponseModel } from '../core/models/course-summary/response/course-summary-level-response.model';

@Injectable({ providedIn: 'root' })
export class SharedService {
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //-----------------------Global Settings------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //#region START Global Settings
    ChangeInstitutionLogo = new EventEmitter<boolean>();
    HideSystemFooter = new EventEmitter<boolean>();
    HideSystemHeader = new EventEmitter<boolean>();
    //#endregion END Global Settings

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //------------------------Builder-------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    //#region START Builder

    //Handle the search bar clearing when a notion is not selected
    SearchSideNav = new EventEmitter<string>();
    /**
     * Handle the data initialization of the notion data
     * variables name : notionInfo,notionElementInfo
     * types : NotionResponseModel, NotionElementInfo[]
     */
    InitializeSideNav = new EventEmitter<any>();

    //Handle the data re-ordering of the content/question side list
    NotionElementReOrder = new EventEmitter<any>();

    //Open the content/exercise popup creation
    OpenCreateNewContentExercise = new EventEmitter<boolean>();

    //Detect when the user clicks on create content or exercise on the bottom sheet popup
    BottomSheetSelectedItemInfo = new EventEmitter<any>();

    /**
     * Delete exercise container
     */
    DeleteExerciseContainer = new EventEmitter<NotionElementResponseModel>();

    /**
     * Change exercise title
     */
    ExerciseTitleContainer = new EventEmitter<any>();

    /**
     * Delete content container
     * Parent will catch DeleteContentItemInsideContentContainer and send an api call
     * Once confirmed, it will fire DeleteConfirmContentItemInsideContentContainer to clear the item from the view
     */
    DeleteContentItemInsideContentContainer = new EventEmitter<ContentModelResponseModel>();
    DeleteConfirmContentItemInsideContentContainer = new EventEmitter<ContentModelResponseModel>();

    /**
     * Delete exercise container
     * Parent will catch DeleteQuestionItemInsideExerciseContainer and send an api call
     * Once confirmed, it will fire DeleteConfirmQuestionItemInsideExerciseContainer to clear the item from the view
     */
    DeleteQuestionItemInsideExerciseContainer = new EventEmitter<ContentModelResponseModel>();
    DeleteConfirmQuestionItemInsideExerciseContainer = new EventEmitter<ContentModelResponseModel>();

    /**
     * Update the question builder item that one of the siblings was deleted
     */
    UpdateSiblingsItemsOnItemDelete = new EventEmitter<boolean>();

    /**
     * Gather data from content details
     */
    GetDataFromContentDetails = new EventEmitter<boolean>();

    /**
     * Gather data from question details
     */
    GetDataFromQuestionDetails = new EventEmitter<boolean>();

    /**
     * Gather data from answer details
     */
    GetDataFromAnswerDetails = new EventEmitter<boolean>();

    /**
     * Send gathered data from content details
     */
    SendDataFromContentDetails = new EventEmitter<ContentInsideMixedContentResponseModel[]>();

    /**
     * Send gathered data from content details
     */
    SendDataFromQuestionDetails = new EventEmitter<ComplexQuestionResponseModel[]>();

    /**
     * Send gathered data from content details
     */
    SendDataFromAnswerDetails = new EventEmitter<QuestionAnswerSM>();
    //#endregion END Builder

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //----------------------Course Summary--------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    //#region Course Summary

    /**
     * Request access to show the course summary manage detail
     * @name RequestToShowCourseSummaryDetailManageMode
     */
    CourseSummaryHideHeaderInfo = new EventEmitter<boolean>();

    /**
     * Request access to hide loader
     * @name CourseSummaryHideDetailInfo
     */
    CourseSummaryHideDetailInfo = new EventEmitter<boolean>();

    /**
     * Request access to show and hide loader
     */
    CourseSummaryShowHideLoader = new EventEmitter<boolean>();

    /**
     * Request access to show the course summary manage detail
     * @name RequestToShowCourseSummaryDetailManageMode
     */
    RequestToShowCourseSummaryDetailManageMode = new EventEmitter<CourseSummaryLevelResponseModel>();

    /**
     * Request access to show the course summary info detail by sending the parent info
     * @name CourseSummarySideNavSubCourseSelected
     * @param courseSummaryResponseModel:CourseSummaryResponseModel
     * @param courseSummaryLevelResponseModel:CourseSummaryLevelResponseModel
     */
    CourseSummarySideNavSubCourseSelected = new EventEmitter<any>();

    /**
     * Request access to show the course summary info detail by sending the parent info
     * @name CourseSummarySubCourseDataUpdate
     * @param subCourseRequestModel:SubCourseRequestModel
     * @param subCourseId:number
     */
    CourseSummarySubCourseDataUpdate = new EventEmitter<any>();

    /**
     * Request access to show the course summary info detail by sending the parent info
     * @name CourseSummarySubCourseDataCreate
     * @param subCourseRequestModel:SubCourseRequestModel
     * @param subCourseId:number
     */
    CourseSummarySubCourseDataCreate = new EventEmitter<any>();

    /**
     * Request access to show the course summary info detail by sending the parent info
     * @name CourseSummarySubCourseDataCreate
     * @param notionRequestModel:NotionRequestModel
     * @param subCourseId:number
     */
    CourseSummarySectionDataCreate = new EventEmitter<any>();
    //#endregion Course Summary

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //----------------Manage User Enrollment------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    //Start User Enrollment

    ManageUserEnrollmentFilterUsers = new EventEmitter<any>();
    ManageUserEnrollmentCancelFilter = new EventEmitter<boolean>();
    //#end user enrollment

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //----------------Manage User Group-----------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    //Start User Group Enrollment

    ManageUserGroupFilterUsers = new EventEmitter<boolean>();
    //#end user group enrollment

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------User--------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //#region User
    /**
     * On user role change
     */
    readonly onUserRoleChange = new EventEmitter<boolean>();

    /**
     * On user role change
     */
    readonly onUserProfileUpdate = new EventEmitter<boolean>();
    //#endregion User

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //------------------------ Quiz --------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    /**
     * @name CollectQuizDetails
     * collect all required data to create a quiz
     */
    CollectQuizData = new EventEmitter<boolean>();

    //#endregion Quiz

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //------------------ learning flow -----------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    /**
     * @name CloseLearningFlow
     * close learning flow
     */
    CloseLearningFlow = new EventEmitter<boolean>();

    //#endregion User

    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //---------------------- Subscription --------------------
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    //#region Subscription

    /**
     * On subscription change
     */
    readonly onSubscriptionChange = new EventEmitter<boolean>();

    //#endregion Subscription
}
