import {
    Component,
    ElementRef,
    Inject,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVaribales } from '../../../../../../src/app/shared/helper/global-variable';
import { UtilityHelper } from '../../../../../../src/app/shared/helper/utility';
import { DialogData } from '../dialog-single-input-model';

@Component({
    selector: 'dialog-single-output',
    templateUrl: './dialog-single-output.component.html',
    styleUrls: [`dialog-single-output.component.scss`],
    encapsulation: ViewEncapsulation.None,
})
export class DialogSingleOutput {
    @ViewChild('linkText') inputOne: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<DialogSingleOutput>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        dialogRef.disableClose = data.disableClickOutsideDialog;
        this.LoadData();
    }

    /**
     * @name LoadData
     * Load required data for this page
     */
    async LoadData() {
        await UtilityHelper.delay(
            GlobalVaribales.ShortDelayToLoadLazyDataBetweenComponents
        ); //delay the data reading, until the data has been set from the previous page using lazy loading
        this.inputOne.nativeElement.focus();
    }

    /**
     * @name OnNegativeClick
     * On negative click
     */
    OnNegativeClick(): boolean {
        this.dialogRef.close();
        return false;
    }

    /**
     * @name OnPositiveClick
     * On positive click
     */
    OnPositiveClick(): boolean {
        this.dialogRef.close();
        return false;
    }
}
