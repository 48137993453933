import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IntegrationsApiVariables {
    public readonly AugmentalBaseApiServer = environment.baseApiServer;
    public readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

    public readonly ApiGetInstitutionStripeStatus = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Stripe/IsConnected';
    public readonly ApiGetInstitutionStripeUrlConnect = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Users/{1}/Stripe/Connect';
    public readonly ApiRemoveInstitutionStripeConnection = this.AugmentalBaseApiServer + '/api/Institutions/{0}/Stripe/Connect/Access/Revoke';
}
