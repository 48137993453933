import { Analytics, CustomParams, getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { environment } from '../../../../../../../src/environments/environment';
import { LoggerHelper } from '../../../logging/logger';
import { IAnalyticsService } from '../../analytics.interface.service';

const firebaseConfig = {
    apiKey: environment.analytics.firebase.firebaseConfig.apiKey,
    authDomain: environment.analytics.firebase.firebaseConfig.authDomain,
    projectId: environment.analytics.firebase.firebaseConfig.projectId,
    storageBucket: environment.analytics.firebase.firebaseConfig.storageBucket,
    messagingSenderId: environment.analytics.firebase.firebaseConfig.messagingSenderId,
    appId: environment.analytics.firebase.firebaseConfig.appId,
    measurementId: environment.analytics.firebase.firebaseConfig.measurementId,
};

export class FirebaseService implements IAnalyticsService {
    private isTrackingEnabled: boolean;

    private app: FirebaseApp;
    private analytics: Analytics;

    constructor() {
        // Initialize Firebase
        LoggerHelper.log('Inside AnalyticsFirebaseService');
        this.isTrackingEnabled = environment.analytics.firebase.enableTracking;
    }
    registerUserProperties(userCode: string, property: CustomParams) {
        throw new Error('Method not implemented.');
    }

    /**
     * Initialize service
     *
     * @name initAnalyticsService
     * @memberof IAnalyticsService
     */
    initAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Initialize AnalyticsFirebaseService');
            this.app = initializeApp(firebaseConfig);
            this.analytics = getAnalytics(this.app);
        }
    }

    /**
     * Destroy service
     *
     * @name destroyAnalyticsService
     * @memberof IAnalyticsService
     */
    destroyAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Destroying AnalyticsFirebaseService');
        }
    }

    /**
     * @name registerUser
     * @param userId
     */
    public registerUser(userCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService userCode= ' + userCode);
            this.analytics = getAnalytics(this.app);
            setUserId(this.analytics, userCode);
        }
    }

    /**
     * Register company once
     *
     * @name registerCompanyPropertyOnce
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyPropertyOnce(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService registerCompanyPropertyOnce companyCode= ' + companyCode);
            // mixpanel.set_group_once(AnalyticsProperties.EventAccount, companyCode);
        }
    }

    /**
     * Register company
     *
     * @name registerCompanyProperty
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyProperty(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService registerCompanyProperty companyCode= ' + companyCode);
            // mixpanel.set_group(AnalyticsProperties.EventAccount, companyCode);
        }
    }

    /**
     * Update company
     *
     * @name updateCompanyProperty
     * @param {string} companyCode
     * @param {CustomParams} property
     * @memberof MixpanelService
     */
    public updateCompanyProperty(companyCode: string, property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService updateCompanyProperty companyCode= ' + companyCode);
            LoggerHelper.log('AnalyticsFirebaseService updateCompanyProperty property= ' + JSON.stringify(property));
            // var group = mixpanel.get_group(AnalyticsProperties.EventAccount, companyCode);
            // group.set(AnalyticsProperties.PropertyAccountName, property);
        }
    }

    /**
     * Register user property
     *
     * @name registerUserProperty
     * @param property
     */
    public registerUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService registerUserProperty= ' + JSON.stringify(property));
            this.analytics = getAnalytics(this.app);
            setUserProperties(this.analytics, property);
        }
    }

    /**
     * @name setUserProperty
     * @param property
     */
    public setUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService setUserProperty= ' + JSON.stringify(property));
            this.analytics = getAnalytics(this.app);
            setUserProperties(this.analytics, property);
        }
    }

    /**
     * @name LogEvent
     * @param eventName
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEvent(eventName: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService logEvent= ' + eventName);
            this.analytics = getAnalytics(this.app);
            logEvent(this.analytics, eventName);
        }
    }

    /**
     * @name logEventWithValue
     * @param eventName
     * @param eventValue
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEventWithValue(eventName: string, eventValue: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('AnalyticsFirebaseService logEvent= ' + eventName + ' ' + eventValue);
            this.analytics = getAnalytics(this.app);
            logEvent(this.analytics, eventName, eventValue);
        }
    }
}
