import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../../http/http-call/global-variables';
import { HttpCall } from '../../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class CountryApplicationService {
    constructor(private globalApiVariables: GlobalApiVariables, private http: HttpCall) { }

    /**
     * @name getAllCountries
     * @returns data
     */
    public getAllCountries() {
        var data = this.http.GetAsync(false, this.globalApiVariables.ApiGetAllCountries);
        return data;
    }
}
