import { Component, Inject, OnDestroy, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReplaySubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { PopupActionButtonType } from "../../../helper/popup/popup-action.enum";
import { GenericSelectionCardModel } from "../../../models/generic-selection-card-model";
import { DialogSelectionData } from "../../dialog-builder/dialog-selection.model";
import { TranslationService } from "../../../../../../src/app/config/services/translation.service";

@Component({
  selector: "popup-selection",
  templateUrl: "./popup-selection.component.html",
  styleUrls: ["./popup-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PopupSelectionComponent implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  icon: string;
  theme: string;
  title: string;
  description: string;
  cancelButtonText: string = "";
  actionButtonText: string = "";
  lookupArray: GenericSelectionCardModel[] = [];
  isMultipleSelection: boolean = false;

  isRTL: boolean = false;

  /**
   * Component constructor to inject dependencies.
   */
  constructor(
    public dialogRef: MatDialogRef<PopupSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectionData,
    public translate: TranslateService,
    public translationService: TranslationService
  ) {
    this.initializeData(data);
    this.labelUpdate();
    this.translate.onLangChange.takeUntil(this.destroyed$).subscribe(() => {
      this.labelUpdate();
    });

    // Close dialog when user clicks outside the popup (backdrop click)
    this.dialogRef.backdropClick().subscribe(() => {
      this.handleFooterButtonClick(PopupActionButtonType.NegativeActionButton);
    });
  }

  /**
   * On component destroy, clean up subscriptions
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * @name labelUpdate
   * Update translation labels when the language changes
   */
  private labelUpdate(): void {
    this.isRTL = this.translationService.isPlatformDirectionRtl();

    // Use the provided values for cancel and action buttons or use defaults
    this.cancelButtonText =
      this.data.negativeButtonText ||
      this.translationService.GetTranslatedValue("GENERAL.Dismiss");

    this.actionButtonText =
      this.data.positiveButtonText ||
      this.translationService.GetTranslatedValue("GENERAL.Select");
  }

  /**
   * @name initializeData
   * @param data - The dialog data passed in
   * Initializes the dialog with the passed data
   */
  private initializeData(data: DialogSelectionData): void {
    this.title = data.title;
    this.theme = data.theme;
    this.description = data.description;
    this.icon = data.icon;
    // Deep clone each item in the lookupArray to avoid two-way binding
    this.lookupArray = (data.lookupArray || []).map((item) => ({ ...item }));
    this.isMultipleSelection = data.isMultipleSelection || false;
  }

  /**
   * @name onItemSelect
   * @param item - The selected item
   * Handles the selection logic for both single and multiple selection modes,
   * allowing deselection when clicking on an already selected item.
   */
  onItemSelect(item: GenericSelectionCardModel): void {
    if (this.isMultipleSelection) {
      // Toggle the isActive state in multiple selection mode
      item.isActive = !item.isActive;
    } else {
      if (item.isActive) {
        // Deselect the item if it is already selected
        item.isActive = false;
      } else {
        // Deselect all other items and select the clicked one
        this.lookupArray.forEach((i) => (i.isActive = false));
        item.isActive = true;
      }
    }
  }

  /**
   * @name handleFooterButtonClick
   * @param action - The action type clicked in the footer (positive or negative).
   * Sends the modified lookupArray to the parent component.
   */
  handleFooterButtonClick(action: string): void {
    if (action === PopupActionButtonType.PrimaryActionButton) {
      this.dialogRef.close(this.lookupArray);
    } else if (action === PopupActionButtonType.NegativeActionButton) {
      this.dialogRef.close(this.data.lookupArray);
    }
  }
}
