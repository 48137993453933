import { Injectable } from '@angular/core';
import { CustomParams } from 'firebase/analytics';
import { InstitutionPreferenceResponseModel } from '../../../../../src/app/core/models/institution/response/institution-preference-response.model';
import { UserSessionModel } from '../../../../../src/app/core/models/session/user-session.model';
import { SubscriptionResponseModel } from '../../../../../src/app/core/models/shared/subscription/response/subscription.response.model';
import { BrandingHelper } from '../branding-helper';
import { DateTimeHelper } from '../datetime/datetime.helper';
import { RoleHelper } from '../role/role-helper';
import { RoleType } from '../role/role.enum';
import { AnalyticsAccountTrialModel } from './models/account/analytics-account-trial.model';
import { AnalyticsAccountModel } from './models/account/analytics-account.model';
import { AnalyticsProperties } from './models/enum/analytics-properties.enum';
import { AnalyticsExtraModel } from './models/other/analytics-extra.model';
import { AnalyticsUserModel } from './models/user/analytics-user.model';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsViewModel {
    constructor(public brandingHelper: BrandingHelper) { }

    /**
     * @name fillCommonUserProfile
     * @param userSession
     * @returns
     */
    private fillCommonUserProfile(userSession: UserSessionModel): AnalyticsUserModel {
        var analyticsModel: AnalyticsUserModel = new AnalyticsUserModel();
        analyticsModel.id = userSession.user.code;

        //Set nationality code
        if (userSession && userSession.user && userSession.user.isoCode) {
            analyticsModel.nationality = userSession.user.isoCode;
        }

        //Set user gender
        // if (userSession && userSession.user && userSession.user.gender) {
        //   analyticsModel.gender = userSession.user.gender.toLowerCase();
        // }

        //Set user birthyear
        // if (userSession && userSession.user && userSession.user.birthDate) {
        //   analyticsModel.birthday = UserSessionModel.Instance.user.birthDate;
        // }

        //Set user role
        if (RoleHelper.getUserRole().length > 0) {
            analyticsModel.role = [];
            RoleHelper.getUserRole().forEach((element) => {
                analyticsModel.role.push(element);
            });
        }

        //Institution
        if (InstitutionPreferenceResponseModel.Instance && InstitutionPreferenceResponseModel.Instance.institutionDescription && InstitutionPreferenceResponseModel.Instance.institutionCode) {
            analyticsModel.company = new AnalyticsAccountModel();
            analyticsModel.company.id = InstitutionPreferenceResponseModel.Instance.institutionCode;
            analyticsModel.company.name = InstitutionPreferenceResponseModel.Instance.institutionDescription;
            analyticsModel.company.domain = location.hostname.replace('www.', '');
        }

        return analyticsModel;
    }

    /**
     * @name getSegmentUserProfileSetupTracking
     * @param userSession
     * @returns
     */
    public getSegmentUserProfileSetupTracking(userSession: UserSessionModel): AnalyticsUserModel {
        var analyticsModel: AnalyticsUserModel = this.fillCommonUserProfile(userSession);

        if (userSession && userSession.user && userSession.user.id > 0) {
            analyticsModel.firstName = userSession.user.firstName;
            analyticsModel.lastName = userSession.user.lastName;
            analyticsModel.email = userSession.user.email;
            analyticsModel.username = userSession.user.username;
            analyticsModel.createdAt = DateTimeHelper.FormatDateTime(new Date(), 'HH:mm dd-MM-yyyy', 'en-US');
            analyticsModel.role = [];
            analyticsModel.role.push(RoleType.Administrator.toString());
        }

        return analyticsModel;
    }

    /**
     * @name getSegmentUserProfileUpdateTracking
     * @param userSession
     * @returns
     */
    public getSegmentUserProfileUpdateTracking(userSession: UserSessionModel): AnalyticsUserModel {
        var analyticsModel: AnalyticsUserModel = this.fillCommonUserProfile(userSession);

        analyticsModel.firstName = userSession.user.firstName;
        analyticsModel.lastName = userSession.user.lastName;
        analyticsModel.email = userSession.user.email;
        analyticsModel.username = userSession.user.username;

        // if (userSession.user.photo && userSession.user.photo.length > 0) {
        //   analyticsModel.avatar = userSession.user.photo;
        // }

        return analyticsModel;
    }

    /**
     * @name getTrackEventExtras
     * @param userSession
     * @returns
     */
    public getTrackEventExtras(): AnalyticsExtraModel {
        var analyticsModel: AnalyticsExtraModel = new AnalyticsExtraModel();

        //Institution
        if (InstitutionPreferenceResponseModel.Instance && InstitutionPreferenceResponseModel.Instance.institutionDescription && InstitutionPreferenceResponseModel.Instance.institutionCode) {
            analyticsModel.groupId = InstitutionPreferenceResponseModel.Instance.institutionCode;
        }

        analyticsModel.domain = location.hostname.replace('www.', '');

        return analyticsModel;
    }

    /**
     * @name getActiveSubscription
     * @param subscription
     * @returns
     */
    public getActiveSubscription(subscription: SubscriptionResponseModel): AnalyticsAccountTrialModel {
        var analyticsModel: AnalyticsAccountTrialModel = new AnalyticsAccountTrialModel();

        //Institution
        if (subscription && subscription.planId > 0) {
            analyticsModel.start_date = subscription.startDateTime || null;
            analyticsModel.end_date = subscription.expiryDateTime || null;
            analyticsModel.plan_name = subscription.planName;
            analyticsModel.isFreeTrial = subscription.isFreeTrial;
            analyticsModel.isAppSumoUser = subscription.isAppSumoSubscription;
        }

        return analyticsModel;
    }

    /**
     * @name getAccountProfile
     * @param userSession
     * @returns
     */
    public getAccountProfile(institutionPreferenceResponseModel: InstitutionPreferenceResponseModel): AnalyticsAccountModel {
        var analyticsModel: AnalyticsAccountModel = new AnalyticsAccountModel();

        analyticsModel.name = institutionPreferenceResponseModel.institutionDescription;
        analyticsModel.id = institutionPreferenceResponseModel.institutionCode;
        analyticsModel.avatar = institutionPreferenceResponseModel.logo;
        analyticsModel.domain = location.hostname.replace('www.', '');

        if (institutionPreferenceResponseModel.isProviderInstitution) {
            analyticsModel.hierarchyLevel = 'Parent';
        } else {
            analyticsModel.hierarchyLevel = 'Subsidiary';
            analyticsModel.parentId = institutionPreferenceResponseModel.whiteLabelInstitutionCode;
            analyticsModel.parentCode = institutionPreferenceResponseModel.whiteLabelInstitutionDescription;
        }
        if (institutionPreferenceResponseModel.isHigherEducation) {
            analyticsModel.industry = 'HigherEd';
        } else {
            if (institutionPreferenceResponseModel.isK12Education) {
                analyticsModel.industry = 'K12';
            } else {
                if (institutionPreferenceResponseModel.isTrainingEducation) {
                    analyticsModel.industry = 'Training';
                }
            }
        }

        return analyticsModel;
    }

    /**
     * @name createCommonUserProfile
     * @param userSession
     * @returns
     */
    private createCommonUserProfile(userSession: UserSessionModel): CustomParams {
        var analyticsObject: CustomParams = this.createEventObject({}, AnalyticsProperties.UserCode, userSession.user.code);

        //Set nationality code
        if (userSession && userSession.user && userSession.user.isoCode) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserNationality, userSession.user.isoCode);
        }

        //Set user gender
        if (userSession && userSession.user && userSession.user.gender) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserGender, userSession.user.gender.toLowerCase());
        }

        //Set user birthyear
        if (userSession && userSession.user && userSession.user.birthDate) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserBirthyear, new Date(UserSessionModel.Instance.user.birthDate).getFullYear());
        }

        //Set user role
        if (RoleHelper.getUserRole().length > 0) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserRole, RoleHelper.getUserRole()[0]);
        }

        //Institution
        if (InstitutionPreferenceResponseModel.Instance && InstitutionPreferenceResponseModel.Instance.institutionDescription && InstitutionPreferenceResponseModel.Instance.institutionCode) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserInstitution, InstitutionPreferenceResponseModel.Instance.institutionDescription);
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserInstitutionCode, InstitutionPreferenceResponseModel.Instance.institutionCode);
        }

        return analyticsObject;
    }

    /**
     * @name getMixpanelUserProfileSetupTracking
     * @param userSession
     * @returns
     */
    public getMixpanelUserProfileSetupTracking(userSession: UserSessionModel): CustomParams {
        var analyticsObject: CustomParams = this.createCommonUserProfile(userSession);

        //Register custom user property for Mixpanel
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserFirstName, userSession.user.firstName);
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserLastName, userSession.user.lastName);
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserEmail, userSession.user.email);
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserJoinedDate, DateTimeHelper.FormatDateTime(new Date(), 'HH:mm dd-MM-yyyy', 'en-US'));
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserRole, RoleType.Administrator);

        return analyticsObject;
    }

    /**
     * @name getFirebaseUserProfileSetupTracking
     * @param userSession
     * @returns
     */
    public getFirebaseUserProfileSetupTracking(userSession: UserSessionModel): CustomParams {
        var analyticsObject: CustomParams = this.createCommonUserProfile(userSession);

        //Register custom user property for Mixpanel
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserJoinedDate, DateTimeHelper.FormatDateTime(new Date(), 'HH:mm dd-MM-yyyy', 'en-US'));
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserRole, RoleType.Administrator.toLowerCase());

        return analyticsObject;
    }

    /**
     * @name getMixpanelUserProfileUpdateTracking
     * @param userSession
     * @returns
     */
    public getMixpanelUserProfileUpdateTracking(userSession: UserSessionModel): CustomParams {
        var analyticsObject: CustomParams = this.createCommonUserProfile(userSession);

        //Register custom user property for Mixpanel
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserFirstName, userSession.user.firstName);
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserLastName, userSession.user.lastName);
        analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserEmail, userSession.user.email);
        if (userSession.user.photo && userSession.user.photo.length > 0) {
            analyticsObject = this.createEventObject(analyticsObject, AnalyticsProperties.UserAvatar, userSession.user.photo);
        }

        return analyticsObject;
    }

    /**
     * @name getFirebaseUserProfileUpdateTracking
     * @param userSession
     * @returns
     */
    public getFirebaseUserProfileUpdateTracking(userSession: UserSessionModel): CustomParams {
        var analyticsObject: CustomParams = this.createCommonUserProfile(userSession);

        return analyticsObject;
    }

    /**
     * @name createEventObject
     * @param obj
     * @param key
     * @param value
     * @returns
     * Reference
     *  https://bobbyhadz.com/blog/typescript-object-with-dynamic-keys
     *  https://www.samanthaming.com/tidbits/7-create-object-with-dynamic-keys/
     */
    public createEventObject(obj: CustomParams, key: string, value: any): CustomParams {
        obj[key] = value;
        return obj;
    }
}
