import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { Routes } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard, RoleGuard, SubscriptionGuard } from './_guards';
import { AuthenticationService } from './_services/authentication.service';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '?authentication=:authenticationCode',
            },
        ],
    },
];
@NgModule({
    declarations: [AuthComponent, LogoutComponent],
    imports: [CommonModule, FormsModule, HttpClientModule, AuthRoutingModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule],
    providers: [AuthGuard, SubscriptionGuard, RoleGuard, AuthenticationService],
    entryComponents: [],
})
export class AuthModule { }
