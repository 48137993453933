import { SerializationHelper } from '../../../../../../src/app/core/helper/serialization-helper';
import { CachingHelper } from '../../../../../../src/app/shared/helper/caching/caching';
import { CachingKeys } from '../../../../../../src/app/shared/helper/caching/caching-keys';

export class InstitutionPreferenceResponseModel {
    id: number;
    appId: number;
    institutionId: number;
    institutionDescription: string;
    institutionCode: string;
    institutionHeaderDescription: string;
    logo: string;
    languageId: number;
    languageDescription: string;
    languageIsoCode: string;
    themeId?: number;
    themeDescription: string;
    themePrimaryColor: string;
    themeDarkColor: string;
    themeLightColor: string;
    themeSecondaryColor: string;
    themeErrorColor: string;
    themeSuccessColor: string;
    active?: boolean;
    isK12Education: boolean;
    isHigherEducation: boolean;
    isTrainingEducation: boolean;
    isAdaptiveLearningEnabled: boolean;
    isLinearLearningEnabled: boolean;
    isProviderInstitution: boolean;
    isConsumerInstitution: boolean;
    whiteLabelInstitutionDescription: string;
    whiteLabelInstitutionCode: string;

    private static _instance: InstitutionPreferenceResponseModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new InstitutionPreferenceResponseModel(), CachingHelper.getItemFromCache(CachingKeys.Institution));

            //if instance does not exist
            if (!this._instance) {
                this.UpdateInstance(new InstitutionPreferenceResponseModel());
                this._instance = SerializationHelper.toInstance(new InstitutionPreferenceResponseModel(), CachingHelper.getItemFromCache(CachingKeys.Institution));
            }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }
    public static UpdateInstance(data: InstitutionPreferenceResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.Institution, JSON.stringify(data));
    }
}
