import { formatDate } from '@angular/common';
import { DateTimeType } from './datetime.enum';

export class DateTimeHelper {
    /**
     * @name RoundUpTimeToTheNearestMinute
     * @param date
     * @param nearestMinute
     * Round up a date to the nearest X minute
     */
    static RoundUpTimeToTheNearestMinute(date: Date, nearestMinute: number): Date {
        var coeff = 1000 * 60 * nearestMinute;
        var rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
        return rounded;
    }

    /**
     * @name RoundDownTimeToTheNearestMinute
     * @param date
     * @param nearestMinute
     * Round down a date to the nearest X minute
     */
    static RoundDownTimeToTheNearestMinute(date: Date, nearestMinute: number): Date {
        var coeff = 1000 * 60 * nearestMinute;
        var rounded = new Date(Math.floor(date.getTime() / coeff) * coeff);
        return rounded;
    }

    /**
     * @name FormatDateTime
     * @param date
     * @param format
     * @param locale
     * Format a date time
     */
    static FormatDateTime(date: Date, format: string, locale: string): string {
        return formatDate(date, format, locale);
    }

    /**
     * Adds time to a date. Modelled after MySQL DATE_ADD function.
     * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
     * https://stackoverflow.com/a/1214753/18511
     *
     * @param date  Date to start with
     * @param interval  One of: year, quarter, month, week, day, hour, minute, second
     * @param units  Number of units of the given interval to add.
     */
    static ChangeDateValue(date: Date, interval: string, units: number): Date {
        if (!(date instanceof Date)) return undefined;
        var ret = new Date(date); //don't change original date
        var checkRollover = function() {
            if (ret.getDate() != date.getDate()) ret.setDate(0);
        };
        switch (String(interval).toUpperCase()) {
            case DateTimeType.YEAR:
                ret.setFullYear(ret.getFullYear() + units);
                checkRollover();
                break;
            case DateTimeType.QUARTER:
                ret.setMonth(ret.getMonth() + 3 * units);
                checkRollover();
                break;
            case DateTimeType.MONTH:
                ret.setMonth(ret.getMonth() + units);
                checkRollover();
                break;
            case DateTimeType.WEEK:
                ret.setDate(ret.getDate() + 7 * units);
                break;
            case DateTimeType.DAY:
                ret.setDate(ret.getDate() + units);
                break;
            case DateTimeType.HOUR:
                ret.setTime(ret.getTime() + units * 3600000);
                break;
            case DateTimeType.MINUTE:
                ret.setTime(ret.getTime() + units * 60000);
                break;
            case DateTimeType.SECOND:
                ret.setTime(ret.getTime() + units * 1000);
                break;
            default:
                ret = undefined;
                break;
        }
        return ret;
    }

    /**
     * Get the time zone name
     *
     * @name getTimeZoneName
     * @param localId
     * @returns
     */
    public static getTimeZoneName(localId: string): string {
        const date = new Date();
        const formatter = new Intl.DateTimeFormat(localId, { timeZoneName: 'short' });
        const parts = formatter.formatToParts(date);
        let timeZoneName: string;

        for (const part of parts) {
            if (part.type === 'timeZoneName') {
                timeZoneName = part.value;
                break;
            }
        }

        return timeZoneName;
    }
}
