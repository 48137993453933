import { SubscriptionPlanFeatureModel } from './subsription-plan-features.model';

export class SubscriptionPlanModel {
    planName: string;
    planPrice: number;
    planCurrency: string;
    planAccessStorage: string;
    planRevenueShare: number;

    planAdminsLimit: number;
    planLearnersLimit: number;

    planSubAccountsLimit: string;

    // Features
    features: SubscriptionPlanFeatureModel[];
}
