import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserSessionModel } from '../../../../src/app/core/models/session/user-session.model';
import { LoggerHelper } from '../../../../src/app/shared/helper/logging/logger';
import { NavigationKeys } from '../../../../src/app/shared/helper/navigation-keys';
import { UserSessionViewModel } from '../../../../src/app/shared/viewmodel/session/user-session.viewmodel';
import { TranslationService } from '../../config/services/translation.service';
import { Helpers } from '../../helpers';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    translationErrorConnectingToServer: string = '';

    constructor(public translate: TranslateService, public translationService: TranslationService, public _router: Router, private viewModel: UserSessionViewModel) {
        this.LabelUpdate();
        this.translate.onLangChange.subscribe(() => {
            this.LabelUpdate();
        });
    }

    ngOnInit(): void {
        Helpers.setLoading(true);

        if (!UserSessionModel.Instance || !UserSessionModel.Instance.session || !UserSessionModel.Instance.session.id) {
            this._router.navigate([NavigationKeys.LoginPage]);
        } else {
            this.Logout();
        }
        Helpers.setLoading(true);
    }

    /**
     * Update the UI labels based on the translations
     */
    LabelUpdate() {
        this.translationErrorConnectingToServer = this.translationService.GetTranslatedValue('GENERAL.ErrorConnectingToServer');
    }

    /**
     * @name Logout
     * Api call to logout + delete session
     */
    async Logout() {
        await this.viewModel
            .DeleteSession()
            .toPromise()
            .then((result) => {
                if (result) {
                    LoggerHelper.log('DELETED');
                }
            })
            .catch((error) => {
                LoggerHelper.log('Error :: ' + error);
                // this._snackBar.ShowWarning(this.translationErrorConnectingToServer);
            })
            .finally(() => {
                this.viewModel.clearSessionData();
            });
    }
}
