import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PopupActionButtonType } from '../../../../../../../src/app/shared/helper/popup/popup-action.enum';
import { ThemeType } from '../../../../helper/theme/theme-type.enum';

@Component({
    selector: 'popup-footer',
    templateUrl: './popup-footer.component.html',
    styleUrls: ['popup-footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupFooterComponent {
    public predefinedTheme = ThemeType;
    @Input() theme: string;
    @Input() negativeButtonText: string;
    @Input() positiveButtonText?: string;
    @Input() isPositiveButtonEnabled: boolean;
    @Input() actionSecondButtonText?: string;
    @Input() isActionSecondButtonEnabled: boolean;

    @Output() onButtonClick = new EventEmitter<string>();

    constructor(public dialogRef: MatDialogRef<PopupFooterComponent>) { }

    /**
     * @name onDismissButtonClick
     * On dismiss button click
     */
    onDismissButtonClick(): void {
        this.onButtonClick.emit(PopupActionButtonType.NegativeActionButton);
    }

    /**
     * onActionButtonClick
     * On action button click
     */
    onActionButtonClick(): void {
        this.onButtonClick.emit(PopupActionButtonType.PrimaryActionButton);
    }

    /**
     * onActionSecondButtonClick
     * On second action button click
     */
    onActionSecondButtonClick(): void {
        this.onButtonClick.emit(PopupActionButtonType.SecondaryActionButton);
    }
}
