import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ThemeType } from '../../../../helper/theme/theme-type.enum';
import { DialogData } from '../../dialog-single-input-model';

@Component({
    selector: 'dialog-informative2',
    templateUrl: './dialog-informative.component.html',
    styleUrls: ['dialog-informative.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogInformativeNewComponent {
    icon: string;
    theme: string;
    title: string;
    description: string;
    cancelButtonText: string = '';
    actionButtonUrl: string;
    actionButtonText: string = '';

    public predefinedTheme = ThemeType;

    constructor(public dialogRef: MatDialogRef<DialogInformativeNewComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.initializeData(data);
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: DialogData) {
        this.title = data.title;
        this.theme = data.theme;
        this.description = data.description;
        this.icon = data.icon;
        this.cancelButtonText = data.negativeButtonText;
        this.actionButtonText = data.positiveButtonText;
        this.actionButtonUrl = data.positiveButtonActionUrl;
    }

    /**
     * @name onCancelButtonClick
     */
    onCancelButtonClick(): void {
        this.dialogRef.close(false);
    }

    /**
     * @name onActionButtonClick
     */
    onActionButtonClick(): void {
        if (this.actionButtonUrl && this.actionButtonUrl.length > 0) {
            window.open(this.actionButtonUrl, '_blank');
        }
        this.dialogRef.close(true);
    }
}
