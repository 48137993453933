import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrandingHelper } from '../branding-helper';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(private titleService: Title, private brandingHelper: BrandingHelper) { }

    /**
     * @name setPageTitle
     * @name pageTitle
     * Init page title
     */
    public setPageTitle(pageTitle: string) {
        if (pageTitle && pageTitle.length > 0) {
            var platformTitle = this.brandingHelper.loadBrandingPlatformTitle();
            if (platformTitle) {
                this.titleService.setTitle(this.titleCaseWord(pageTitle.replace('-', ' ')) + ' | ' + platformTitle);
            } else {
                this.titleService.setTitle(this.titleCaseWord(pageTitle));
            }
        } else {
            this.titleService.setTitle(this.brandingHelper.loadBrandingPlatformTitle());
        }
    }

    /**
     * @name getPageTitle
     * Get page title
     */
    public getPageTitle(): string {
        return this.titleService.getTitle();
    }

    /**
     * @name titleCaseWord
     * @param word
     * @returns
     */
    private titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.slice(1);
    }
}
