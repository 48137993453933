import { Injectable } from '@angular/core';
import { SubscriptionApiVariables } from '../../http/http-call/apis/shared/subscription/subscription.api';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionApplicationService {
    constructor(private subscriptionApiVariables: SubscriptionApiVariables, private http: HttpCall) { }

    /**
     * @name getActiveSubscriptionInformation
     * @param institutionId
     * Get available and completed assessment
     */
    getActiveSubscriptionInformation(institutionId: number) {
        var data = this.http.GetAsync(true, this.subscriptionApiVariables.ApiSubscriptionValidation, institutionId);
        return data;
    }
}
