import { AfterContentInit, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/pairwise';
import { environment } from '../../src/environments/environment';
import { TranslationService } from './config/services/translation.service';
import { InstitutionPreferenceResponseModel } from './core/models/institution/response/institution-preference-response.model';
import { InstitutionSettingResponseModel } from './core/models/institution/response/institution-settings.response.model';
import { BrowserPreferenceModel } from './core/models/session/browser-preferences.model';
import { UserPreferencesModel } from './core/models/session/user-preferences.model';
import { UserSessionModel } from './core/models/session/user-session.model';
import { BrandingResponseModel } from './core/models/shared/branding/response/branding.response.model';
import { SubscriptionResponseModel } from './core/models/shared/subscription/response/subscription.response.model';
import { Helpers } from './helpers';
import { AnalyticsService } from './shared/helper/analytics/analytics.service';
import { AnalyticsViewModel } from './shared/helper/analytics/analytics.viewmodel';
import { AnalyticsProperties } from './shared/helper/analytics/models/enum/analytics-properties.enum';
import { BrandingHelper } from './shared/helper/branding-helper';
import { FaviconService } from './shared/helper/branding/favicon.service';
import { IntercomService } from './shared/helper/intercom/intercom-service';
import { LanguageCode } from './shared/helper/language/language.enum';
import { LoggerHelper } from './shared/helper/logging/logger';
import { TitleService } from './shared/helper/platform/title.service';
import { UtilityHelper } from './shared/helper/utility';
import { InstitutionViewModel } from './shared/viewmodel/institution/institutionpicker.viewmodel';
import { AccountViewModel } from './shared/viewmodel/teacher/account/account.viewmodel';

export let browserRefresh = false;

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  subscription: Subscription;
  branding: BrandingResponseModel;

  globalBodyClass =
    'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-light m-aside-left--fixed m-aside-left--offcanvas m-aside-left--minimize m-brand--minimize m-footer--push m-aside--offcanvas-default';

  constructor(
    private _router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private brandingHelper: BrandingHelper,
    private accountViewModel: AccountViewModel,
    private institutionViewModel: InstitutionViewModel,
    private analyticsService: AnalyticsService,
    private analyticsViewModel: AnalyticsViewModel,
    public intercomService: IntercomService,
    private faviconService: FaviconService
  ) {
    this.titleService.setPageTitle('');

    if (this.isUserSessionAvailable()) {
      this.analyticsService.initAnalyticsService();
      //Initialize user data for analytics
      // this.analyticsService.initializeUserData();

      //Initialize intercom
      this.intercomService.initializeIntercom();
    }

    BrowserPreferenceModel.ClearInstance();
    if (!BrowserPreferenceModel.Instance.enableDebugging) {
      if (environment.production) {
        BrowserPreferenceModel.Instance.enableDebugging = false;
      } else {
        BrowserPreferenceModel.Instance.enableDebugging = true;
      }
      BrowserPreferenceModel.UpdateInstance(BrowserPreferenceModel.Instance);
      BrowserPreferenceModel.ClearInstance();
    }

    //Logs the app version
    LoggerHelper.log('Learning space version : v' + environment.version);

    this.subscription = _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !_router.navigated;
        LoggerHelper.log('browserRefresh: ' + browserRefresh);
      }
    });
  }

  @HostListener('window:unload', ['$event'])
  async ngOnDestroy() {
    LoggerHelper.log('Closing');
    SubscriptionResponseModel.clearFullInstance();
  }

  async ngOnInit() {
    this._router.events.subscribe(async (route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
      }
      if (route instanceof NavigationEnd) {
        if (this.branding) {
          Helpers.setLoading(false);
        }

        if (UserSessionModel.Instance && UserSessionModel.Instance.user && UserSessionModel.Instance.user.code.length > 0) {
          //Track the page view
          LoggerHelper.log('Page being navigated: ' + this.titleService.getPageTitle().split('|')[0].trim());
          this.analyticsService.logEventWithValue(
            AnalyticsProperties.EventPageVisit,
            this.analyticsViewModel.createEventObject({}, AnalyticsProperties.EventPage, this.titleService.getPageTitle().split('|')[0].trim()),
            this.analyticsViewModel.getTrackEventExtras()
          );
        }
      }
    });
  }

  ngAfterContentInit() {
    // Init language
    this.initializePlatformDirection();

    // Init branding
    this.initializePlatformBranding();

    //Load the settings
    this.getInstitutionSettings();

    //Append hotjar
    this.appendHotjarTracking();
  }

  /**
   * @name appendHotjarTracking
   * Append hotjar tracking to the website only when in production
   */
  private appendHotjarTracking() {
    if (environment.analytics.enableHotjarTracking) {
      LoggerHelper.log('Hotjar enabled');
      ((h, o, t, j, a, r) => {
        h.hj =
          h.hj ||
          /* tslint:disable:only-arrow-functions */
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3057888, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }

  /**
   * @name initializePlatformDirection
   * Initialize the platform language based on the user preferences
   */
  private async initializePlatformDirection() {
    //For the purpose of waiting the app.component to load
    await UtilityHelper.delay(2000);
    if (UserPreferencesModel.Instance.languageIsoCode) {
      if (UserPreferencesModel.Instance.languageIsoCode.length > 0) {
        this.translationService.setLanguageBasedOnAccountType(UserPreferencesModel.Instance);
      } else {
        this.translationService.setLanguage(LanguageCode.English);
      }
    } else {
      this.translationService.setLanguage(LanguageCode.English);
    }
  }

  /**
   * @name initializePlatformBranding
   * Initialize the platform branding
   */
  public async initializePlatformBranding() {
    await this.loadBranding();
    this.initializePlatformTheme();
    this.initializeFavicon();
    this.initializePlatformTitle();
  }

  /**
   * @name getInstitutionSettings
   * @param institutionId
   * get institution settings
   */
  async getInstitutionSettings() {
    //For the purpose of waiting the app.component to load
    await UtilityHelper.delay(2000);
    LoggerHelper.log('Loading the institution settings');

    if (InstitutionPreferenceResponseModel.Instance && InstitutionPreferenceResponseModel.Instance.institutionId > 0) {
      var institutionId = InstitutionPreferenceResponseModel.Instance.institutionId;
      var institutionSettingResponseModel: InstitutionSettingResponseModel = await this.institutionViewModel.getInstitutionSettings(institutionId);
      if (institutionSettingResponseModel) {
        InstitutionSettingResponseModel.clearInstance();
        InstitutionSettingResponseModel.updateInstance(institutionSettingResponseModel);
        InstitutionSettingResponseModel.clearInstance();
      } else {
        InstitutionSettingResponseModel.clearInstance();
        InstitutionSettingResponseModel.updateInstance(new InstitutionSettingResponseModel());
        InstitutionSettingResponseModel.clearInstance();
      }
    }
  }

  /**
   * @name initializePlatformBranding
   * Initialize the platform branding
   */
  private async loadBranding() {
    //For the purpose of waiting the app.component to load
    await UtilityHelper.delay(2000);

    LoggerHelper.log('Checking the hostname');
    LoggerHelper.log(location.hostname.replace('www.', ''));

    //Force clear the branding to be re-loaded
    BrandingResponseModel.clearFullInstance();

    if (environment.production || (!environment.production && location.hostname.replace('www.', '') != 'localhost')) {
      //Validate if the domain is singup
      if (location.hostname.replace('www.', '') == 'signup' + environment.webAppDomain) {
        this.branding = await this.brandingHelper.loadBranding();
        Helpers.setLoading(false);
      } else {
        //Validate if the domain exists in our database
        if (await this.accountViewModel.validateDomainNameAvailability(location.hostname.replace('www.', ''))) {
          // Domain not available
          window.location.href = 'https://signup' + environment.webAppDomain;
        } else {
          //Domain available
          this.branding = await this.brandingHelper.loadBranding();
          Helpers.setLoading(false);
        }
      }
    } else {
      // For dev and localhost
      this.branding = await this.brandingHelper.loadBranding();
      Helpers.setLoading(false);
    }
  }

  /**
   * @name initializeFavicon
   * Initialize the platform favicon based on the user preferences
   *  Check if the institution white labelled preference exists
   *  if not load branding based on the url
   */
  private initializeFavicon() {
    this.faviconService.setFavicon(this.brandingHelper.loadBrandingFavicon());
  }

  /**
   * @name initializePlatformTitle
   * Initialize the platform title based on the user preferences
   *  Check if the institution white labelled preference exists
   *  if not load branding based on the url
   */
  private async initializePlatformTitle() {
    this.titleService.setPageTitle('');
  }

  /**
   * @name InitializePlatformTheme
   * Initialize the platform theme based on the user preferences
   *  Check if the institution white labelled preference exists
   *  if not load branding based on the url
   */
  private initializePlatformTheme() {
    this.brandingHelper.changeBrandingBasedOnUrl();
  }

  /**
   * @name IsUserSessionAvailable
   * Checks if user session is available in cache
   */
  private isUserSessionAvailable(): boolean {
    UserSessionModel.clearInstance();
    try {
      if (UserSessionModel.Instance) {
        LoggerHelper.logInfo(UserSessionModel.Instance);
        if (UserSessionModel.Instance.user.id) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    } catch (Exception) {
      return false;
    }
  }
}
