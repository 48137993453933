import { CachingHelper } from '../../../../../src/app/shared/helper/caching/caching';
import { CachingKeys } from '../../../../../src/app/shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../helper/serialization-helper';

export class BrowserPreferenceModel {
    enableDebugging: boolean;

    private static _instance: BrowserPreferenceModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(
                new BrowserPreferenceModel(),
                CachingHelper.getItemFromCache(CachingKeys.BrowserPreferences)
            );

            //if instance does not exist
            if (!this._instance) {
                this.UpdateInstance(new BrowserPreferenceModel());
                this._instance = SerializationHelper.toInstance(
                    new BrowserPreferenceModel(),
                    CachingHelper.getItemFromCache(CachingKeys.BrowserPreferences)
                );
            }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }
    public static UpdateInstance(data: BrowserPreferenceModel) {
        CachingHelper.saveItemIntoCache(
            CachingKeys.BrowserPreferences,
            JSON.stringify(data)
        );
    }
}
