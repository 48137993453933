// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//https://medium.com/@levz0r/angular-cli-gulp-versioning-your-application-made-easy-d72a5f55b083

import { KnowledgeBaseKey } from '../../src/app/shared/helper/knowledge-base/knowledge-base.enum';
import { KnowledgeBaseHelper } from '../../src/app/shared/helper/knowledge-base/knowledge-base.hepler';

export const environment = {
  version: '20.4.0',
  identityApiServer: 'https://dev-auth.augmental.xyz',
  baseApiServer: 'https://dev-api-gateway.augmental.xyz/gateway',
  baseApiServerBff: 'https://dev-api-gateway.augmental.xyz/site-bff',
  // identityApiServer: 'https://staging-auth.lurno.ai',
  // baseApiServer: 'https://staging-api.lurno.ai/gateway',
  // baseApiServerBff: 'https://staging-api.lurno.ai/site-bff',
  webAppDomain: '.testingmeet.augmental.education',
  augmentalWebsite: 'https://augmental.education',
  production: false,
  analytics: {
    enableHotjarTracking: false,
    enableIntercom: true,
    segment: {
      enableTracking: true,
      enableDebugging: true,
      token: 'WARrRC8n2ZWV5NUgJmslL8CQBgMJPCOk',
    },
    mixpanel: {
      enableTracking: false,
      token: '35ff5c3bd5d3973e51fa52a5aee3fae9',
      enableDebugging: true,
    },
    firebase: {
      enableTracking: false,
      googleAnalytics: {
        tag: 'G-8HDQYR5459',
      },
      firebaseConfig: {
        apiKey: 'AIzaSyAJ0baPbebEBaAe-vF2bvK0_KYHyqQtp-Y',
        authDomain: 'augmental-web-portal-dev.firebaseapp.com',
        projectId: 'augmental-web-portal-dev',
        storageBucket: 'augmental-web-portal-dev.appspot.com',
        messagingSenderId: '317791959915',
        appId: '1:317791959915:web:e301166449d32e60c72a2e',
        measurementId: 'G-8HDQYR5459',
      },
    },
  },
  knowledgeBase: {
    website: 'https://help.augmentalapp.com/',
    course: [
      {
        key: KnowledgeBaseKey.CourseLessonPlan,
        title: 'KnowledgeBase.Course.LessonPlan',
        description: 'KnowledgeBase.Course.LessonPlanDescription',
        type: KnowledgeBaseHelper.TrainnGuide,
        url: 'JNimQFl804E-ai-generated-lesson-plan',
      },
      {
        key: KnowledgeBaseKey.CourseContentQuestions,
        title: 'KnowledgeBase.Course.ContentAndQuestions',
        description: 'KnowledgeBase.Course.ContentAndQuestionsDescription',
        type: KnowledgeBaseHelper.TrainnCollection,
        url: 'VqebTQvRWaY-content-question-creation',
      },
      {
        key: KnowledgeBaseKey.CoursePublish,
        title: 'KnowledgeBase.Course.PublishCourse',
        description: 'KnowledgeBase.Course.PublishCourseDescription',
        type: KnowledgeBaseHelper.TrainnGuide,
        url: 'cTfI5Dgynoc-publishing-a-course',
      },
      {
        key: KnowledgeBaseKey.CourseMonetize,
        title: 'KnowledgeBase.Course.Monetize',
        description: 'KnowledgeBase.Course.MonetizeDescription',
        type: KnowledgeBaseHelper.TrainnGuide,
        url: 'bluXnYvcBV4-connecting-your-stripe-account',
      },
    ],
    account: [
      {
        key: KnowledgeBaseKey.AccountManageType,
        title: 'KnowledgeBase.Account.AccountType',
        description: 'KnowledgeBase.Account.AccountTypeDescription',
        url: '',
      },
      {
        key: KnowledgeBaseKey.AccountManageBranding,
        title: 'KnowledgeBase.Account.Branding',
        description: 'KnowledgeBase.Account.BrandingDescription',
        url: '',
      },
      {
        key: KnowledgeBaseKey.AccountManageSubAccounts,
        title: 'KnowledgeBase.Account.SubAccounts',
        description: 'KnowledgeBase.Account.SubAccountsDescription',
        url: '',
      },
      {
        key: KnowledgeBaseKey.AccountManageCustomDomain,
        title: 'KnowledgeBase.Account.CustomDomain',
        description: 'KnowledgeBase.Account.CustomDomainDescription',
        url: '',
      },
    ],
    integrations: [
      {
        key: KnowledgeBaseKey.IntegrationsPaymentGateawy,
        title: 'KnowledgeBase.Integrations.PaymentGateway',
        description: 'KnowledgeBase.Integrations.PaymentGatewayDescription',
        url: '',
      },
    ],
    siteBuilder: [
      {
        key: KnowledgeBaseKey.SiteBuilderFeaturedCourses,
        title: 'KnowledgeBase.SiteBuilder.EmbedFeaturedTitle',
        description: 'KnowledgeBase.SiteBuilder.EmbedFeaturedDescription',
        type: KnowledgeBaseHelper.TrainnCollection,
        url: '5hk8YtgJftM-site-builder',
      },
      {
        key: KnowledgeBaseKey.SiteBuilderAllCourses,
        title: 'KnowledgeBase.SiteBuilder.EmbedFullTitle',
        description: 'KnowledgeBase.SiteBuilder.EmbedFullDescription',
        type: KnowledgeBaseHelper.TrainnCollection,
        url: '5hk8YtgJftM-site-builder',
      },
    ],
  },
};
