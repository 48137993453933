import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { environment } from '../../../../environments/environment';
import { UserSessionModel } from '../../../core/models/session/user-session.model';
import { SubscriptionResponseModel } from '../../../core/models/shared/subscription/response/subscription.response.model';
import { EncryptionDecryptionKeys } from '../encryption-decryption/encryption-decryption-keys';
import { EncryptionDecryptionHelper } from '../encryption-decryption/encryption-decryption.helper';
import { GlobalVaribales } from '../global-variable';
import { LoggerHelper } from '../logging/logger';

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private isIntercomEnabled: boolean;

    constructor(public intercom: Intercom) {
        this.isIntercomEnabled = environment.analytics.enableIntercom;
    }

    /**
     * @name appendIntercom
     * Append hotjar tracking to the website only when in production
     */
    public initializeIntercom() {
        if (this.isIntercomEnabled && this.isUserSessionAvailable()) {
            LoggerHelper.log('Intercom enabled');
            LoggerHelper.log(UserSessionModel.Instance.user.code);
            LoggerHelper.log(EncryptionDecryptionKeys.IntercomSecretKey);
            var encryptedValue = EncryptionDecryptionHelper.EncryptStringHmacSHA256(EncryptionDecryptionKeys.IntercomSecretKey, UserSessionModel.Instance.user.code);
            LoggerHelper.log(encryptedValue);

            var numberOfDaysRemainingInFreeTrial: number = SubscriptionResponseModel.Instance.numberOfDays;
            if (!numberOfDaysRemainingInFreeTrial) {
                numberOfDaysRemainingInFreeTrial = 0;
            }

            this.intercom.boot({
                app_id: GlobalVaribales.IntercomAppID,
                name: UserSessionModel.Instance.user.firstName,
                email: UserSessionModel.Instance.user.email,
                user_id: UserSessionModel.Instance.user.code,
                user_hash: encryptedValue,
                days_remaining_in_free_trial: numberOfDaysRemainingInFreeTrial,
                widget: {
                    activator: '#intercom',
                },
            });
        }
    }

    /**
     * @name shutdown
     * Shutdown intercom
     */
    public shutdown() {
        this.intercom.shutdown();
    }

    /**
     * @name isUserSessionAvailable
     * Checks if user session is available in cache
     */
    private isUserSessionAvailable(): boolean {
        UserSessionModel.clearInstance();
        try {
            if (UserSessionModel.Instance) {
                LoggerHelper.logInfo(UserSessionModel.Instance);
                if (UserSessionModel.Instance.user.id) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        } catch (Exception) {
            return false;
        }
    }
}
