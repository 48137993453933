import { CustomParams } from 'firebase/analytics';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../../../../../src/environments/environment';
import { LoggerHelper } from '../../../logging/logger';
import { IAnalyticsService } from '../../analytics.interface.service';
import { AnalyticsProperties } from '../../models/enum/analytics-properties.enum';

export class MixpanelService implements IAnalyticsService {
    private isTrackingEnabled: boolean;

    constructor() {
        LoggerHelper.log('Inside MixpanelService ' + environment.analytics.mixpanel.enableTracking);
        this.isTrackingEnabled = environment.analytics.mixpanel.enableTracking;
    }
    registerUserProperties(userCode: string, property: CustomParams) {
        throw new Error('Method not implemented.');
    }

    /**
     * Initialize service
     * @name initAnalyticsService
     * @memberof MixpanelService
     */
    initAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Initialize MixpanelService');
            mixpanel.init(environment.analytics.mixpanel.token, { debug: environment.analytics.mixpanel.enableDebugging, ignore_dnt: true, track_pageview: true, persistence: 'localStorage' });
        }
    }

    /**
     * Destroy service
     *
     * @name destroyAnalyticsService
     * @memberof MixpanelService
     */
    destroyAnalyticsService() {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('Destroying MixpanelService');
            mixpanel.reset();
        }
    }

    /**
     * Initialize userCode
     *
     * @name registerUser
     * @param {string} userCode
     * @memberof MixpanelService
     */
    public registerUser(userCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService registerUser userCode= ' + userCode);
            mixpanel.identify(userCode);
        }
    }

    /**
     * Register company once
     *
     * @name registerCompanyPropertyOnce
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyPropertyOnce(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService registerCompanyPropertyOnce companyCode= ' + companyCode);
            // mixpanel.set_once(AnalyticsProperties.EventAccount, companyCode);
            mixpanel.set_group(AnalyticsProperties.EventAccount, companyCode);
        }
    }

    /**
     * Register company
     *
     * @name registerCompanyProperty
     * @param {string} companyCode
     * @memberof MixpanelService
     */
    public registerCompanyProperty(companyCode: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService registerCompanyProperty companyCode= ' + companyCode);
            mixpanel.set_group(AnalyticsProperties.EventAccount, companyCode);
        }
    }

    /**
     * Update company
     *
     * @name updateCompanyProperty
     * @param {string} companyCode
     * @param {CustomParams} property
     * @memberof MixpanelService
     */
    public updateCompanyProperty(companyCode: string, property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService updateCompanyProperty companyCode= ' + companyCode);
            LoggerHelper.log('MixpanelService updateCompanyProperty property= ' + JSON.stringify(property));
            var group = mixpanel.get_group(AnalyticsProperties.EventAccount, companyCode);
            group.set(property);
        }
    }

    /**
     * Register user property
     *
     * @name registerUserProperty
     * @param property
     * mixpanel.register is used to set super properties for events.
     * Super properties are a feature in Mixpanel that allows you to set properties that are included with every event.
     * This is useful for attributes that don't change often and should be attached to every event to avoid redundancy.
     */
    public registerUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService registerUserProperty= ' + JSON.stringify(property));
            mixpanel.register(property);
        }
    }

    /**
     * Set user property
     *
     * @name setUserProperty
     * @param property
     * mixpanel.people.set is used to set properties on a user's profile within Mixpanel's People Analytics feature.
     * This is used for storing properties about the user themselves, rather than about their actions.
     */
    public setUserProperty(property: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService setUserProperty= ' + JSON.stringify(property));
            mixpanel.people.set(property);
        }
    }

    /**
     * @name LogEvent
     * @param {string} eventName Name of the action to track.
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEvent(eventName: string) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService logEvent= ' + eventName);
            mixpanel.track(eventName);
        }
    }

    /**
     * @name logEventWithValue
     * @param {string} eventName Name of the action to track.
     * @param {*} eventValue Actions object with custom properties.
     * Sends a Google Analytics event with given eventParams. This method automatically associates this logged event with this Firebase web app instance on this device.
     */
    public logEventWithValue(eventName: string, eventValue: CustomParams) {
        if (this.isTrackingEnabled) {
            LoggerHelper.log('MixpanelService logEventWithValue= ' + eventName);
            mixpanel.track(eventName, eventValue);
        }
    }
}
