import { CachingHelper } from '../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../helper/serialization-helper';

export class OnBoardingPreferences {
    hasTakenBuilderMainPage: boolean;
    hasTakenBuilderSection: boolean;
    hasTakenTextContent: boolean;
    hasTakenImageContent: boolean;
    hasTakenAudioContent: boolean;
    hasTakenVideoContent: boolean;
    hasTakenMixedContent: boolean;
    hasTakenMultipleChoiceQuestion: boolean;
    hasTakenMultipleAnswerQuestion: boolean;
    hasTakenTrueOrFalseQuestion: boolean;
    hasTakenOpenEndedQuestion: boolean;
    hasTakenFillTheBlankQuestion: boolean;
    hasTakenClassificationQuestion: boolean;
    hasTakenAssessmentGeneration: boolean;
    hasTakenBrandingCustomization: boolean;

    private static _instance: OnBoardingPreferences;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new OnBoardingPreferences(), CachingHelper.getItemFromCache(CachingKeys.OnBoarding));
            //if instance does not exist
            //   if (!this._instance) {
            //     this.UpdateInstance(new OnBoardingHandler());
            //     this._instance = SerializationHelper.toInstance(
            //       new OnBoardingHandler(),
            //       CachingHelper.getItemFromCache(CachingKeys.OnBoarding)
            //     );
            //   }
            return this._instance;
        }
        return this._instance;
    }

    public static ClearInstance() {
        this._instance = null;
    }
    public static UpdateInstance(data: OnBoardingPreferences) {
        CachingHelper.saveItemIntoCache(CachingKeys.OnBoarding, JSON.stringify(data));
    }
}
