import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { TranslationService } from '../../../../../../src/app/config/services/translation.service';
import { IconsList } from '../../../../../../src/app/shared/helper/theme/icons';
import { ThemeType } from '../../../../../../src/app/shared/helper/theme/theme-type.enum';
import { DialogData } from '../../dialog-builder/dialog-single-input-model';

@Component({
    selector: 'popup-input',
    templateUrl: './popup-input.component.html',
    styleUrls: ['popup-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopupInputComponent implements OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    popupData: DialogData;

    icon: string;
    theme: string;

    preFilledItemName: string;
    placeHolderField: string;

    iconsList = IconsList;
    isRTL: boolean = false;

    public predefinedTheme = ThemeType;

    /**
     * On page destroy
     */
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    constructor(public translate: TranslateService, public translationService: TranslationService, public dialogRef: MatDialogRef<PopupInputComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.labelUpdate();
        this.translate.onLangChange.takeUntil(this.destroyed$).subscribe((event: LangChangeEvent) => {
            this.labelUpdate();
        });

        this.popupData = data.main;
        this.preFilledItemName = data.item.itemName;
        this.placeHolderField = data.item.placeHolderField;

        this.initializeData(data.main);
    }

    /**
     * Update the UI labels based on the translations
     */
    labelUpdate() {
        this.isRTL = this.translationService.isPlatformDirectionRtl();
    }

    /**
     * @name initializeData
     * @param data
     * Initialize the data of the dialog
     */
    private initializeData(data: DialogData) {
        this.theme = data.theme;
        this.icon = data.icon;
    }

    /**
     * @name onCancelButtonClick
     */
    onCancelButtonClick(): void {
        this.dialogRef.close();
    }

    /**
     * @name updateData
     */
    updateData(itemName: string) {
        this.dialogRef.close({ itemName: itemName });
    }
}
