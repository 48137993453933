import { CachingHelper } from '../../../../shared/helper/caching/caching';
import { CachingKeys } from '../../../../shared/helper/caching/caching-keys';
import { SerializationHelper } from '../../../helper/serialization-helper';
import { InstitutionAssistantSettingResponseModel } from './institution-assistant-settings.response.model';
import { InstitutionCourseSettingResponseModel } from './institution-course-settings.response.model';

export class InstitutionSettingResponseModel {
    institutionId: number;
    isGenderSettingEnabled: boolean;
    isNationalitySettingEnabled: boolean;
    isGenerativeAISettingEnabled: boolean;
    isPaymentConfigured: boolean;
    isBirthyearSettingEnabled: boolean;
    isOrganizationSettingsEnabled: boolean;
    isOptInMarketingSettingsEnabled: boolean;
    isAccessPublic: boolean;
    assistant: InstitutionAssistantSettingResponseModel;
    course: InstitutionCourseSettingResponseModel;

    private static _instance: InstitutionSettingResponseModel;

    public constructor() {
        //...
    }

    /**
     * @name Instance
     */
    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new InstitutionSettingResponseModel(), CachingHelper.getItemFromCache(CachingKeys.InstitutionSettings));
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.InstitutionSettings);
    }

    /**
     * @name updateInstance
     * @param data
     * Update the instance
     */
    public static updateInstance(data: InstitutionSettingResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.InstitutionSettings, JSON.stringify(data));
    }
}
