export class NavigationKeys {
    public static CreateUrl(navigationType: string, navigation: string) {
        return navigationType + '/' + navigation;
    }

    public static TeacherModule = 'admin';
    public static LearnerModule = 'learner';

    /**
     * Login page
     */
    public static LoginPage = 'login';

    /**
     * Reset password page
     */
    public static ResetPasswordPage = 'reset-password';

    /**
     * Sign up page
     */
    public static SignUpPage = 'sign-up';

    /**
     * Welcome
     */
    public static AccountRegistrationModule = 'account';

    /**
     * Welcome
     */
    public static AccountWelcomePage = 'welcome';

    /**
     * Account setup page
     */
    public static AccountSetupPage = 'setup';

    /**
     * User setup page
     */
    public static AccountUserSetupPage = 'user';

    /**
     * Account setting up page
     */
    public static AccountSettingUpPage = 'registration';

    /**
     * Logout page
     */
    public static LogoutPage = 'logout';

    /**
     * Routing page
     */
    public static RoutingPage = 'routing';

    /**
     * Institution Setup Page page
     */
    public static InstitutionSetupPage = 'institutions';

    /**
     * Language Setup Page page
     */
    public static LanguageSetupPage = 'languages';

    /**
     * Getting started page
     */
    public static GettingStartedPage = 'getting-started';

    /**
     * Dashboard page
     */
    public static DashboardPage = 'dashboard';
    /**
     * Dashboard page
     */
    public static ReportPage = 'reporting';

    /**
     * Assessment page
     */
    public static AssessmentPage = 'assessment';

    /**
     * Assessment generation page
     */
    public static AssessmentPageGeneration = 'generation';

    /**
     * Assessment flow page
     */
    public static AssessmentFlowPage = 'assessment-flow';

    /**
     * Settings page
     */
    public static SettingsPage = 'settings';

    /**
     * Settings Switch Institution
     */
    public static SettingsSwitchInstitution = 'switch-institution';

    /**
     * Settings profile
     */
    public static SettingsProfile = 'profile';

    /**
     * Settings Change Language
     */
    public static SettingsChangeLanguage = 'change-language';

    /**
     * Settings Change password
     */
    public static SettingsChangePassword = 'change-password';

    /**
     * Settings Integrations
     */
    public static SettingsIntegrations = 'integrations';

  /**
   * MyCourses page
   */
  public static MyCoursesPage = 'courses';
  public static AdminCourseManagePage = 'manage';
  public static AdminCourseSetupPage = 'setup';
  public static AdminCourseSetupIntroPage = 'intro';
  public static AdminCourseSetupAIPage = 'ai-basic';
  public static AdminCourseSetupAIAdvancedPage = 'ai-advanced';
  public static AdminCourseSetupManualPage = 'manual';

    /**
     * Learner course generation
     */
    public static LearnerCourseGeneration = 'course-generation';

    /**
     * CourseCatalog page
     */
    public static CourseCatalog = 'course-catalog';

    /**
     * Learning flow page
     */
    public static LearningFlowPage = 'learning';

    /**
     * Course Structure page
     */
    public static CourseStructurePage = 'course-structure';

    /**
     * Course Summary page
     */
    public static CourseSummaryPage = 'summary';
    public static CourseSummaryPageOverview = 'overview';
    public static CourseSummaryPageLesson = 'lesson';
    public static CourseSummaryPageMembers = 'members';

    /**
     * Course drip schedulee
     */
    public static CourseDripSchedule = 'drip-schedule';

    /**
     * content details page
     */
    public static ContentDetailsPage = 'content';

    /**
     * Manage accounts page
     */
    public static ManageAccountsPage = 'accounts';
    public static ManageAccountsManage = 'manage';
    public static ManageAccountsSettings = 'settings';
    public static ManageAccountsEmail = 'email';

    /**
     * Manage subscription page
     */
    public static ManageSubscriptionPage = 'subscription';

    /**
     * Manage user page
     */
    public static ManageUserPage = 'users';
    public static ManageUserManage = 'manage';
    public static ManageUserAccount = 'account';

    /**
     * Manage user page
     */
    public static ManageUserEnrollmentPage = 'enrollment';
    public static ManageUserEnrollmentManage = 'manage';
    public static ManageUserEnrollmentGroup = 'group';
    public static ManageUserEnrollmentUser = 'user';
    public static ManageUserEnrollmentPageQueryParamUser = 'user';
    public static ManageUserEnrollmentPageQueryParamGroup = 'group';

    /**
     * Builder main page
     */
    public static BuilderMainPage = 'main-page';

    /**
     * Builder  page
     */
    public static BuilderPage = 'builder';

    /**
     * Builder question page
     */
    public static BuilderQuestionPage = 'builder/question';

    /**
     * Builder content page
     */
    public static BuilderContentPage = 'builder/content';

    /**
     * Builder diagnostic question page
     */
    public static BuilderDiagnosticPage = 'builder/diagnostic-question';

    /**
     * Diagnostic main page
     */
    public static DiagnosticMainPage = 'diagnostic-main-page';

    /**
     * Live session
     */
    public static LiveSessionPage = 'video-conferencing';

    /**
     * Live session
     */
    public static LiveBoardPage = 'liveboard';

    /**
     * Live board sample
     */
    public static LiveBoardSamplePage = 'sample/liveboard';

    /**
     * Report sample
     */
    public static ReportSamplePage = 'sample/report';

    /**
     * Publisher content library
     */
    public static PublisherContentLibrary = 'content-library/publisher';

    /**
     * Consumer content library
     */
    public static ConsumerContentLibrary = 'content-library';

    /**
     * recorded session
     */
    public static RecordedSessionPage = 'cloud-recording';

    /**
     * question correction
     */
    public static QuestionCorrectionPage = 'question-correction';

    /**
     * Forgot password page
     */
    public static ForgotPasswordPage = 'forgot-password';

    /**
     * learner course enrollment
     */
    public static CourseEnrollment = 'courseEnrollment';
    public static CourseEmbeddedEnrollment = 'courseEmbeddedEnrollment';
    public static CourseEmbeddedEnrollmentCoursePreviewParam = 'coursePreview';
    public static CourseEmbeddedEnrollmentEmbeddedCodeParam = 'embeddedCode';
    public static CourseEmbeddedEnrollmentRedirectUrlParam = 'redirectUrl';
    public static CourseEmbeddedEnrollmentCourseParam = 'course';

    /**
     * learning outcome
     */
    public static LearningOutcomePage = 'learning-outcomes';
    public static LearningOutcomeManagePage = 'manage';
    public static LearningOutcomeManageSubjectParam = 'subject';
    public static LearningOutcomeManageCurriculumParam = 'curriculum';
    public static LearningOutcomeManageLanguageParam = 'language';

    /**
     * curriculum
     */
    public static CurriculumPage = 'curriculum';
    public static SubjectPage = 'subject';
    public static ClassPage = 'class';

    /**
     * Session ended page
     */
    public static SessionEndedPage = 'session-ended';

    /**
     * Join room
     */
    public static JoinRoom = 'joinRoom';

    /**
     * Join guest
     */
    public static JoinGuest = 'join-guest';

    /**
     * Analytics page
     */
    public static AnalyticsPage = 'analytics';

    /**
     * Learner report page
     */
    public static LearnerReportPage = 'learner-report';

    /**
     * Lesson preview page
     */
    public static LessonPreviewPage = 'preview';

    /**
     * Subscription page
     */
    public static SubscriptionPage = 'subscription';
    public static SubscriptionTrialEndedPage = 'trial-ended';
    public static SubscriptionErrorPage = 'error';
    public static SubscriptionInvalidAccessPage = 'invalid-access';

    /**
     * Site builder page
     */
    public static SiteBuilder = 'site-builder';
    public static SiteBuilderSetup = 'setup';
    public static SiteBuilderCourseEmbedded = 'course-embedded';
}
