export enum AnalyticsProperties {
    // User
    UserFirstName = 'firstName',
    UserLastName = 'lastName',
    UserEmail = 'email',
    UserAvatar = 'avatar',
    UserGender = 'gender',
    UserBirthyear = 'birthday',
    UserNationality = 'nationality',
    UserCode = 'id',
    UserJoinedDate = 'createdAt',
    UserUsername = 'username',
    UserRole = 'role',
    UserInstitution = 'company',
    UserInstitutionCode = 'companyCode',
    UserInstitutionDomain = 'companyDomain',

    //Account
    EventAccountCreated = 'Account Created',
    EventAccount = 'company',
    PropertyAccountName = 'name',
    PropertyAccountCode = 'id',
    PropertyAccountTarget = 'industry',
    PropertyAccountLogo = 'logo',
    PropertyAccountHierarchyLevel = 'hierarchy_level',
    PropertyAccountParentCode = 'parent_code',
    PropertyAccountParentName = 'parent_name',

    //Subscription
    EventSubscriptionTrialStarted = 'Trial Started',
    EventSubscriptionTrialEnded = 'Trial Ended',
    EventSubscriptionEnded = 'Subscription Ended',
    EventSubscriptionActive = 'Subscription Active',

    // User session
    EventLogin = 'Signed In',
    EventLogout = 'Signed Out',
    EventSignup = 'Signed Up',

    // Course
    EventCourse = 'Course',
    PropertyHasCreatedFirstCourse = 'hasCreatedFirstCourse',

    // ----------------------
    // ----------------------
    // ----------------------

    // Platform
    AppLanguage = 'app_language',
    AppLanguageCode = 'app_language_code',

    // Page flow
    EventPageVisit = 'page_visit',
    EventPage = 'page',

    // User session
    // EventLogin = 'login',
    PropertyLoginDate = 'login_date',
    // EventSignup = 'sign_up',
    PropertySignupUser = 'sign_up_user',
    PropertySignupUserSetupDate = 'sign_up_user_setup_date',
    PropertySignupAccount = 'sign_up_account',
    PropertySignupAccountSetupDate = 'sign_up_account_setup_date',
    // EventLogout = 'logout',
    PropertyLogoutDate = 'logout_date',

    // User
    // UserFirstName = '$first_name', //reserved for mixpanel
    // UserLastName = '$last_name', //reserved for mixpanel
    // UserEmail = '$email', //reserved for mixpanel
    // UserAvatar = '$avatar', //reserved for mixpanel
    // UserGender = 'gender',
    // UserBirthyear = 'birth_year',
    // UserNationality = 'nationality',
    // UserCode = 'user_code',
    // UserJoinedDate = 'joined_date',
    // UserRole = 'role',
    // UserInstitution = 'institution',
    // UserInstitutionCode = 'institution_code',
    // UserInstitutionDomain = 'institution_domain',

    //Account
    // EventAccount = 'company',
    // PropertyAccountName = '$name',
    // PropertyAccountCode = 'code',
    // PropertyAccountTarget = 'target',
    // PropertyAccountLogo = 'logo',
    // PropertyAccountHierarchyLevel = 'hierarchy_level',
    // PropertyAccountParentCode = 'parent_code',
    // PropertyAccountParentName = 'parent_name',

    // Subscription
    Subscription = 'subscription',
    SubscriptionPlan = 'plan',
    SubscriptionRegistrationDate = 'renewed_date',
}
