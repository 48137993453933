import { enableProdMode, OnInit } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
import { LoggerHelper } from './app/shared/helper/logging/logger';
import { environment } from './environments/environment';

export class main implements OnInit {
    constructor() { }

    async ngOnInit() {
        LoggerHelper.log('Inside the main.ts');
    }
}

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fcHVWRmBZV0Z+WUA=');

if (environment.production) {
    enableProdMode();
}

/**
 * Initialize segment
 *
 * Initially it was declared in the library as follow:
 * declare var analytics: SegmentAnalytics.AnalyticsJS;
 *
 * Documentation
 * https://www.sabhya.dev/setting-up-segment-analytics-in-angular-with-environment-files
 */
if (environment.analytics.segment.enableTracking) {
    (analytics as any)._writeKey = environment.analytics.segment.token;
    analytics.load(environment.analytics.segment.token);
    analytics.page();
    if (environment.analytics.segment.enableDebugging) {
        analytics.debug(true);
    }
}

platformBrowserDynamic()
    .bootstrapModule(
        AppModule
        //      ,{
        //      providers:[
        //         {provide : TRANSLATIONS ,useValue :langchoice},
        //          {provide :TRANSLATIONS_FORMAT ,useValue :'xlf'}
        //      ]
        //  }
    )
    .catch((err) => LoggerHelper.logError(err));
