import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoutingWorkflowSetupComponent } from './setup-workflow-routing.component';

const routes: Routes = [
    {
        path: '',
        component: RoutingWorkflowSetupComponent,
    }
];
@NgModule({
    imports: [NgbModule.forRoot(), RouterModule.forChild(routes)],
    entryComponents: [RoutingWorkflowSetupComponent],
    exports: [RouterModule],
    declarations: [RoutingWorkflowSetupComponent]
})
export class RoutingWorkflowSetupModule { }
