export class EncryptionDecryptionKeys {
    /**
     * The encrypt decrypt common key with mobile
     * Key must be 16 characters
     */
    public static EncryptDecryptCommonKey = 'eewa0_{herea#!@#';

    /**
     * The iv key common key with mobile
     * Key must be 16 characters
     */
    public static IVCommonKey = '_18ja$301Pa_)&%V';

    /**
     * The encrypt decrypt common key
     */
    public static IntercomSecretKey = 'pCGfhAqClGewPgLDo7p0N9eSksbs3YdIP5axhSIH';
}
